// @flow

import { Fragment, Component } from 'react';
import styled from 'styled-components';
import { Icon, MenuItem, Menu, Position } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { uniq } from 'lodash';
import * as Selectors from '../../selectors';
import * as RecordingModel from '../../models/recording';
import * as LocationModel from '../../models/location';
import { Popover, Button, Intent } from '@blueprintjs/core';

export const ActiveIcon = styled(Icon)`
  &&& {
    margin-left: auto;
    max-height: 13px;
    color: rgba(0, 0, 0, 0.5);
  }
`;
export const MenuItemChildStyled = styled.div`
  padding: 0.5em;
  padding-left: 2em;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: rgba(167, 182, 194, 0.3);
  }
`;
export const MenuItemParentStyled = styled(MenuItem)`
  display: flex;
  align-items: center;
`;
export const MenuStyled = styled(Menu)`
  max-height: 80vh;
  overflow-y: auto;
`;

type Props = {
  controlledButtonText?: string,
  activeRecording?: RecordingModel.t,
  recordingIdWhitelist: string[],
  recordings: RecordingModel.t[],
  onSelect: (id: string) => void,
  locations: LocationModel.t[],
};
type State = {
  currentLocationIdOpen: boolean,
};

// TODO: support for this component if there are no locations...
// Fall back to a list of all recordings as before

class SingleRecordingPicker extends Component<Props, State> {
  state = {
    currentLocationIdOpen: false,
  };

  componentDidMount() {
    const validLocationIds = this.getValidLocations(true);

    // if there's only one location, have it open it by default
    if (validLocationIds && validLocationIds.length === 1) {
      this.setState({ currentLocationIdOpen: validLocationIds[0] });
    }
  }

  getAllowedRecordings() {
    const { recordings, recordingIdWhitelist } = this.props;

    return recordings.filter((r) =>
      recordingIdWhitelist ? recordingIdWhitelist.includes(r.id) : true,
    );
  }

  getValidLocations(idsOnly) {
    const { locations = [] } = this.props;

    const allowedRecordings = this.getAllowedRecordings();
    const uniqLocationIds = uniq(allowedRecordings.map((r) => r.location.id));
    if (idsOnly) return uniqLocationIds;
    return uniqLocationIds.map((id) => locations.find((l) => l.id === id));
  }

  handleCurrentLocationIdOpen = (id) => {
    const { currentLocationIdOpen } = this.state;

    if (currentLocationIdOpen === id) {
      this.setState({
        currentLocationIdOpen: false,
      });
    } else {
      this.setState({ currentLocationIdOpen: id });
    }
  };

  getButtonText = () => {
    const { activeRecording } = this.props;
    if (activeRecording && activeRecording.location) {
      return `${activeRecording.location.name} - ${activeRecording.name}`;
    } else {
      return 'Select a camera';
    }
  };

  render() {
    const { activeRecording, controlledButtonText, onSelect } = this.props;
    const { currentLocationIdOpen } = this.state;

    const buttonText = controlledButtonText
      ? controlledButtonText
      : this.getButtonText();

    const validLocations = this.getValidLocations();
    const allowedRecordings = this.getAllowedRecordings();

    return (
      <Popover position={Position.BOTTOM}>
        <Button
          text={buttonText}
          rightIcon="caret-down"
          intent={Intent.PRIMARY}
        />
        <MenuStyled>
          {validLocations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((location) => (
              <Fragment key={location ? location.id : 1}>
                <MenuItemParentStyled
                  shouldDismissPopover={false}
                  text={location ? location.name : 'All locations'}
                  onClick={() =>
                    this.handleCurrentLocationIdOpen(location && location.id)
                  }
                  icon={
                    location && location.id === currentLocationIdOpen
                      ? 'chevron-down'
                      : 'chevron-right'
                  }
                />
                <Fragment>
                  {location &&
                    location.id === currentLocationIdOpen &&
                    allowedRecordings
                      .filter((r) =>
                        location && r.location
                          ? r.location.id === location.id
                          : true,
                      )
                      .map((item) => (
                        <MenuItemChildStyled
                          key={item.id}
                          onClick={() => onSelect(item.id)}
                        >
                          {item.name}
                          {activeRecording &&
                            activeRecording.id === item.id && (
                              <ActiveIcon icon="tick" />
                            )}
                        </MenuItemChildStyled>
                      ))}
                </Fragment>
              </Fragment>
            ))}
        </MenuStyled>
      </Popover>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    ...ownProps,
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
  };
}

export default connect(mapStateToProps)(SingleRecordingPicker);
