// @flow

import styled from 'styled-components';
import { isEmpty } from 'lodash';

import WidgetRenderer from './widget-renderer';
import NoAreasPanel from '../../no-areas-panel';
import DateAndComparisonDateSelectors from '../../date-and-comparison-date-selector';
import { Wrapper } from '../summary/layout';
import { datePresetsAllowed } from './config';

import * as ReportModel from '../../../models/report';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as LocationModel from '../../../models/location';
import { breakpoints } from '../../../styles/variables';
import { IS_EMAIL_VIEW } from '../../../config/vars';
import { Panel, PanelHeader } from '../../panel';

const WidthWrapper = styled(Wrapper)`
  width: 100%;
  margin: auto;
  flex: 1;
`;
const Section = styled.div`
  border-bottom: var(--widget-section-divider);
  background: var(--widget-section-background);
  padding: var(--widget-section-padding);
  max-width: var(--widget-max-width);
  margin: 1rem auto 2rem;
  border-radius: var(--widget-border-radius);
  box-shadow: var(--widget-box-shadow);

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    flex-direction: column;
  }
`;
const SectionTitle = styled.h2`
  color: var(--heading-text);
  margin: 0 2rem 0 1rem;
  font-size: 2rem;
  font-family: var(--fonts-heading);

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    margin-bottom: 1rem;
  }
`;
const SectionGrid = styled.div``;
const WidgetContainer = styled.div`
  padding-bottom: ${props => (props.addBottomPadding ? '3rem' : '')};
`;
const StyledPanel = styled(Panel)`
  margin: 0;
`;
const StyledPanelHeader = styled(PanelHeader)`
  padding: 1rem;
`;

type Props = {
  report: ReportModel.t,
  getHierarchyConfig: (parent: Object, child: Object) => Object,
  transformHierarchyConfig: (config: Object) => Object,
  updateReportConfig: (updates: Object) => void,
  availableMetrics: AvailableMetricsModel.t,
  allLocationsList: LocationModel.t[],
  startOfWeek: 'monday' | 'sunday',
};

const WidgetGrid = ({
  report,
  getHierarchyConfig,
  transformHierarchyConfig,
  updateReportConfig,
  availableMetrics,
  allLocationsList,
  startOfWeek,
}: Props) => {
  const { config: reportConfig = {} } = report;

  return (
    <WidthWrapper>
      {!isEmpty(report) &&
        reportConfig.sections &&
        reportConfig.sections.map((section, sectionIndex) => {
          const hierarchyConfig = getHierarchyConfig(reportConfig, section);
          const sectionHierarchyConfig = transformHierarchyConfig(
            hierarchyConfig
          );

          const { period, comparisonPeriod } = sectionHierarchyConfig;

          const sectionWidgets = section.widgets
            .map((widget, widgetIndex) => {
              const hierarchyConfig2 = getHierarchyConfig(
                sectionHierarchyConfig,
                widget.widgetConfig
              );
              const widgetHierarchyConfig = transformHierarchyConfig(
                hierarchyConfig2
              );

              return {
                ...widget,
                widgetConfig: {
                  ...widget.widgetConfig,
                  ...widgetHierarchyConfig
                }
              };
            })
            .filter(widget => {
              const isSingleLocation =
                widget.widgetConfig.locations.length === 1;

              if (isSingleLocation && widget.hideOnSingleLocations)
                return false;

              return true;
            });

          if (!sectionWidgets || sectionWidgets.length === 0) return '';

          return (
            <Section key={sectionIndex}>
              <SectionHeader>
                <SectionTitle>{section.sectionTitle}</SectionTitle>
                <StyledPanel>
                  <StyledPanelHeader>
                    <DateAndComparisonDateSelectors
                      currentSelectedDatePreset={period.selectedPreset}
                      datePresetsAllowed={datePresetsAllowed}
                      handlePresetClick={newValue =>
                        updateReportConfig({
                          property: 'period',
                          newValue,
                          hierarchyLevel: 1,
                          hierarchyIndex: sectionIndex
                        })
                      }
                      currentSelectedComparisonDatePreset={
                        comparisonPeriod && comparisonPeriod.selectedPreset
                      }
                      handleComparisonPresetClick={newValue =>
                        updateReportConfig({
                          property: 'comparisonPeriod',
                          newValue,
                          hierarchyLevel: 1,
                          hierarchyIndex: sectionIndex
                        })
                      }
                      period={period}
                      compareToPastPeriod={comparisonPeriod}
                      disableDatesView={IS_EMAIL_VIEW}
                      disableCompareDatesView
                      align="flex-end"
                  />
                  </StyledPanelHeader>
                </StyledPanel>
              </SectionHeader>
              <SectionGrid>
                {sectionWidgets.map((widget, widgetIndex) => (
                  <WidgetContainer
                    key={widgetIndex}
                  >
                    <WidgetRenderer
                      widgetType={widget.widgetType}
                      widget={widget.widgetConfig}
                      availableMetrics={availableMetrics}
                      locations={allLocationsList} // not every widget needs this but we supply in case it's needed
                      uiOptions={{
                        disableDescription: IS_EMAIL_VIEW,
                        disableViewportLoading: IS_EMAIL_VIEW,
                        startOfWeek,
                      }}
                    />
                  </WidgetContainer>
                ))}
              </SectionGrid>
            </Section>
          );
        })}
      {isEmpty(report) && <NoAreasPanel message="No reports found" />}
    </WidthWrapper>
  );
};

export default WidgetGrid;
