import { Backoff } from '../../../utils/Backoff';
/**
 * MySegmentsUpdateWorker class
 */
var MySegmentsUpdateWorker = /** @class */ (function () {
    /**
     * @param {Object} mySegmentsSyncTask task for syncing mySegments data
     */
    function MySegmentsUpdateWorker(mySegmentsSyncTask) {
        this.mySegmentsSyncTask = mySegmentsSyncTask;
        this.maxChangeNumber = 0; // keeps the maximum changeNumber among queued events
        this.handleNewEvent = false;
        this.segmentsData = undefined; // keeps the segmentsData (if included in notification payload) from the queued event with maximum changeNumber
        this.currentChangeNumber = -1; // @TODO: remove once `/mySegments` endpoint provides the changeNumber
        this.put = this.put.bind(this);
        this.__handleMySegmentsUpdateCall = this.__handleMySegmentsUpdateCall.bind(this);
        this.backoff = new Backoff(this.__handleMySegmentsUpdateCall);
    }
    // Private method
    // Precondition: this.mySegmentsSyncTask.isSynchronizingMySegments === false
    MySegmentsUpdateWorker.prototype.__handleMySegmentsUpdateCall = function () {
        var _this = this;
        if (this.maxChangeNumber > this.currentChangeNumber) {
            this.handleNewEvent = false;
            var currentMaxChangeNumber_1 = this.maxChangeNumber;
            // fetch mySegments revalidating data if cached
            this.mySegmentsSyncTask.execute(this.segmentsData, true).then(function (result) {
                if (result !== false) // Unlike `Splits|SegmentsUpdateWorker`, we cannot use `mySegmentsCache.getChangeNumber` since `/mySegments` endpoint doesn't provide this value.
                    _this.currentChangeNumber = Math.max(_this.currentChangeNumber, currentMaxChangeNumber_1); // use `currentMaxChangeNumber`, in case that `this.maxChangeNumber` was updated during fetch.
                if (_this.handleNewEvent) {
                    _this.__handleMySegmentsUpdateCall();
                }
                else {
                    _this.backoff.scheduleCall();
                }
            });
        }
    };
    /**
     * Invoked by NotificationProcessor on MY_SEGMENTS_UPDATE event
     *
     * @param {number} changeNumber change number of the MY_SEGMENTS_UPDATE notification
     * @param {SegmentsData | undefined} segmentsData might be undefined
     */
    MySegmentsUpdateWorker.prototype.put = function (changeNumber, segmentsData) {
        if (changeNumber <= this.currentChangeNumber || changeNumber <= this.maxChangeNumber)
            return;
        this.maxChangeNumber = changeNumber;
        this.handleNewEvent = true;
        this.backoff.reset();
        this.segmentsData = segmentsData;
        if (this.mySegmentsSyncTask.isExecuting())
            return;
        this.__handleMySegmentsUpdateCall();
    };
    return MySegmentsUpdateWorker;
}());
export { MySegmentsUpdateWorker };
