'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = any;

var _concurrent = require('./__internal__/concurrent');

var _concurrent2 = _interopRequireDefault(_concurrent);

var _errors = require('./__internal__/errors');

var _utils = require('./__internal__/utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function any(iterable) {
  var winner = void 0;

  return Promise.resolve(iterable).then((0, _concurrent2.default)({
    breakOnError: false,
    onItemResolved: function onItemResolved(value) {
      if (!(0, _utils.isDefined)(winner)) {
        winner = value;
      };
    },

    onItemCompleted: function onItemCompleted(done, throws) {
      return function (count, values, errors) {
        if ((0, _utils.isDefined)(winner)) {
          done(winner);
        } else if ((0, _utils.size)(errors) === (0, _utils.size)(values)) {
          throws(new _errors.SubError(errors));
        }
      };
    }
  }));
}