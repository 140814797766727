// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import * as RecordingActions from '../actions/recording';
import * as HeatmapActions from '../actions/heatmap';
import Heatmaps from '../components/pages/heatmaps';
import {
  FETCHING_HEATMAPS,
  FETCHING_HEATMAP_SOURCE_URL
} from '../constants/async-process-names';

function mapStateToProps(state, ownProps) {
  return {
    recordings: Selectors.getRecordings(state),
    fetchingHeatmaps: Selectors.asyncProcessHappening(state, FETCHING_HEATMAPS),
    fetchingHeatmapSourceUrl: Selectors.asyncProcessHappening(
      state,
      FETCHING_HEATMAP_SOURCE_URL
    )
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      fetchHeatmaps: RecordingActions.fetchHeatmaps,
      getSourceUrl: HeatmapActions.getSourceUrl
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Heatmaps);
