import { isString } from '../../../utils/lang';
var VERSION = '1.1';
var CONTROL_CHANNEL_REGEX = /^control_/;
/**
 * Build metadata headers for SSE connection.
 *
 * @param {ISettings} settings Validated settings.
 * @returns {Record<string, string>} Headers object
 */
function buildSSEHeaders(settings) {
    var headers = {
        SplitSDKClientKey: isString(settings.core.authorizationKey) ? settings.core.authorizationKey.slice(-4) : '',
        SplitSDKVersion: settings.version,
    };
    // ip and hostname are false if IPAddressesEnabled is false
    var _a = settings.runtime, ip = _a.ip, hostname = _a.hostname;
    if (ip)
        headers['SplitSDKMachineIP'] = ip;
    if (hostname)
        headers['SplitSDKMachineName'] = hostname;
    return headers;
}
/**
 * Handles streaming connections with EventSource API
 */
var SSEClient = /** @class */ (function () {
    /**
     * SSEClient constructor.
     *
     * @param settings Validated settings.
     * @param useHeaders True to send metadata as headers or false to send as query params. If `true`, the provided EventSource must support headers.
     * @param getEventSource Function to get the EventSource constructor.
     * @throws 'EventSource API is not available. ' if EventSource is not available.
     */
    function SSEClient(settings, useHeaders, getEventSource) {
        this.eventSource = getEventSource && getEventSource();
        // if eventSource is not available, throw an exception
        if (!this.eventSource)
            throw new Error('EventSource API is not available. ');
        this.streamingUrl = settings.urls.streaming + '/sse';
        // @TODO get `useHeaders` flag from `getEventSource`, to use EventSource headers on client-side SDKs when possible.
        this.useHeaders = useHeaders;
        this.headers = buildSSEHeaders(settings);
    }
    SSEClient.prototype.setEventHandler = function (handler) {
        this.handler = handler;
    };
    /**
     * Open the connection with a given authToken
     *
     * @param {IAuthTokenPushEnabled} authToken
     * @throws {TypeError} Will throw an error if `authToken` is undefined
     */
    SSEClient.prototype.open = function (authToken) {
        this.close(); // it closes connection if previously opened
        var channelsQueryParam = Object.keys(authToken.channels).map(function (channel) {
            var params = CONTROL_CHANNEL_REGEX.test(channel) ? '[?occupancy=metrics.publishers]' : '';
            return encodeURIComponent(params + channel);
        }).join(',');
        var url = this.streamingUrl + "?channels=" + channelsQueryParam + "&accessToken=" + authToken.token + "&v=" + VERSION + "&heartbeats=true"; // same results using `&heartbeats=false`
        this.connection = new this.eventSource(
        // For client-side SDKs, SplitSDKClientKey and SplitSDKClientKey metadata is passed as query params,
        // because native EventSource implementations for browser doesn't support headers.
        this.useHeaders ? url : url + ("&SplitSDKVersion=" + this.headers.SplitSDKVersion + "&SplitSDKClientKey=" + this.headers.SplitSDKClientKey), 
        // @ts-ignore. For server-side SDKs, metadata is passed via headers. EventSource must support headers, like 'eventsource' package for Node.
        this.useHeaders ? { headers: this.headers } : undefined);
        if (this.handler) { // no need to check if SSEClient is used only by PushManager
            this.connection.addEventListener('open', this.handler.handleOpen);
            this.connection.addEventListener('message', this.handler.handleMessage);
            this.connection.addEventListener('error', this.handler.handleError);
        }
    };
    /** Close connection  */
    SSEClient.prototype.close = function () {
        if (this.connection)
            this.connection.close();
    };
    return SSEClient;
}());
export { SSEClient };
