import { matchersTransform } from '../matchersTransform';
import { Treatments } from '../treatments';
import { matcherFactory } from '../matchers';
import { sanitizeValue } from '../value';
import { conditionContext } from '../condition';
import { ifElseIfCombinerContext } from '../combiners/ifelseif';
import { andCombinerContext } from '../combiners/and';
import { thenable } from '../../utils/promise/thenable';
export function parser(log, conditions, storage) {
    var predicates = [];
    for (var i = 0; i < conditions.length; i++) {
        var _a = conditions[i], matcherGroup = _a.matcherGroup, partitions = _a.partitions, label = _a.label, conditionType = _a.conditionType;
        // transform data structure
        var matchers = matchersTransform(matcherGroup.matchers);
        // create a set of pure functions from the matcher's dto
        var expressions = matchers.map(function (matcherDto) {
            var matcher = matcherFactory(log, matcherDto, storage);
            // Evaluator function.
            return function (key, attributes, splitEvaluator) {
                var value = sanitizeValue(log, key, matcherDto, attributes);
                var result = value !== undefined && matcher ? matcher(value, splitEvaluator) : false;
                if (thenable(result)) {
                    // @ts-ignore
                    return result.then(function (res) { return Boolean(res ^ matcherDto.negate); });
                }
                // @ts-ignore
                return Boolean(result ^ matcherDto.negate);
            };
        });
        // if matcher's factory can't instanciate the matchers, the expressions array
        // will be empty
        if (expressions.length === 0) {
            // reset any data collected during parsing
            predicates = [];
            // and break the loop
            break;
        }
        predicates.push(conditionContext(log, andCombinerContext(log, expressions), Treatments.parse(partitions), label, conditionType));
    }
    // Instanciate evaluator given the set of conditions using if else if logic
    return ifElseIfCombinerContext(log, predicates);
}
