import { thenable } from '../../utils/promise/thenable';
import { ENGINE_MATCHER_SEGMENT } from '../../logger/constants';
export function segmentMatcherContext(log, segmentName, storage) {
    return function segmentMatcher(key) {
        var isInSegment = storage.segments.isInSegment(segmentName, key);
        if (thenable(isInSegment)) {
            isInSegment.then(function (result) {
                log.debug(ENGINE_MATCHER_SEGMENT, [segmentName, key, isInSegment]);
                return result;
            });
        }
        else {
            log.debug(ENGINE_MATCHER_SEGMENT, [segmentName, key, isInSegment]);
        }
        return isInSegment;
    };
}
