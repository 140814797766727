'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = resolveAll;

var _errors = require('./errors');

var _utils = require('./utils');

function resolve(promises) {
  return Array.isArray(promises) ? Promise.all(promises) : Promise.resolve(promises);
}

function resolveAll(promises) {
  var rejectNonIterable = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  return resolve(promises).then(function (resolved) {
    if (rejectNonIterable && !(0, _utils.isIterable)(resolved)) {
      return Promise.reject((0, _errors.IterableError)(resolved));
    }

    return resolved;
  });
}