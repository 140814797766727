// @flow

import type { FluxStandardAction } from 'flux-standard-action';
import * as ACTIONS from '../constants/actions';
import * as LocationModel from '../models/location';

export type ReducerType = LocationModel.t[];

const INITIAL_STATE = [];

export default function locationsReducer(
  state: ReducerType = INITIAL_STATE,
  { type, payload }: FluxStandardAction<string, *>
): ReducerType {
  switch (type) {
    case ACTIONS.FETCH_RECORDINGS_SUCCESS:
      return payload.locations;

    case ACTIONS.EDIT_LOCATION:
      let updatedLocations = [];
      if (state && payload && payload.id) {
        const locations = [...state];
        const index = locations.findIndex(loc => loc.id === payload.id);
        if (index >= 0) {
          updatedLocations = locations;
          updatedLocations[index] = {
            ...updatedLocations[index],
            ...payload
          };
        }
      } else {
        updatedLocations = state;
      }
      return updatedLocations;

    default:
      return state;
  }
}
