import { syncTaskComposite } from '../syncTaskComposite';
import { eventsSyncTaskFactory } from './eventsSyncTask';
import { impressionsSyncTaskFactory } from './impressionsSyncTask';
import { impressionCountsSyncTaskFactory } from './impressionCountsSyncTask';
export function submitterManagerFactory(params) {
    var settings = params.settings, storage = params.storage, splitApi = params.splitApi;
    var log = settings.log;
    var submitters = [
        impressionsSyncTaskFactory(log, splitApi.postTestImpressionsBulk, storage.impressions, settings.scheduler.impressionsRefreshRate, settings.core.labelsEnabled),
        eventsSyncTaskFactory(log, splitApi.postEventsBulk, storage.events, settings.scheduler.eventsPushRate, settings.startup.eventsFirstPushWindow)
        // @TODO add telemetry submitter
    ];
    if (storage.impressionCounts)
        submitters.push(impressionCountsSyncTaskFactory(log, splitApi.postTestImpressionsCount, storage.impressionCounts));
    return syncTaskComposite(submitters);
}
