export function timeout(ms, promise) {
    if (ms < 1)
        return promise;
    return new Promise(function (resolve, reject) {
        var tid = setTimeout(function () {
            reject(new Error("Operation timed out because it exceeded the configured time limit of " + ms + " ms."));
        }, ms);
        promise.then(function (res) {
            clearTimeout(tid);
            resolve(res);
        }, function (err) {
            clearTimeout(tid);
            reject(err);
        });
    });
}
