// @flow

import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';
import { connect } from 'react-redux';

import * as Selectors from '../selectors';
import * as AuthActions from '../actions/auth';
import Authenticate from '../components/pages/authenticate';

function mapStateToProps(state) {
  return {
    userRefreshCompleted: Selectors.userRefreshCompleted(state),
    userRefreshAuthFailure: Selectors.userRefreshAuthFailure(state),
    isUserNewlyCreated: Selectors.isUserNewlyCreated(state),
    user: Selectors.getUserModel(state),
    locations: Selectors.getLocations(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<*>, ownProps: any) {
  return bindActionCreators(
    {
      ...ownProps,
      refreshUser: AuthActions.refreshUser
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Authenticate);
