import { ERROR_NOT_BOOLEAN, USER_CONSENT_UPDATED, USER_CONSENT_NOT_UPDATED, USER_CONSENT_INITIAL } from '../logger/constants';
import { isConsentGranted } from './index';
import { CONSENT_GRANTED, CONSENT_DECLINED, CONSENT_UNKNOWN } from '../utils/constants';
import { isBoolean } from '../utils/lang';
// User consent enum
var ConsentStatus = {
    GRANTED: CONSENT_GRANTED,
    DECLINED: CONSENT_DECLINED,
    UNKNOWN: CONSENT_UNKNOWN,
};
/**
 * The public user consent API exposed via SplitFactory, used to control if the SDK tracks and sends impressions and events or not.
 */
export function createUserConsentAPI(params) {
    var settings = params.settings, log = params.settings.log, syncManager = params.syncManager, _a = params.storage, events = _a.events, impressions = _a.impressions, impressionCounts = _a.impressionCounts;
    if (!isConsentGranted(settings))
        log.info(USER_CONSENT_INITIAL, [settings.userConsent]);
    return {
        setStatus: function (consent) {
            var _a, _b;
            // validate input param
            if (!isBoolean(consent)) {
                log.warn(ERROR_NOT_BOOLEAN, ['setUserConsent']);
                return false;
            }
            var newConsentStatus = consent ? CONSENT_GRANTED : CONSENT_DECLINED;
            if (settings.userConsent !== newConsentStatus) {
                log.info(USER_CONSENT_UPDATED, [settings.userConsent, newConsentStatus]); // @ts-ignore, modify readonly prop
                settings.userConsent = newConsentStatus;
                if (consent) { // resumes submitters if transitioning to GRANTED
                    (_a = syncManager === null || syncManager === void 0 ? void 0 : syncManager.submitter) === null || _a === void 0 ? void 0 : _a.start();
                }
                else { // pauses submitters and drops tracked data if transitioning to DECLINED
                    (_b = syncManager === null || syncManager === void 0 ? void 0 : syncManager.submitter) === null || _b === void 0 ? void 0 : _b.stop();
                    // @ts-ignore, clear method is present in storage for standalone and partial consumer mode
                    if (events.clear)
                        events.clear(); // @ts-ignore
                    if (impressions.clear)
                        impressions.clear();
                    if (impressionCounts)
                        impressionCounts.clear();
                }
            }
            else {
                log.info(USER_CONSENT_NOT_UPDATED, [newConsentStatus]);
            }
            return true;
        },
        getStatus: function () {
            return settings.userConsent;
        },
        Status: ConsentStatus
    };
}
