// @flow

import { useState, useEffect } from 'react';
import { range } from 'lodash';
import * as AvailableMetricsModel from '../../../models/available-metrics';
import * as WidgetPropsModel from '../../../models/widget-props';
import * as QueryResponseModel from '../../../models/query-response';
import ColHeatmap from '../../widgets-ui/col-heatmap';
import {
  getQueryPayloadType,
  buildPayload
} from '../../../services/query-api';
import { getColumnLabel } from '../../../utils/metricsTableHelpers';
import { dayOfWeekLabel, hourOfDayLabel } from '../../../utils/dates';
import { prettyValueToCompare } from '../../../utils/summaryPageHelpers';
import { buildWidgetDateString } from '../common/stringHelpers';
import { useHasWidgetChanged } from '../common/hooks';
import { useVisible } from 'react-hooks-visible';
import { fetchSQLQuery } from '../../../services/sql-api';
import { apiEndpointByMetricKey } from '../../../constants/api-endpoints';

const PeakTimeHeatmapWidget = ({
  widget,
  availableMetrics,
  locations,
  uiOptions = {}
}: WidgetPropsModel.peakTimeHeatmapT) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [autoTitle, setAutoTitle] = useState('');
  const [widgetDescription, setWidgetDescription] = useState('');
  const [tableRows, setTableRows] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const hasWidgetChanged = useHasWidgetChanged(widget);
  const [currentElement, isVisible] = useVisible((vi) => uiOptions.disableViewportLoading || vi > 0);

  const usingComps =
    !!widget.comparisonPeriod &&
    widget.comparisonPeriod.active &&
    widget.showChangeValue;

  const loadWidgetData = async (metric, locations) => {
    setLoading(true);
    setError(false);

    const path = apiEndpointByMetricKey(metric.metricKey);
    const endpointDetails = AvailableMetricsModel.getMetricEndpoint(
      metric.details
    );
    const queryPayloadType = getQueryPayloadType(path, endpointDetails); // TODO: handle getting paths without querystrings on them in this function (test with Sky)

    let taxonomies;
    if (metric.taxonomy) {
      taxonomies = [metric.taxonomy];
    } else if (metric.filter) {
      taxonomies = [`${metric.filter.key}:${metric.filter.value}`];
    }

    const payloadInput = {
      period: widget.period,
      locations: widget.locations,
      taxonomies,
      demographicFilter: widget.demographicFilter,
      aggregation: 'dayofweek-hourofday', // it has to be this aggregation type for this component to work
      metricKey: metric.metricKey,
      breakdownByDimensions: [],
    };

    const payloadInitialPeriod = buildPayload(queryPayloadType, payloadInput);

    try {
      const response = await fetchSQLQuery(payloadInitialPeriod, path, {
        returnErrors: true,
        return404AsError: false,
        metricKey: metric.metricKey,
      });

      let compResponse;
      if (usingComps) {
        const payloadComparisonPeriod = buildPayload(queryPayloadType, {
          ...payloadInput,
          period: widget.comparisonPeriod
        });

        compResponse = await fetchSQLQuery(payloadComparisonPeriod, path, {
          returnErrors: true,
          return404AsError: false,
          metricKey: metric.metricKey,
        });
      }

      if (!response || !response.segments || !response.segments.length) {
        setError(true);
        setLoading(false);
      }

      const unitType = QueryResponseModel.getUnitType(response, metric.metricKey);

      const allHours = response.segments.map(segment => {
        const { index } = segment;
        const hod = index.split('-')[1];
        return hod;
      });

      let minHour = 23;
      let maxHour = 0;

      if (locations.length) {
        // Find the earliest start and latest end, and restrict the table by those
        locations.forEach(location => {
          if (widget.locations.includes(location.id) && location.openingTimes) {
            location.openingTimes.forEach(openingTime => {
              if (openingTime.startHour && openingTime.startHour < minHour) {
                minHour = openingTime.startHour;
              }
              if (openingTime.stopHour && openingTime.stopHour > maxHour) {
                maxHour = openingTime.stopHour;
              }
            });
          }
          // Avoid including the segment immediately after close
          maxHour = maxHour - 1;
        });

        // If there's something wrong with the opening times, show everything
        if (minHour > maxHour) {
          minHour = Math.min(...allHours);
          maxHour = Math.max(...allHours);
        }
      } else {
        minHour = Math.min(...allHours);
        maxHour = Math.max(...allHours);
      }

      const numberedTableRows = range(minHour, maxHour + 1);
      const labelledTableRows = numberedTableRows.map(time =>({
          label: hourOfDayLabel(time),
        })
      );

      // this will create an array with labels in the format:
      // 0 = Sunday
      // 1 = Monday
      // ...
      // 6 = Saturday
      const builtTableColumns = range(0, 7).map(dayNum => ({
        label: dayOfWeekLabel(dayNum, true),
        unitType,
        rows: new Array(24).fill(null)
      }));

      if (response.segments) {
        response.segments.forEach(segment => {
          const { index } = segment;
          const value = QueryResponseModel.getSegmentOverallValue(segment, metric.metricKey);
          const cell: { value: number, changeValue?: number, unitType: string } = { value, unitType };

          if (compResponse) {
            const compSegments = QueryResponseModel.getSegments(compResponse);
            const compSegment = compSegments.find(s => s.index === index);
            const compValue = QueryResponseModel.getSegmentOverallValue(
              compSegment,
              metric.metricKey,
            );
            const { result } = prettyValueToCompare(compValue, value);
            cell.changeValue = result;
          }

          // work out where in the columns array exactly to put this cell
          // NB. dow is returned from the API in the format:
          // 1 = Sunday
          // 2 = Monday
          // ...
          // 7 = Saturday
          const [dow, hod] = index.split('-');
          const hodNumber = Number(hod);
          if (Number.isNaN(hodNumber)) throw new Error('hodNumber is NaN');
          const hodIndex = hodNumber - minHour;

          // insert the new cell into the columns array
          builtTableColumns[dow].rows[hodIndex] = cell;
        });
      }

      // Move sunday to the end column
      const sunday = builtTableColumns[0];
      const orderedTableColumns = [...builtTableColumns.slice(1), sunday];

      setTableColumns(orderedTableColumns);
      setTableRows(labelledTableRows);
      setLoading(false);
    } catch (e) {
      setError(true);
      setLoading(false);
      console.error(
        'API call or something else failed for Peak Time Heatmap widget',
        e
      );
    }
  };

  const [fetchRequired, setFetchRequired] = useState(false);
  useEffect(() => {
    if (hasWidgetChanged) setFetchRequired(true);
  }, [hasWidgetChanged]);

  useEffect(() => {
    if (fetchRequired && isVisible) {
      setFetchRequired(false);

      const metric = {
        ...widget.metric,
        details: AvailableMetricsModel.findMetricDetails(
          availableMetrics,
          widget.metric.metricGroupKey,
          widget.metric.metricKey
        )
      };

      if (!metric || !metric.details) {
        console.error(
          `No metric found in availableMetrics for the Peak Time Heatmap widget: ${widget.metric.metricGroupKey}:${widget.metric.metricKey}`
        );
        setError(true);
      } else {
        setAutoTitle(`${getColumnLabel(metric, availableMetrics, true, true)}`);
        if (!uiOptions.disableDescription) {
          setWidgetDescription(
            AvailableMetricsModel.getMetricDescription(metric.details)
          );
        }
        loadWidgetData(metric, locations);
      }
    }
  }, [widget, fetchRequired, isVisible, locations]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = widget.customTitle || autoTitle;
  const subtitle = buildWidgetDateString(widget, true);

  return (
    <div ref={currentElement}>
      <ColHeatmap
        errors={error}
        loading={loading}
        title={title}
        description={widgetDescription}
        subtitle={subtitle}
        tableColumns={tableColumns}
        tableRows={tableRows}
        dataKey={widget.showChangeValue ? 'changeValue' : 'value'}
        shadeBy="table"
      />
    </div>
  );
};

export default PeakTimeHeatmapWidget;
