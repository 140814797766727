import { Fragment } from 'react';
import styled from 'styled-components';
import {
  Popover,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Intent,
} from '@blueprintjs/core';
import { prettyLabel } from '../../utils/prettyLabel';
import { BiggerTablet, Tablet } from '../layouts/devices-sizes';

const TypeSelector = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: flex-end;
`;
const SelectorLabel = styled.p`
  margin: 0 0.5rem 0 0;
`;

const HeatmapTypesMenu = ({ heatmapTypes, selectedValues, setActiveType }) => {
  return (
    <div>
      {heatmapTypes && heatmapTypes.length > 0 && (
        <>
          <BiggerTablet>
            <TypeSelector>
              <SelectorLabel>Heatmap Type:</SelectorLabel>
              <ButtonGroup>
                {heatmapTypes.sort().map((type) => (
                  <Button
                    key={type}
                    intent={selectedValues.heatmapType === type ? Intent.PRIMARY : null}
                    onClick={() => setActiveType(type)}
                  >
                    {prettyLabel(type)}
                  </Button>
                ))}
              </ButtonGroup>
            </TypeSelector>
          </BiggerTablet>
          <Tablet>
            <TypeSelector>
              <Popover>
                <Button icon="polygon-filter">Heatmap Type</Button>
                <Menu>
                  {heatmapTypes.sort().map((type) => (
                    <MenuItem
                      key={type}
                      icon={selectedValues.heatmapType === type ? 'tick' : ''}
                      onClick={() => setActiveType(type)}
                      label={prettyLabel(type)}
                    />
                  ))}
                </Menu>
              </Popover>
            </TypeSelector>
          </Tablet>
        </>
      )}
    </div>
  );
};

export default HeatmapTypesMenu;
