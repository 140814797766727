// @flow

import { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment-timezone';
import * as RecordingModel from '../../models/recording';
import * as HeatmapModel from '../../models/heatmap';
import RecordingAndFilterPanel from './recording-filter-panel';
import ImagePanel from './image-panel';
import { AGE, GENDER, ROLE } from '../../constants/demographic-types';

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  max-width: var(--widget-max-width);
  margin: auto;
`;

type Props = {
  recordings: RecordingModel.t[],
  fetchHeatmaps(recordingId: string): any,
  getSourceUrl(recordingId: string, heatmapId: string): any,
  fetchingHeatmaps: boolean,
  fetchingHeatmapSourceUrl: boolean
};

type timePeriodT = {
  periodType?: string,
  periodValue?: string
};

export type selectedValuesT = {
  recordingId: string,
  demographicKeys: {
    filterDimension?: string,
    filterValue?: string
  },
  timePeriod: timePeriodT,
  heatmapType: string,
  selectedHeatmapsId: string[]
};
export type heatmapConfigT = {
  demographicDescriptor?: {
    [string]: string
  },
  sourceUrl: string
};

type State = {
  activeHeatmaps: heatmapConfigT[],
  availableValues: {
    heatmapTypes: string[]
  },
  selectedValues: selectedValuesT,
  newRecordingRequested: boolean
};

// TODO: Move a lot of these settings into pageSettings reducer, and have the container/selectors take care of it

class HeatmapViewer extends Component<Props, State> {
  state = {
    activeHeatmaps: [],
    availableValues: {
      heatmapTypes: []
    },
    selectedValues: {
      recordingId: '',
      demographicKeys: {},
      timePeriod: {},
      heatmapType: 'lingerAccumulatedTime',
      selectedHeatmapsId: []
    },
    newRecordingRequested: false
  };

  componentDidMount() {
    const { recordings = [] } = this.props;
    // set a default initially selected recordingId
    const initialRecordingId =
      !!recordings.length &&
      recordings.sort((a, b) =>
        a.location && a.location.name > b.location.name ? 1 : -1
      )[0].id;
    if (initialRecordingId) this.setActiveRecordingId(initialRecordingId, true);
  }

  componentDidUpdate() {
    const recording = this.getActiveRecording(this.props.recordings);
    const { timePeriod = {} } = this.state.selectedValues;

    if (recording && recording.heatmaps) {
      if (!timePeriod.periodType && !timePeriod.periodValue) {
        const timezone = RecordingModel.getTimezone(recording);
        const initialPeriod = HeatmapModel.getInitialPeriod(recording.heatmaps);

        if (initialPeriod) {
          const { periodType, periodValue } = initialPeriod;
          const value = moment(periodValue)
            .tz(timezone)
            .toISOString();
          this.setActivePeriod(periodType, value);
        }
      } else if (
        timePeriod.periodType &&
        timePeriod.periodValue &&
        this.state.newRecordingRequested
      ) {
        this.setState({ newRecordingRequested: false }, () => {
          // $FlowIgnore - checked 2 lines above
          this.setActivePeriod(timePeriod.periodType, timePeriod.periodValue);
        });
      }
    }
  }

  setActiveRecordingId(recordingId: string, isInitialSetting: boolean) {
    this.setState({
      newRecordingRequested: isInitialSetting ? false : true,
      selectedValues: {
        ...this.state.selectedValues,
        recordingId
      }
    });
    this.props.fetchHeatmaps(recordingId);
  }

  setActiveFilter(filterDimension: string) {
    const nextSelectedValues = {
      ...this.state.selectedValues,
      demographicKeys: { filterDimension }
    };

    const { selectedHeatmaps, heatmapTypes } = this.onSettingChange(
      nextSelectedValues
    );

    nextSelectedValues.selectedHeatmapsId = selectedHeatmaps.map(h => h.id);

    this.setState(
      {
        selectedValues: nextSelectedValues,
        availableValues: {
          ...this.state.availableValues,
          heatmapTypes
        }
      },
      this.loadImage
    );
  }

  setActivePeriod(periodType: string, periodValue: string) {
    const nextSelectedValues = {
      ...this.state.selectedValues,
      timePeriod: { periodType, periodValue }
    };

    const { selectedHeatmaps, heatmapTypes } = this.onSettingChange(
      nextSelectedValues
    );
    nextSelectedValues.selectedHeatmapsId = selectedHeatmaps.map(h => h.id);

    this.setState(
      {
        selectedValues: nextSelectedValues,
        availableValues: {
          ...this.state.availableValues,
          heatmapTypes
        }
      },
      this.loadImage
    );
  }

  setActiveType(heatmapType: string) {
    const nextSelectedValues = {
      ...this.state.selectedValues,
      heatmapType
    };

    const { selectedHeatmaps, heatmapTypes } = this.onSettingChange(
      nextSelectedValues
    );
    nextSelectedValues.selectedHeatmapsId = selectedHeatmaps.map(h => h.id);

    this.setState(
      {
        selectedValues: nextSelectedValues,
        availableValues: {
          ...this.state.availableValues,
          heatmapTypes
        }
      },
      this.loadImage
    );
  }

  getActiveRecording(recordings: RecordingModel.t[]) {
    const { recordingId } = this.state.selectedValues;
    // TODO: use RecordingModel.getOneRecording instead
    return recordings.find(r => r.id === recordingId);
  }

  findActiveHeatmaps(heatmaps: HeatmapModel.t[]): HeatmapModel.t[] {
    const { selectedHeatmapsId } = this.state.selectedValues;
    const activeHeatmaps = heatmaps.filter(h => {
      return selectedHeatmapsId.includes(h.id);
    });
    return activeHeatmaps;
  }

  async loadImage() {
    const { recordings, getSourceUrl } = this.props;
    const activeRecording = this.getActiveRecording(recordings);

    if (activeRecording) {
      const allHeatmaps = RecordingModel.getHeatmaps(activeRecording);
      const activeHeatmaps = this.findActiveHeatmaps(allHeatmaps);
      this.setState({ activeHeatmaps: [] });

      activeHeatmaps.forEach(async heatmap => {
        const response = await getSourceUrl(activeRecording.id, heatmap.id);
        if (response) {
          const { demographicDescriptor } = heatmap;
          const { sourceUrl } = response;
          const heatmapConfig = {
            sourceUrl,
            demographicDescriptor
          };
          this.setState({
            activeHeatmaps: [...this.state.activeHeatmaps, heatmapConfig]
          });
        }
      });
    }
  }

  onSettingChange(newSelectedValues: selectedValuesT) {
    const activeRecording = this.getActiveRecording(this.props.recordings);
    if (!activeRecording) return {};

    const heatmaps = RecordingModel.getHeatmaps(activeRecording);
    const timezone = RecordingModel.getTimezone(activeRecording);

    const { demographicKeys = {}, heatmapType, timePeriod } =
      newSelectedValues || this.state.selectedValues;

    const heatmapsForSelectedDay = HeatmapModel.getHeatmapsForSelectedDay(
      heatmaps,
      timePeriod,
      timezone
    );
    const heatmapTypes = HeatmapModel.getHeatmapTypesFromList(
      heatmapsForSelectedDay
    );

    const selectedHeatmaps: HeatmapModel.t[] = heatmapsForSelectedDay
      .filter(h => {
        return h.heatmapType === heatmapType;
      })
      .filter(h => {
        const hasNoDemographicInfo = heatmap =>
          !h.demographicDescriptor ||
          (!h.demographicDescriptor.age &&
            !h.demographicDescriptor.gender &&
            !h.demographicDescriptor.role);

        if (
          (hasNoDemographicInfo(h) && !demographicKeys.filterDimension) ||
          (demographicKeys.filterDimension === AGE &&
            h.demographicDescriptor &&
            h.demographicDescriptor.age) ||
          (demographicKeys.filterDimension === GENDER &&
            h.demographicDescriptor &&
            h.demographicDescriptor.gender) ||
          (demographicKeys.filterDimension === ROLE &&
            h.demographicDescriptor &&
            h.demographicDescriptor.role)
        ) {
          return true;
        }
        return false;
      });
    return { heatmapTypes, selectedHeatmaps };
  }

  render() {
    const {
      recordings,
      fetchingHeatmaps,
      fetchingHeatmapSourceUrl
    } = this.props;
    const { activeHeatmaps, selectedValues, availableValues } = this.state;

    const activeRecording = this.getActiveRecording(recordings);
    const timezone =
      activeRecording && RecordingModel.getTimezone(activeRecording);
    const heatmaps = activeRecording
      ? RecordingModel.getHeatmaps(activeRecording)
      : [];

    const noHeatmapsForRecording =
      !fetchingHeatmaps && (!heatmaps || !heatmaps.length);
    const showImageViewer = !fetchingHeatmaps && !noHeatmapsForRecording;

    // should these  be stored in state along with availableValues.heatmapTypes?
    const demographicKeys = HeatmapModel.getDemographicKeysFromList(heatmaps);
    const timePeriods = HeatmapModel.getTimePeriodsFromList(heatmaps);

    return (
      <Wrapper>
        <RecordingAndFilterPanel
          noHeatmapsForRecording={noHeatmapsForRecording}
          fetchingHeatmaps={fetchingHeatmaps}
          recordings={recordings.sort((a, b) => a.name.localeCompare(b.name))}
          activeRecording={activeRecording}
          availableValues={{ demographicKeys }}
          selectedValues={selectedValues}
          setActiveRecordingId={this.setActiveRecordingId.bind(this)}
          setActiveFilter={this.setActiveFilter.bind(this)}
        />
        {showImageViewer && (
          <ImagePanel
            availableValues={{
              heatmapTypes: availableValues.heatmapTypes,
              timePeriods
            }}
            selectedValues={selectedValues}
            setActivePeriod={this.setActivePeriod.bind(this)}
            setActiveType={this.setActiveType.bind(this)}
            heatmaps={activeHeatmaps}
            fetchingHeatmapSourceUrl={fetchingHeatmapSourceUrl}
            noHeatmapFound={!activeHeatmaps || !activeHeatmaps.length}
            timezone={timezone}
          />
        )}
      </Wrapper>
    );
  }
}

export default HeatmapViewer;
