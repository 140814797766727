import { submitterManagerFactory } from './submitters/submitterManager';
import { PUSH_SUBSYSTEM_UP, PUSH_SUBSYSTEM_DOWN } from './streaming/constants';
import { SYNC_START_POLLING, SYNC_CONTINUE_POLLING, SYNC_STOP_POLLING } from '../logger/constants';
import { isConsentGranted } from '../consent';
/**
 * Online SyncManager factory.
 * Can be used for server-side API, and client-side API with or without multiple clients.
 *
 * @param pollingManagerFactory allows to specialize the SyncManager for server-side or client-side API by passing
 * `pollingManagerSSFactory` or `pollingManagerCSFactory` respectively.
 * @param pushManagerFactory optional to build a SyncManager with or without streaming support
 */
export function syncManagerOnlineFactory(pollingManagerFactory, pushManagerFactory) {
    /**
     * SyncManager factory for modular SDK
     */
    return function (params) {
        var settings = params.settings, _a = params.settings, log = _a.log, streamingEnabled = _a.streamingEnabled;
        /** Polling Manager */
        var pollingManager = pollingManagerFactory && pollingManagerFactory(params);
        /** Push Manager */
        var pushManager = streamingEnabled && pollingManager && pushManagerFactory ?
            pushManagerFactory(params, pollingManager) :
            undefined;
        /** Submitter Manager */
        // It is not inyected as push and polling managers, because at the moment it is required
        var submitter = submitterManagerFactory(params);
        /** Sync Manager logic */
        function startPolling() {
            if (pollingManager.isRunning()) {
                log.info(SYNC_CONTINUE_POLLING);
            }
            else {
                log.info(SYNC_START_POLLING);
                pollingManager.start();
            }
        }
        function stopPollingAndSyncAll() {
            log.info(SYNC_STOP_POLLING);
            // if polling, stop
            if (pollingManager.isRunning())
                pollingManager.stop();
            // fetch splits and segments. There is no need to catch this promise (it is always resolved)
            pollingManager.syncAll();
        }
        if (pushManager) {
            pushManager.on(PUSH_SUBSYSTEM_UP, stopPollingAndSyncAll);
            pushManager.on(PUSH_SUBSYSTEM_DOWN, startPolling);
        }
        var running = false; // flag that indicates whether the syncManager has been started (true) or stopped (false)
        var startFirstTime = true; // flag to distinguish calling the `start` method for the first time, to support pausing and resuming the synchronization
        return {
            // Exposed for fine-grained control of synchronization.
            // E.g.: user consent, app state changes (Page hide, Foreground/Background, Online/Offline).
            pollingManager: pollingManager,
            pushManager: pushManager,
            submitter: submitter,
            /**
             * Method used to start the syncManager for the first time, or resume it after being stopped.
             */
            start: function () {
                running = true;
                // start syncing splits and segments
                if (pollingManager) {
                    if (pushManager) {
                        // Doesn't call `syncAll` when the syncManager is resuming
                        if (startFirstTime) {
                            pollingManager.syncAll();
                            startFirstTime = false;
                        }
                        pushManager.start();
                    }
                    else {
                        pollingManager.start();
                    }
                }
                // start periodic data recording (events, impressions, telemetry).
                if (isConsentGranted(settings))
                    submitter.start();
            },
            /**
             * Method used to stop/pause the syncManager.
             */
            stop: function () {
                running = false;
                // stop syncing
                if (pushManager)
                    pushManager.stop();
                if (pollingManager && pollingManager.isRunning())
                    pollingManager.stop();
                // stop periodic data recording (events, impressions, telemetry).
                submitter.stop();
            },
            isRunning: function () {
                return running;
            },
            flush: function () {
                if (isConsentGranted(settings))
                    return submitter.execute();
                else
                    return Promise.resolve();
            },
            // [Only used for client-side]
            // If polling and push managers are defined (standalone mode), they implement the interfaces for client-side
            shared: function (matchingKey, readinessManager, storage) {
                if (!pollingManager)
                    return;
                var mySegmentsSyncTask = pollingManager.add(matchingKey, readinessManager, storage);
                return {
                    isRunning: mySegmentsSyncTask.isRunning,
                    start: function () {
                        if (pushManager) {
                            if (pollingManager.isRunning()) {
                                // if doing polling, we must start the periodic fetch of data
                                if (storage.splits.usesSegments())
                                    mySegmentsSyncTask.start();
                            }
                            else {
                                // if not polling, we must execute the sync task for the initial fetch
                                // of segments since `syncAll` was already executed when starting the main client
                                mySegmentsSyncTask.execute();
                            }
                            pushManager.add(matchingKey, mySegmentsSyncTask);
                        }
                        else {
                            if (storage.splits.usesSegments())
                                mySegmentsSyncTask.start();
                        }
                    },
                    stop: function () {
                        // check in case `client.destroy()` has been invoked more than once for the same client
                        var mySegmentsSyncTask = pollingManager.get(matchingKey);
                        if (mySegmentsSyncTask) {
                            // stop syncing
                            if (pushManager)
                                pushManager.remove(matchingKey);
                            if (mySegmentsSyncTask.isRunning())
                                mySegmentsSyncTask.stop();
                            pollingManager.remove(matchingKey);
                        }
                    },
                    flush: function () { return Promise.resolve(); }
                };
            }
        };
    };
}
