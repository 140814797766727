import { objectAssign } from '../utils/lang/objectAssign';
import { find } from '../utils/lang';
import { _Map } from '../utils/lang/maps';
export var LogLevels = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    WARN: 'WARN',
    ERROR: 'ERROR',
    NONE: 'NONE'
};
var LogLevelIndexes = {
    DEBUG: 1,
    INFO: 2,
    WARN: 3,
    ERROR: 4,
    NONE: 5
};
export function isLogLevelString(str) {
    return !!find(LogLevels, function (lvl) { return str === lvl; });
}
// exported for testing purposes only
export function _sprintf(format, args) {
    if (format === void 0) { format = ''; }
    if (args === void 0) { args = []; }
    var i = 0;
    return format.replace(/%s/g, function () {
        return args[i++];
    });
}
var defaultOptions = {
    prefix: 'splitio',
    logLevel: LogLevels.NONE,
    showLevel: true,
};
var Logger = /** @class */ (function () {
    function Logger(options, codes) {
        this.options = objectAssign({}, defaultOptions, options);
        this.codes = codes || new _Map();
        this.logLevel = LogLevelIndexes[this.options.logLevel];
    }
    Logger.prototype.setLogLevel = function (logLevel) {
        this.options.logLevel = logLevel;
        this.logLevel = LogLevelIndexes[logLevel];
    };
    Logger.prototype.debug = function (msg, args) {
        if (this._shouldLog(LogLevelIndexes.DEBUG))
            this._log(LogLevels.DEBUG, msg, args);
    };
    Logger.prototype.info = function (msg, args) {
        if (this._shouldLog(LogLevelIndexes.INFO))
            this._log(LogLevels.INFO, msg, args);
    };
    Logger.prototype.warn = function (msg, args) {
        if (this._shouldLog(LogLevelIndexes.WARN))
            this._log(LogLevels.WARN, msg, args);
    };
    Logger.prototype.error = function (msg, args) {
        if (this._shouldLog(LogLevelIndexes.ERROR))
            this._log(LogLevels.ERROR, msg, args);
    };
    Logger.prototype._log = function (level, msg, args) {
        if (typeof msg === 'number') {
            var format = this.codes.get(msg);
            msg = format ? _sprintf(format, args) : "Message code " + msg + (args ? ', with args: ' + args.toString() : '');
        }
        else {
            if (args)
                msg = _sprintf(msg, args);
        }
        var formattedText = this._generateLogMessage(level, msg);
        console.log(formattedText);
    };
    Logger.prototype._generateLogMessage = function (level, text) {
        var textPre = ' => ';
        var result = '';
        if (this.options.showLevel) {
            result += '[' + level + ']' + (level === LogLevels.INFO || level === LogLevels.WARN ? ' ' : '') + ' ';
        }
        if (this.options.prefix) {
            result += this.options.prefix + textPre;
        }
        return result += text;
    };
    Logger.prototype._shouldLog = function (level) {
        return level >= this.logLevel;
    };
    return Logger;
}());
export { Logger };
