// @flow

import styled from 'styled-components';
import moment from 'moment-timezone';
import { Panel, PanelHeader, PanelBody, Row } from '../panel';
import {
  Popover,
  Button,
  ButtonGroup,
  Position,
  Spinner,
  Classes
} from '@blueprintjs/core';
import { DatePicker } from '@blueprintjs/datetime';
import NotFoundText from './not-found-text';
import ButtonIconColorer from '../button-icon-colorer';
import HeatmapGrid from './heatmap-grid';
import { formatDateStr } from '../../utils/dates';
import { breakpoints } from '../../styles/variables';
import HeatmapTypesMenu from './heatmap-types-menu';
import type { heatmapConfigT, selectedValuesT } from './index';

const StyledPanel = styled(Panel)`
  margin-top: 1em;
  box-shadow: var(--widget-box-shadow);
`;
const SpreadRow = styled(Row)`
  justify-content: space-between;
`;
const TimePeriodSelector = styled.div`
  flex-shrink: 0;
`;

const ImgPanel = styled(PanelBody)`
  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 0;
  }
`;

const LeftButton = styled(Button)`
  margin-right: 0.5rem;
`;

const RightButton = styled(Button)`
  margin-left: 0.5rem;
`;

const formatDate = (date, tz) => {
  return moment(date)
    .tz(tz)
    .format(formatDateStr);
};

// TODO: move to a picker?
const DatesMenu = ({ periodType, dates, selected, onClick, timezone }) => {
  const onChange = (selectedDate, isUserChange) => {
    if (!selectedDate) return false;

    if (shouldBeDisabled(selectedDate)) return false;
    if (!isUserChange) return false;

    const start = moment(selectedDate)
      .tz(timezone)
      .toISOString();

    onClick(periodType, start);
  };

  const modifiersDates = dates.map(d => {
    const newDate = moment(d).tz(timezone);
    return [newDate.year(), newDate.month(), newDate.date()];
  });

  const shouldBeDisabled = day => {
    const mDay = moment(day).tz(timezone);
    const year = mDay.year();
    const month = mDay.month();
    const date = mDay.date();
    const heatmapForDateExists = modifiersDates.some(
      d => d[0] === year && d[1] === month && d[2] === date
    );

    if (heatmapForDateExists) return false;
    return true;
  };

  return (
    <DatePicker
      value={moment(selected)
        .tz(timezone)
        .toDate()}
      modifiers={{ 'disabled-day': shouldBeDisabled }}
      onChange={onChange}
    />
  );
};

type Props = {
  availableValues: {
    heatmapTypes: any[],
    timePeriods: Object
  },
  selectedValues: selectedValuesT,
  setActivePeriod(periodType: string, periodValue: string): void,
  setActiveType(heatmapType: string): void,
  heatmaps: heatmapConfigT[],
  fetchingHeatmapSourceUrl: boolean,
  noHeatmapFound: boolean,
  timezone?: string
};

const ImagePanel = ({
  availableValues,
  selectedValues,
  setActivePeriod,
  setActiveType,
  heatmaps,
  fetchingHeatmapSourceUrl,
  noHeatmapFound,
  timezone
}: Props) => {
  const selectedDateText = selectedValues.timePeriod.periodValue
    ? formatDate(selectedValues.timePeriod.periodValue, timezone)
    : 'Select a day';

  return (
    <>
      <StyledPanel>
        <PanelHeader>
          <SpreadRow>
            <TimePeriodSelector>
              <LeftButton
                icon="chevron-left"
                onClick={() => {
                  const index = availableValues.timePeriods.days.indexOf(selectedValues.timePeriod.periodValue);
                  const newIndex = index + 1;
                  if (newIndex < availableValues.timePeriods.days.length) {
                    setActivePeriod('day', availableValues.timePeriods.days[newIndex]);
                  }
                }}
                disabled={availableValues.timePeriods.days.indexOf(selectedValues.timePeriod.periodValue) === availableValues.timePeriods.days.length - 1}
              />
              <ButtonGroup>
                <Popover
                  content={
                    <DatesMenu
                      onClick={setActivePeriod}
                      periodType="day"
                      dates={availableValues.timePeriods.days}
                      selected={selectedValues.timePeriod.periodValue}
                      timezone={timezone}
                    />
                  }
                  position={Position.BOTTOM_LEFT}
                >
                  <ButtonIconColorer>
                    <Button
                      icon="calendar"
                      rightIcon="caret-down"
                      text={selectedDateText}
                    />
                  </ButtonIconColorer>
                </Popover>
              </ButtonGroup>
              <RightButton
                icon="chevron-right"
                onClick={() => {
                  const index = availableValues.timePeriods.days.indexOf(selectedValues.timePeriod.periodValue);
                  const newIndex = index - 1;
                  if (newIndex >= 0) {
                    setActivePeriod('day', availableValues.timePeriods.days[newIndex]);
                  }
                }}
                disabled={availableValues.timePeriods.days.indexOf(selectedValues.timePeriod.periodValue) === 0}
              />
            </TimePeriodSelector>
            {fetchingHeatmapSourceUrl && <Spinner className={Classes.SMALL} />}
            <HeatmapTypesMenu
              heatmapTypes={availableValues && availableValues.heatmapTypes}
              selectedValues={selectedValues}
              setActiveType={setActiveType}
            />
          </SpreadRow>
        </PanelHeader>
      </StyledPanel>
      <StyledPanel>
        <ImgPanel>
          {noHeatmapFound ? (
            <NotFoundText>
              No heatmap found for this date, demographic, or type.
            </NotFoundText>
          ) : (
            <HeatmapGrid
              heatmaps={heatmaps}
              selectedValues={selectedValues}
              selectedDateText={selectedDateText}
            />
          )}
        </ImgPanel>
      </StyledPanel>
    </>
  );
};

export default ImagePanel;
