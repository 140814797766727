'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = filterLimit;

var _concurrent = require('./__internal__/concurrent');

var _concurrent2 = _interopRequireDefault(_concurrent);

var _resolveAll = require('./__internal__/resolveAll');

var _resolveAll2 = _interopRequireDefault(_resolveAll);

var _utils = require('./__internal__/utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function filterLimit(iterable, filterer, limit) {
  var resolved = {};

  return (0, _resolveAll2.default)(iterable).then((0, _concurrent2.default)({
    limit: limit,
    onItemResolved: function onItemResolved(value, key, values) {
      var maybePromise = filterer(value, key, values);
      return Promise.resolve(maybePromise).then(_utils.toBoolean).then(function (bool) {
        if (bool) {
          resolved[key] = value;
        }
      });
    },

    onItemCompleted: function onItemCompleted(done) {
      return function (count, values) {
        if (count === values.length) {
          done((0, _utils.toArray)(resolved));
        }
      };
    }
  }));
}