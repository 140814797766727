import moment from 'moment';
import * as QueryApiRequestModel from '../models/query-api-request';
import { getAuth0Client } from './auth0';
import { DISABLE_CACHE, IS_EMAIL_VIEW, QUERY_API_BASE } from '../config/vars';
import { db } from '../models/database';
import { neverCache } from '../utils/cacheExclusions';
import { errorToast } from '../utils/toaster';

// TODO: caching for everything except email view

const inFlightMessages = {};
let connected = false;

type otherOptionsT = {
  metricKey: string,
};

/**
 *
 * start,
 * end,
 * entities,
 * entityType,
 * role,
 * gender,
 * ages,
 * aggregationPeriod,
 *
 * @param {*} path
 * @param {*} body
 * @returns
 */
const call = async (action, path, body, otherOptions = {}) => {
  let bypassCache = otherOptions.bypassCache;
  let shouldCache = true;

  let dbRecord = null;
  if (
    !IS_EMAIL_VIEW &&
    action !== 'status' &&
    !neverCache(`${action}/${path}`) &&
    db &&
    db.isOpen()
  ) {
    try {
      dbRecord = await db.metricAPICache.get({
        path: `${action}/${path}`,
        payload: JSON.stringify(body) || '',
      });
    } catch (err) {
      console.log(`Failed to get record from local db: ${err}`);
    }
  }

  // if there's no cached record, or the existing cached record is stale (> 3 hours old), fetch a new version
  if (!dbRecord || (dbRecord && dbRecord.timestamp < new Date() - 10800000)) {
    bypassCache = true;
  }

  // bypass the cache if the user is requesting today's data
  try {
    let bodyObj = body;
    if (typeof body === 'string') {
      bodyObj = JSON.parse(body);
    }

    if (
      bodyObj.period &&
      typeof bodyObj.period === 'object' &&
      bodyObj.period.end === new Date().toISOString().split('T')[0]
    ) {
      bypassCache = true;
    }
  } catch (e) {}

  // return the cached record if possible
  if (!bypassCache && dbRecord) {
    return dbRecord.response;
  }

  // do not cache some routes
  if (IS_EMAIL_VIEW || action === 'status' || neverCache(`${action}/${path}`)) {
    shouldCache = false;
  }

  // do not cache if it is disabled for the environment
  if (DISABLE_CACHE === true) {
    shouldCache = false;
  }

  const auth0Client = getAuth0Client();
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    if (token) {
      const requestId = Math.random().toString(36).slice(2);
      console.log(`REQUEST [${requestId}]:`, action, path, body);

      if (!window.queryWorker) {
        window.queryWorker = new Worker(
          new URL('../assets/query-worker.js', import.meta.url),
          { type: 'module' }
        );

        window.queryWorker.onerror = (e) => {
          console.error(e.error);
        };

        window.queryWorker.onmessage = (e) => {
          if (e.data && e.data.connected) {
            console.log('Query worker connected to websocket');
            connected = true;
          } else if (
            e.data &&
            e.data.complete &&
            e.data.res &&
            typeof e.data.requestId === 'string' &&
            typeof inFlightMessages[e.data.requestId].complete === 'function'
          ) {
            inFlightMessages[e.data.requestId].complete(e.data.res);
          } else if (e.data && e.data.disconnected) {
            console.log('Query worker disconnected from websocket');
            connected = false;
          }
        };
      }

      // Tell the query worker to connect to the websocket server
      if (!connected) {
        await new Promise((resolve) => {
          window.queryWorker.postMessage({
            connect: true,
            url: QUERY_API_BASE,
            token,
          });

          const poller = () => {
            if (connected) {
              resolve();
            } else {
              setTimeout(poller, 100);
            }
          };

          setTimeout(poller, 100);
        });
      }

      return new Promise((resolve) => {
        inFlightMessages[requestId] = {
          complete: (data) => {
            resolve(data);
            delete inFlightMessages[requestId];
          },
        };

        window.queryWorker.postMessage({
          action,
          path,
          body,
          requestId,
          synthetic: window.isDemoOrg,
        });
      })
        .then((res) => {
          console.log(`RESPONSE [${requestId}]:`, action, path, res);
          if (res.statusCode !== 200) {
            console.warn(
              `Server responded with ${res.statusCode} when calling ${
                res.route
              }${res.message ? `: ${res.message}` : ''}`
            );
          }
          return res;
        })
        .then(async (res) => {
          if (
            shouldCache &&
            action &&
            path &&
            path.length > 0 &&
            db &&
            db.isOpen()
          ) {
            try {
              await db.metricAPICache.put({
                path: `${action}/${path}`,
                timestamp: new Date() - 1,
                payload: JSON.stringify(body) || '',
                response: res.body,
              });
            } catch (err) {
              console.log(`Failed to put record in local db: ${err}`);
            }
          }
          return res.body;
        })
        .catch((error) => {
          console.error(error);
          if (otherOptions.returnErrors) {
            throw error;
          } else {
            if (error.response && error.response.status !== 404) {
              errorToast({
                message: 'Error fetching analytics data',
                timeout: 3000,
              });
            }
          }
        });
    }
  }
  return false;
};

const aggregateMetricType = (metricKey: string): string => {
  switch (metricKey) {
    case 'entries':
    case 'passers_by':
    case 'area_total_dwell_time':
    case 'area_entries':
    case 'sales_transaction_count':
    case 'sales_units':
    case 'sales_volume':
    case 'internal_movement':
    case 'internal_passers_by':
    case 'internal_entries':
    case 'internal_line_crossings':
      return 'sum';
    default:
      return 'average';
  }
};

// Drop in replacement for old query API fetchQuery method
export const fetchSQLQuery = async (
  query: QueryApiRequestModel.t,
  path: string,
  otherOptions?: otherOptionsT
) => {
  const endDate = moment.utc(query.period.end);
  endDate.add(1, 'day');
  endDate.subtract(1, 'second');
  const filteredBreakdownByDimensions = query.breakdownByDimensions
    ? Array.isArray(query.breakdownByDimensions)
      ? query.breakdownByDimensions.filter((d) => !!d)
      : [query.breakdownByDimensions]
    : undefined;

  const breakdownByDimensions =
    filteredBreakdownByDimensions && filteredBreakdownByDimensions.length
      ? filteredBreakdownByDimensions
      : undefined;

  const body = {
    start: moment.utc(query.period.start).toISOString(),
    end: endDate.toISOString(),
    entities: query.locations,
    entityType: 'location',
    roles: query.roles,
    ages: query.ages,
    genders: query.genders,
    aggregationPeriod: query.aggregation,
    breakdownByDimensions,
    facets: query.facets
      ? query.facets
      : ['segments', 'summary', 'thumbnails', 'estimates'],
    taxonomy: query.taxonomies,
    areaType: undefined,
  };

  if (query.areaType) {
    body.areaType = query.areaType;
  }

  if (
    typeof body.aggregationPeriod === 'string' &&
    ['dayofweek', 'hourofday', 'dayofweek-hourofday'].includes(
      body.aggregationPeriod.toLowerCase()
    )
  ) {
    body.facets.push('aggregates');
  }

  let res = null;
  try {
    res = await call('metric', path, body);
  } catch (err) {
    console.log(err);
  }

  const aggregationPeriod = typeof body.aggregationPeriod === 'string' ? body.aggregationPeriod.toLowerCase() : null;

  if (res) {
    // If we want to graph aggregate data, then we need to do some reshaping to make it work smoothly
    // TODO: remove this tech debt
    if (
      otherOptions &&
      otherOptions.metricKey &&
      ['dayofweek', 'hourofday', 'dayofweek-hourofday'].includes(
        aggregationPeriod
      )
    ) {
      return {
        segments: res.aggregates
          ? res.aggregates.map((item) => ({
              ...item,
              [otherOptions.metricKey.split('__')[0]]:
                item[
                  `${aggregateMetricType(
                    otherOptions.metricKey.split('__')[0]
                  )}_${otherOptions.metricKey}`
                ],
            }))
          : [],
        meta: res.meta,
        thumbnails: res.thumbnails,
      };
    }

    return res;
  }

  return null;
};

export const sendMessage = async (action: string, path: string, body: Object): Object | Array<Object> => {
  try {
    return call(action, path, body);
  } catch (error) {
    console.log(error);
    return null;
  }
};
