import { __extends } from "tslib";
import { AbstractSegmentsCacheSync } from '../AbstractSegmentsCacheSync';
/**
 * Default MySegmentsCacheInMemory implementation that stores MySegments in memory.
 * Supported by all JS runtimes.
 */
var MySegmentsCacheInMemory = /** @class */ (function (_super) {
    __extends(MySegmentsCacheInMemory, _super);
    function MySegmentsCacheInMemory() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.segmentCache = {};
        return _this;
    }
    MySegmentsCacheInMemory.prototype.clear = function () {
        this.segmentCache = {};
    };
    MySegmentsCacheInMemory.prototype.addToSegment = function (name) {
        this.segmentCache[name] = true;
        return true;
    };
    MySegmentsCacheInMemory.prototype.removeFromSegment = function (name) {
        delete this.segmentCache[name];
        return true;
    };
    MySegmentsCacheInMemory.prototype.isInSegment = function (name) {
        return this.segmentCache[name] === true;
    };
    /**
     * Reset (update) the cached list of segments with the given list, removing and adding segments if necessary.
     * @NOTE based on the way we use segments in the browser, this way is the best option
     *
     * @param {string[]} names list of segment names
     * @returns boolean indicating if the cache was updated (i.e., given list was different from the cached one)
     */
    MySegmentsCacheInMemory.prototype.resetSegments = function (names) {
        var _this = this;
        var isDiff = false;
        var index;
        var storedSegmentKeys = Object.keys(this.segmentCache);
        // Extreme fast => everything is empty
        if (names.length === 0 && storedSegmentKeys.length === names.length)
            return isDiff;
        // Quick path
        if (storedSegmentKeys.length !== names.length) {
            isDiff = true;
            this.segmentCache = {};
            names.forEach(function (s) {
                _this.addToSegment(s);
            });
        }
        else {
            // Slowest path => we need to find at least 1 difference because
            for (index = 0; index < names.length && this.isInSegment(names[index]); index++) {
                // TODO: why empty statement?
            }
            if (index < names.length) {
                isDiff = true;
                this.segmentCache = {};
                names.forEach(function (s) {
                    _this.addToSegment(s);
                });
            }
        }
        return isDiff;
    };
    return MySegmentsCacheInMemory;
}(AbstractSegmentsCacheSync));
export { MySegmentsCacheInMemory };
