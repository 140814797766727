var Backoff = /** @class */ (function () {
    /**
     * Schedule function calls with exponential backoff
     *
     * @param {function} cb
     * @param {number} baseMillis
     * @param {number} maxMillis
     */
    function Backoff(cb, baseMillis, maxMillis) {
        this.baseMillis = baseMillis || Backoff.DEFAULT_BASE_MILLIS;
        this.maxMillis = maxMillis || Backoff.DEFAULT_MAX_MILLIS;
        this.attempts = 0;
        this.cb = cb;
    }
    /**
     * Schedule a next call to `cb`
     * @returns scheduled delay in milliseconds
     */
    Backoff.prototype.scheduleCall = function () {
        var _this = this;
        var delayInMillis = Math.min(this.baseMillis * Math.pow(2, this.attempts), this.maxMillis);
        if (this.timeoutID)
            clearTimeout(this.timeoutID);
        this.timeoutID = setTimeout(function () {
            _this.cb();
        }, delayInMillis);
        this.attempts++;
        return delayInMillis;
    };
    Backoff.prototype.reset = function () {
        this.attempts = 0;
        if (this.timeoutID) {
            clearTimeout(this.timeoutID);
            this.timeoutID = undefined;
        }
    };
    Backoff.DEFAULT_BASE_MILLIS = 1000; // 1 second
    Backoff.DEFAULT_MAX_MILLIS = 1800000; // 30 minutes
    return Backoff;
}());
export { Backoff };
