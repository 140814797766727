import { ERROR_INVALID_CONFIG_PARAM } from '../../logger/constants';
import { DEBUG, OPTIMIZED } from '../constants';
import { stringToUpperCase } from '../lang';
export function validImpressionsMode(log, impressionsMode) {
    impressionsMode = stringToUpperCase(impressionsMode);
    if ([DEBUG, OPTIMIZED].indexOf(impressionsMode) > -1)
        return impressionsMode;
    log.error(ERROR_INVALID_CONFIG_PARAM, ['impressionsMode', [DEBUG, OPTIMIZED], OPTIMIZED]);
    return OPTIMIZED;
}
