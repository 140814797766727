/**
 * Message codes used to trim string log messages from commons and client-side API modules,
 * in order to reduce the minimal SDK size for Browser and eventually other client-side environments.
 *
 * Modules related to the server-side API (e.g., segmentsSyncTask), platform-specific components (e.g., signal listeners)
 * and pluggable components (e.g., pluggable integrations & storages) can use the logger with string literals.
 */
export var ENGINE_COMBINER_AND = 0;
export var ENGINE_COMBINER_IFELSEIF = 1;
export var ENGINE_COMBINER_IFELSEIF_NO_TREATMENT = 2;
export var ENGINE_BUCKET = 3;
export var ENGINE_MATCHER_ALL = 4;
export var ENGINE_MATCHER_BETWEEN = 5;
export var ENGINE_MATCHER_BOOLEAN = 6;
export var ENGINE_MATCHER_CONTAINS_ALL = 7;
export var ENGINE_MATCHER_CONTAINS_ANY = 8;
export var ENGINE_MATCHER_CONTAINS_STRING = 9;
export var ENGINE_MATCHER_DEPENDENCY = 10;
export var ENGINE_MATCHER_DEPENDENCY_PRE = 11;
export var ENGINE_MATCHER_EQUAL = 12;
export var ENGINE_MATCHER_EQUAL_TO_SET = 13;
export var ENGINE_MATCHER_ENDS_WITH = 14;
export var ENGINE_MATCHER_GREATER = 15;
export var ENGINE_MATCHER_LESS = 16;
export var ENGINE_MATCHER_PART_OF = 17;
export var ENGINE_MATCHER_SEGMENT = 18;
export var ENGINE_MATCHER_STRING = 19;
export var ENGINE_MATCHER_STRING_INVALID = 20;
export var ENGINE_MATCHER_STARTS_WITH = 21;
export var ENGINE_MATCHER_WHITELIST = 22;
export var ENGINE_VALUE = 23;
export var ENGINE_SANITIZE = 24;
export var CLEANUP_REGISTERING = 25;
export var CLEANUP_DEREGISTERING = 26;
export var RETRIEVE_CLIENT_DEFAULT = 27;
export var RETRIEVE_CLIENT_EXISTING = 28;
export var RETRIEVE_MANAGER = 29;
export var SYNC_OFFLINE_DATA = 30;
export var SYNC_SPLITS_FETCH = 31;
export var SYNC_SPLITS_NEW = 32;
export var SYNC_SPLITS_REMOVED = 33;
export var SYNC_SPLITS_SEGMENTS = 34;
export var STREAMING_NEW_MESSAGE = 35;
export var SYNC_TASK_START = 36;
export var SYNC_TASK_EXECUTE = 37;
export var SYNC_TASK_STOP = 38;
export var SETTINGS_SPLITS_FILTER = 39;
export var CLIENT_READY_FROM_CACHE = 100;
export var CLIENT_READY = 101;
export var IMPRESSION = 102;
export var IMPRESSION_QUEUEING = 103;
export var NEW_SHARED_CLIENT = 104;
export var NEW_FACTORY = 105;
export var POLLING_SMART_PAUSING = 106;
export var POLLING_START = 107;
export var POLLING_STOP = 108;
export var SYNC_SPLITS_FETCH_RETRY = 109;
export var STREAMING_REFRESH_TOKEN = 110;
export var STREAMING_RECONNECT = 111;
export var STREAMING_CONNECTING = 112;
export var STREAMING_DISABLED = 113;
export var STREAMING_DISCONNECTING = 114;
export var SUBMITTERS_PUSH_FULL_QUEUE = 115;
export var SUBMITTERS_PUSH = 116;
export var SYNC_START_POLLING = 117;
export var SYNC_CONTINUE_POLLING = 118;
export var SYNC_STOP_POLLING = 119;
export var EVENTS_TRACKER_SUCCESS = 120;
export var IMPRESSIONS_TRACKER_SUCCESS = 121;
export var USER_CONSENT_UPDATED = 122;
export var USER_CONSENT_NOT_UPDATED = 123;
export var USER_CONSENT_INITIAL = 124;
export var ENGINE_VALUE_INVALID = 200;
export var ENGINE_VALUE_NO_ATTRIBUTES = 201;
export var CLIENT_NO_LISTENER = 202;
export var CLIENT_NOT_READY = 203;
export var SYNC_MYSEGMENTS_FETCH_RETRY = 204;
export var SYNC_SPLITS_FETCH_FAILS = 205;
export var STREAMING_PARSING_ERROR_FAILS = 206;
export var STREAMING_PARSING_MESSAGE_FAILS = 207;
export var STREAMING_FALLBACK = 208;
export var SUBMITTERS_PUSH_FAILS = 209;
export var SUBMITTERS_PUSH_RETRY = 210;
export var WARN_SETTING_NULL = 211;
export var WARN_TRIMMING_PROPERTIES = 212;
export var WARN_CONVERTING = 213;
export var WARN_TRIMMING = 214;
export var WARN_NOT_EXISTENT_SPLIT = 215;
export var WARN_LOWERCASE_TRAFFIC_TYPE = 216;
export var WARN_NOT_EXISTENT_TT = 217;
export var WARN_INTEGRATION_INVALID = 218;
export var WARN_SPLITS_FILTER_IGNORED = 219;
export var WARN_SPLITS_FILTER_INVALID = 220;
export var WARN_SPLITS_FILTER_EMPTY = 221;
export var WARN_API_KEY = 222;
export var STREAMING_PARSING_MY_SEGMENTS_UPDATE_V2 = 223;
export var ERROR_ENGINE_COMBINER_IFELSEIF = 300;
export var ERROR_LOGLEVEL_INVALID = 301;
export var ERROR_CLIENT_LISTENER = 302;
export var ERROR_CLIENT_CANNOT_GET_READY = 303;
export var ERROR_SYNC_OFFLINE_LOADING = 304;
export var ERROR_STREAMING_SSE = 305;
export var ERROR_STREAMING_AUTH = 306;
export var ERROR_IMPRESSIONS_TRACKER = 307;
export var ERROR_IMPRESSIONS_LISTENER = 308;
export var ERROR_EVENTS_TRACKER = 309;
export var ERROR_EVENT_TYPE_FORMAT = 310;
export var ERROR_NOT_PLAIN_OBJECT = 311;
export var ERROR_SIZE_EXCEEDED = 312;
export var ERROR_NOT_FINITE = 313;
export var ERROR_CLIENT_DESTROYED = 314;
export var ERROR_NULL = 315;
export var ERROR_TOO_LONG = 316;
export var ERROR_INVALID_KEY_OBJECT = 317;
export var ERROR_INVALID = 318;
export var ERROR_EMPTY = 319;
export var ERROR_EMPTY_ARRAY = 320;
export var ERROR_INVALID_CONFIG_PARAM = 321;
export var ERROR_HTTP = 322;
export var ERROR_LOCALHOST_MODULE_REQUIRED = 323;
export var ERROR_STORAGE_INVALID = 324;
export var ERROR_NOT_BOOLEAN = 325;
// Log prefixes (a.k.a. tags or categories)
export var LOG_PREFIX_SETTINGS = 'settings';
export var LOG_PREFIX_INSTANTIATION = 'Factory instantiation';
export var LOG_PREFIX_ENGINE = 'engine';
export var LOG_PREFIX_ENGINE_COMBINER = LOG_PREFIX_ENGINE + ':combiner: ';
export var LOG_PREFIX_ENGINE_MATCHER = LOG_PREFIX_ENGINE + ':matcher: ';
export var LOG_PREFIX_ENGINE_VALUE = LOG_PREFIX_ENGINE + ':value: ';
export var LOG_PREFIX_SYNC = 'sync';
export var LOG_PREFIX_SYNC_MANAGER = LOG_PREFIX_SYNC + ':sync-manager: ';
export var LOG_PREFIX_SYNC_OFFLINE = LOG_PREFIX_SYNC + ':offline: ';
export var LOG_PREFIX_SYNC_STREAMING = LOG_PREFIX_SYNC + ':streaming: ';
export var LOG_PREFIX_SYNC_SPLITS = LOG_PREFIX_SYNC + ':split-changes: ';
export var LOG_PREFIX_SYNC_SEGMENTS = LOG_PREFIX_SYNC + ':segment-changes: ';
export var LOG_PREFIX_SYNC_MYSEGMENTS = LOG_PREFIX_SYNC + ':my-segments: ';
export var LOG_PREFIX_SYNC_POLLING = LOG_PREFIX_SYNC + ':polling-manager: ';
export var LOG_PREFIX_SYNC_SUBMITTERS = LOG_PREFIX_SYNC + ':submitter: ';
export var LOG_PREFIX_IMPRESSIONS_TRACKER = 'impressions-tracker: ';
export var LOG_PREFIX_EVENTS_TRACKER = 'events-tracker: ';
export var LOG_PREFIX_CLEANUP = 'cleanup: ';
