'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = filter;

var _filterLimit = require('./filterLimit');

var _filterLimit2 = _interopRequireDefault(_filterLimit);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function filter(iterable, filterer) {
  return (0, _filterLimit2.default)(iterable, filterer);
}