// @flow

import styled from 'styled-components';
import { Tag } from '@blueprintjs/core';
import { prettyLabel } from '../../utils/prettyLabel';
import { demographicValuesSortFn } from '../../utils/graphHelpers';
import * as HeatmapModel from '../../models/heatmap';
import type { selectedValuesT, heatmapConfigT } from './index';
import { breakpoints } from '../../styles/variables';

const Grid = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  ${({ columns }) => `grid-template-columns: ${'1fr '.repeat(columns)};`}

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    display: block;
  }
`;

export const heatmapsSortFnByKey = (
  heatmaps: heatmapConfigT[],
  filterDimension: string
): heatmapConfigT[] => {
  return heatmaps.sort((a: heatmapConfigT, b: heatmapConfigT) => {
    if (
      !a.demographicDescriptor ||
      !a.demographicDescriptor[filterDimension] ||
      !b.demographicDescriptor ||
      !b.demographicDescriptor[filterDimension]
    ) {
      return 0;
    }

    return demographicValuesSortFn(
      a.demographicDescriptor[filterDimension],
      b.demographicDescriptor[filterDimension]
    );
  });
};

export const getTagLabel = (
  demographicDescriptor: HeatmapModel.DemographicDescriptorT,
  heatmapType: string
) => {
  const entries = Object.entries(demographicDescriptor);
  if (entries.length < 1) return null;

  const demographicType = entries[0][0];
  const demographicValue = entries[0][1];
  const demographicTypeLabel = prettyLabel(demographicType);
  const demographicValueLabel =
    heatmapType === 'interaction'
      ? // $FlowFixMe -> cannot coerce type - same issue with Object.entries and mixed types
        prettyLabel(`interaction-${demographicValue}`)
      : prettyLabel(demographicValue);

  return `${demographicTypeLabel} : ${demographicValueLabel}`;
};

type Props = {
  heatmaps: heatmapConfigT[],
  selectedValues: selectedValuesT,
  selectedDateText: string
};

const HeatmapGrid = ({ heatmaps, selectedValues, selectedDateText }: Props) => {
  const { demographicKeys, heatmapType } = selectedValues;

  const sortedHeatmaps =
    heatmaps && demographicKeys && demographicKeys.filterDimension
      ? heatmapsSortFnByKey(heatmaps, demographicKeys.filterDimension)
      : heatmaps;

  const columnsNumber =
    demographicKeys && demographicKeys.filterDimension ? 2 : 1;

  return (
    <Grid columns={columnsNumber}>
      {sortedHeatmaps.map(({ demographicDescriptor, sourceUrl }) => {
        if (!demographicDescriptor) return null;
        const labelTag = getTagLabel(demographicDescriptor, heatmapType);

        return (
          <div key={sourceUrl}>
            {labelTag && (
              <Tag intent="primary" large>
                {labelTag}
              </Tag>
            )}
            <img
              src={sourceUrl}
              width="100%"
              loading="lazy"
              alt={`Heatmap of type "${prettyLabel(
                heatmapType
              )}" for the date ${selectedDateText}`}
            />
          </div>
        );
      })}
    </Grid>
  );
};

export default HeatmapGrid;
