// @flow

import { connect } from 'react-redux';
import styled from 'styled-components';
import { Panel, PanelHeader, PanelBody, Row, PanelLoading } from '../panel';
import { Menu, MenuItem, Popover, Button, Position } from '@blueprintjs/core';
import SingleRecordingPicker from '../pickers/single-recording';
import { prettyLabel } from '../../utils/prettyLabel';
import NotFoundText from './not-found-text';
import { AGE, GENDER, ROLE } from '../../constants/demographic-types';
import * as RecordingModel from '../../models/recording';
import * as Selectors from '../../selectors';
import { DISABLE_AGE_GENDER } from '../../constants/features';
import type { selectedValuesT } from './index';
import { breakpoints } from '../../styles/variables';

const Wrapper = styled.div`
  box-shadow: var(--widget-box-shadow);
`;
const SelectorLabel = styled.p`
  margin: 0 1rem 0 0;
`;

const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 0.5rem;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    justify-content: center;
  }
`;

const FilterMenu = ({ ageValues, genderValues, roleValues, onClick }) => (
  <Menu>
    <MenuItem key="none" text="All visitors" onClick={() => onClick('')} />
    {!!roleValues.length && (
      <MenuItem text={prettyLabel(ROLE)} onClick={() => onClick(ROLE)} />
    )}
    {!!genderValues.length && (
      <MenuItem text={prettyLabel(GENDER)} onClick={() => onClick(GENDER)} />
    )}
    {!!ageValues.length && (
      <MenuItem text={prettyLabel(AGE)} onClick={() => onClick(AGE)} />
    )}
  </Menu>
);

type Props = {
  noHeatmapsForRecording: boolean,
  fetchingHeatmaps: boolean,
  recordings: RecordingModel.t[],
  activeRecording?: RecordingModel.t,
  availableValues: {
    demographicKeys: Object,
  },
  selectedValues: selectedValuesT,
  setActiveRecordingId(recordingId: string): void,
  setActiveFilter(filterDimension: string): void,
  hideAgeAndGenderFilter: boolean,
};

const RecordingAndFilterPanel = ({
  noHeatmapsForRecording,
  fetchingHeatmaps,
  recordings,
  activeRecording,
  availableValues,
  selectedValues,
  setActiveRecordingId,
  setActiveFilter,
  hideAgeAndGenderFilter,
}: Props) => {
  const { demographicKeys = {} } = selectedValues;

  const filterButtonLabel = demographicKeys.filterDimension
    ? prettyLabel(demographicKeys.filterDimension)
    : 'All people';

  return (
    <Wrapper>
      <Panel>
        <StyledPanelHeader>
          <SingleRecordingPicker
            onSelect={setActiveRecordingId}
            activeRecording={activeRecording}
          />
          {noHeatmapsForRecording ? (
            <NotFoundText>No heatmaps were found for this camera.</NotFoundText>
          ) : (
            <Row>
              <SelectorLabel>Show heatmap for:</SelectorLabel>
              <Popover
                content={
                  <FilterMenu
                    onClick={setActiveFilter}
                    ageValues={
                      !hideAgeAndGenderFilter &&
                      availableValues.demographicKeys.ageValues
                    }
                    genderValues={
                      !hideAgeAndGenderFilter &&
                      availableValues.demographicKeys.genderValues
                    }
                    roleValues={availableValues.demographicKeys.roleValues}
                  />
                }
                position={Position.BOTTOM_LEFT}
              >
                <Button rightIcon="caret-down" text={filterButtonLabel} />
              </Popover>
            </Row>
          )}
        </StyledPanelHeader>
        {fetchingHeatmaps && (
          <PanelBody>
            <PanelLoading />
          </PanelBody>
        )}
      </Panel>
    </Wrapper>
  );
};

function mapStateToProps(state) {
  return {
    hideAgeAndGenderFilter: Selectors.userHasFeature(DISABLE_AGE_GENDER, state),
  };
}

export default connect(mapStateToProps)(RecordingAndFilterPanel);
