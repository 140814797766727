import { Backoff } from '../../../utils/Backoff';
/**
 * SegmentUpdateWorker class
 */
var SegmentsUpdateWorker = /** @class */ (function () {
    /**
     * @param {Object} segmentsCache segments data cache
     * @param {Object} segmentsSyncTask task for syncing segments data
     */
    function SegmentsUpdateWorker(segmentsSyncTask, segmentsCache) {
        this.segmentsCache = segmentsCache;
        this.segmentsSyncTask = segmentsSyncTask;
        this.maxChangeNumbers = {};
        this.handleNewEvent = false;
        this.put = this.put.bind(this);
        this.__handleSegmentUpdateCall = this.__handleSegmentUpdateCall.bind(this);
        this.backoff = new Backoff(this.__handleSegmentUpdateCall);
    }
    // Private method
    // Precondition: this.segmentsSyncTask.isSynchronizingSegments === false
    // Approach similar to MySegmentsUpdateWorker due to differences on Segments notifications and endpoint changeNumbers
    SegmentsUpdateWorker.prototype.__handleSegmentUpdateCall = function () {
        var _this = this;
        var segmentsToFetch = Object.keys(this.maxChangeNumbers).filter(function (segmentName) {
            return _this.maxChangeNumbers[segmentName] > _this.segmentsCache.getChangeNumber(segmentName);
        });
        if (segmentsToFetch.length > 0) {
            this.handleNewEvent = false;
            var currentMaxChangeNumbers_1 = segmentsToFetch.map(function (segmentToFetch) { return _this.maxChangeNumbers[segmentToFetch]; });
            // fetch segments revalidating data if cached
            this.segmentsSyncTask.execute(segmentsToFetch, true).then(function (result) {
                // Unlike `SplitUpdateWorker` where changeNumber is consistent between notification and endpoint, if there is no error,
                // we must clean the `maxChangeNumbers` of those segments that didn't receive a new update notification during the fetch.
                if (result !== false) {
                    segmentsToFetch.forEach(function (fetchedSegment, index) {
                        if (_this.maxChangeNumbers[fetchedSegment] === currentMaxChangeNumbers_1[index])
                            _this.maxChangeNumbers[fetchedSegment] = -1;
                    });
                }
                else {
                    // recursive invocation with backoff if there was some error
                    _this.backoff.scheduleCall();
                }
                // immediate recursive invocation if a new notification was queued during fetch
                if (_this.handleNewEvent) {
                    _this.__handleSegmentUpdateCall();
                }
            });
        }
    };
    /**
     * Invoked by NotificationProcessor on SEGMENT_UPDATE event
     *
     * @param {number} changeNumber change number of the SEGMENT_UPDATE notification
     * @param {string} segmentName segment name of the SEGMENT_UPDATE notification
     */
    SegmentsUpdateWorker.prototype.put = function (_a) {
        var changeNumber = _a.changeNumber, segmentName = _a.segmentName;
        var currentChangeNumber = this.segmentsCache.getChangeNumber(segmentName);
        if (changeNumber <= currentChangeNumber || changeNumber <= this.maxChangeNumbers[segmentName])
            return;
        this.maxChangeNumbers[segmentName] = changeNumber;
        this.handleNewEvent = true;
        this.backoff.reset();
        if (this.segmentsSyncTask.isExecuting())
            return;
        this.__handleSegmentUpdateCall();
    };
    return SegmentsUpdateWorker;
}());
export { SegmentsUpdateWorker };
