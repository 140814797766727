// @flow

import { connect } from 'react-redux';
import * as Selectors from '../selectors';
import ManageSingleLocation from '../components/pages/settings/manage/single-location';

function mapStateToProps(state) {
  return {
    recordings: Selectors.getRecordings(state),
    locations: Selectors.getLocations(state),
  };
}

export default connect(mapStateToProps)(ManageSingleLocation);
