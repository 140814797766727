import { LogLevels, isLogLevelString } from './index';
import { ERROR_LOGLEVEL_INVALID } from './constants';
/**
 * The public Logger utility API exposed via SplitFactory, used to update the log level.
 *
 * @param log the factory logger instance to handle
 */
export function createLoggerAPI(log) {
    function setLogLevel(logLevel) {
        if (isLogLevelString(logLevel)) {
            log.setLogLevel(logLevel);
        }
        else {
            log.error(ERROR_LOGLEVEL_INVALID);
        }
    }
    return {
        /**
         * Enables all the logs.
         */
        enable: function () {
            setLogLevel(LogLevels.DEBUG);
        },
        /**
         * Sets a custom log Level for the SDK.
         * @param {string} logLevel - Custom LogLevel value.
         */
        setLogLevel: setLogLevel,
        /**
         * Disables all the log levels.
         */
        disable: function () {
            // Disabling is equal logLevel none
            setLogLevel(LogLevels.NONE);
        },
        /**
         * Exposed for usage with setLogLevel
         */
        LogLevel: LogLevels
    };
}
