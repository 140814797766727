import { truncateTimeFrame } from '../../utils/time';
var ImpressionCountsCacheInMemory = /** @class */ (function () {
    function ImpressionCountsCacheInMemory() {
        this.cache = {};
    }
    /**
    * Builds key to be stored in the cache with the featureName and the timeFrame truncated.
    */
    ImpressionCountsCacheInMemory.prototype._makeKey = function (featureName, timeFrame) {
        return featureName + "::" + truncateTimeFrame(timeFrame);
    };
    /**
    * Increments the quantity of impressions with the passed featureName and timeFrame.
    */
    ImpressionCountsCacheInMemory.prototype.track = function (featureName, timeFrame, amount) {
        var key = this._makeKey(featureName, timeFrame);
        var currentAmount = this.cache[key];
        this.cache[key] = currentAmount ? currentAmount + amount : amount;
    };
    /**
    * Returns all the elements stored in the cache and resets the cache.
    */
    ImpressionCountsCacheInMemory.prototype.state = function () {
        return this.cache;
    };
    ImpressionCountsCacheInMemory.prototype.clear = function () {
        this.cache = {};
    };
    ImpressionCountsCacheInMemory.prototype.isEmpty = function () {
        return Object.keys(this.cache).length === 0;
    };
    return ImpressionCountsCacheInMemory;
}());
export { ImpressionCountsCacheInMemory };
