// @flow

import { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import { SETTINGS_MANAGE_LOCATIONS } from '../../constants/routes';
import * as LocationModel from '../../models/location';
import { IS_EMAIL_VIEW } from '../../config/vars';

const Wrapper = styled.div`
  ${IS_EMAIL_VIEW
    ? `
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 1rem;
    `
    : ''}
`;

const Bar = styled.div`
  border-radius: var(--widget-border-radius);
  padding: 0.5rem;
  text-align: center;
  background: #d9822b;
  color: white;
  z-index: 1000;
  display: flex;
  gap: 0.5rem;

  a {
    color: white;
    text-decoration: underline;
  }

  p {
    margin: 0.5rem 0;
  }

  ${IS_EMAIL_VIEW
    ? `
    position: relative;
    margin-top: 1rem;
    bottom: auto;
    right: auto;
    width: var(--widget-max-width);
    justify-content: center;
  `
    : `
    bottom: 1rem;
    right: 1rem;
    position: fixed;
    width: 300px;
    justify-content: space-between;
  `}
`;

const DismissIcon = styled(Icon)`
  cursor: pointer;
  margin: 0.5rem 0.5rem 0 0;
`;

type Props = {
  locations: LocationModel.t[],
};

const ContractExpiryWarning = ({ locations = [] }: Props) => {
  const [dismissed, setDismissed] = useState(false);

  const showWarning = LocationModel.anyLocationNearOrAtContractEnd(locations);

  if (showWarning && !dismissed)
    return (
      <Wrapper>
        <Bar>
          {!IS_EMAIL_VIEW && (
            <div style={{ width: '16px', height: '16px' }}></div>
          )}
          <div>
            <p>One or more of your locations is near the end of its license.</p>
            {!IS_EMAIL_VIEW && (
              <p>
                <Link
                  to={SETTINGS_MANAGE_LOCATIONS}
                  onClick={(e) => setDismissed(true)}
                >
                  Check your current locations
                </Link>
              </p>
            )}
          </div>
          {!IS_EMAIL_VIEW && (
            <DismissIcon icon="delete" onClick={(e) => setDismissed(true)} />
          )}
        </Bar>
      </Wrapper>
    );

  return null;
};

export default ContractExpiryWarning;
