// Splits events:
export var SDK_SPLITS_ARRIVED = 'state::splits-arrived';
export var SDK_SPLITS_CACHE_LOADED = 'state::splits-cache-loaded';
// Segments events:
export var SDK_SEGMENTS_ARRIVED = 'state::segments-arrived';
// Readiness events:
export var SDK_READY_TIMED_OUT = 'init::timeout';
export var SDK_READY = 'init::ready';
export var SDK_READY_FROM_CACHE = 'init::cache-ready';
export var SDK_UPDATE = 'state::update';
