/**
 * Composite Sync Task: group of sync tasks that are treated as a single one.
 */
export function syncTaskComposite(syncTasks) {
    return {
        start: function () {
            syncTasks.forEach(function (syncTask) { return syncTask.start(); });
        },
        stop: function () {
            syncTasks.forEach(function (syncTask) { return syncTask.stop(); });
        },
        isRunning: function () {
            return syncTasks.some(function (syncTask) { return syncTask.isRunning(); });
        },
        execute: function () {
            return Promise.all(syncTasks.map(function (syncTask) { return syncTask.execute(); }));
        },
        isExecuting: function () {
            return syncTasks.some(function (syncTask) { return syncTask.isExecuting(); });
        }
    };
}
