import { thenable } from '../../utils/promise/thenable';
import { ENGINE_MATCHER_DEPENDENCY, ENGINE_MATCHER_DEPENDENCY_PRE } from '../../logger/constants';
export function dependencyMatcherContext(log, _a, storage) {
    var split = _a.split, treatments = _a.treatments;
    function checkTreatment(evaluation, acceptableTreatments, parentName) {
        var matches = false;
        if (Array.isArray(acceptableTreatments)) {
            matches = acceptableTreatments.indexOf(evaluation.treatment) !== -1;
        }
        log.debug(ENGINE_MATCHER_DEPENDENCY, [parentName, evaluation.treatment, evaluation.label, parentName, acceptableTreatments, matches]);
        return matches;
    }
    return function dependencyMatcher(_a, splitEvaluator) {
        var key = _a.key, attributes = _a.attributes;
        log.debug(ENGINE_MATCHER_DEPENDENCY_PRE, [split, JSON.stringify(key), attributes ? '\n attributes: ' + JSON.stringify(attributes) : '']);
        var evaluation = splitEvaluator(log, key, split, attributes, storage);
        if (thenable(evaluation)) {
            return evaluation.then(function (ev) { return checkTreatment(ev, treatments, split); });
        }
        else {
            return checkTreatment(evaluation, treatments, split);
        }
    };
}
