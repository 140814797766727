'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = some;

var _concurrent = require('./__internal__/concurrent');

var _concurrent2 = _interopRequireDefault(_concurrent);

var _errors = require('./__internal__/errors');

var _utils = require('./__internal__/utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function some(iterable, total) {
  var resolved = {};

  return Promise.resolve(iterable).then((0, _concurrent2.default)({
    breakOnError: false,
    onItemResolved: function onItemResolved(value, key) {
      resolved[key] = value;
    },

    onItemCompleted: function onItemCompleted(done, throws) {
      return function (count, values, errors) {
        var tooManyFails = values.length - errors.length <= total;

        if ((0, _utils.size)(resolved) === total) {
          done((0, _utils.toArray)(resolved));
        } else if (tooManyFails) {
          throws(new _errors.SubError(errors));
        }
      };
    }
  }));
}