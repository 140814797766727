import { ERROR_EMPTY_ARRAY } from '../../logger/constants';
import { uniq } from '../lang';
import { validateSplit } from './split';
export function validateSplits(log, maybeSplits, method, listName, item) {
    if (listName === void 0) { listName = 'split_names'; }
    if (item === void 0) { item = 'split name'; }
    if (Array.isArray(maybeSplits) && maybeSplits.length > 0) {
        var validatedArray_1 = [];
        // Remove invalid values
        maybeSplits.forEach(function (maybeSplit) {
            var splitName = validateSplit(log, maybeSplit, method, item);
            if (splitName)
                validatedArray_1.push(splitName);
        });
        // Strip off duplicated values if we have valid split names then return
        if (validatedArray_1.length)
            return uniq(validatedArray_1);
    }
    log.error(ERROR_EMPTY_ARRAY, [method, listName]);
    return false;
}
