import { useState, useEffect } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Select } from '@blueprintjs/select';
import {
  Button,
  MenuItem,
  Alignment,
  Switch,
  Spinner,
} from '@blueprintjs/core';
import { Panel, PanelHeader, PanelBody } from '../panel';
import { breakpoints } from '../../styles/variables';
import DateSelector from './date-selector';
import { useLocation } from 'react-router-dom';
import Popup from './popup';
import { sendMessage } from '../../services/sql-api';
import { fetchThumbnailsForRecordings } from '../../actions/thumbnails';

const Wrapper = styled.div`
  flex: 1;
`;

const RecordingGrid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 285px;
  width: 100%;
`;

const RecordingPreview = styled.div`
  width: calc(20% - 2rem);
  margin: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${breakpoints.MED_PC_SIZE}) {
    width: calc(100% / 3 - 2rem);
  }

  @media (max-width: ${breakpoints.SMALL_PC_SIZE}) {
    width: calc(50% - 2rem);
  }

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: calc(100%);
  }
`;

const Image = styled.img`
  cursor: pointer;
  width: 100%;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    cursor: unset;
  }
`;

const DateLabel = styled.span`
  margin-inline-start: 16px;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    display: none;
  }
`;

const StyledPanel = styled(Panel)`
  margin: 1rem 0;
  box-shadow: var(--widget-box-shadow);

  &:first-child {
    margin: 0;
  }
`;

const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    justify-content: center;
  }
`;

const StyledSwitch = styled(Switch)`
  margin: 0;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    display: none;
  }
`;

const SelectionGroup = styled.div`
  flex-shrink: 0;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    display: flex;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`;

const sortByName = (a, b) => {
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  return 0;
};

const CameraPreviews = ({ recordings, locations, compare, setCompare }) => {
  const browserLocation = useLocation();

  const [selectedLocation, setSelectedLocation] = useState(locations[0]);
  const [selectedDate, setSelectedDate] = useState('');
  const [activeRecordings, setActiveRecordings] = useState([]);
  const [dates, setDates] = useState([]);
  const [popupSelectedDate, setPopupSelectedDate] = useState('');
  const [selectedRecording, setSelectedRecording] = useState(null);

  useEffect(() => setPopupSelectedDate(selectedDate), [selectedDate]);

  useEffect(() => {
    if (browserLocation && browserLocation.search) {
      const location = new URLSearchParams(browserLocation.search).get(
        'location',
      );
      if (location && location !== '') {
        setSelectedLocation(locations.find((loc) => loc.id === location));
      }
    }
  }, [browserLocation, locations]);

  // Set the active recordings based on location
  useEffect(() => {
    const sortedRecordings = recordings.sort(sortByName);
    setActiveRecordings(
      sortedRecordings.filter(
        (recording) =>
          recording.location && recording.location.id === selectedLocation.id,
      ),
    );
  }, [selectedLocation, recordings]);

  // Fetch thumbnails for the active recordings
  const fetchThumbnails = async (recordings) => {
    const results = await fetchThumbnailsForRecordings(
      recordings.map((rec) => rec.id),
    );

    if (results && results.size > 0) {
      setActiveRecordings(
        activeRecordings.map((rec) => ({
          ...rec,
          thumbnails: results.get(rec.id).reverse(),
        })),
      );
    }
  };

  // Assume all recordings for a location have the same dates
  useEffect(() => {
    if (activeRecordings && activeRecordings[0]) {
      if (!Array.isArray(activeRecordings[0].thumbnails)) {
        fetchThumbnails(activeRecordings);
      } else {
        const timezone = activeRecordings[0].timezone;
        const recordingDates = activeRecordings[0].thumbnails
          .map((t) => moment(t.local_time).format('L'));
        setDates(recordingDates);
        setSelectedDate(recordingDates[recordingDates.length - 1]);
      }
    }
  }, [activeRecordings]);

  const recordingsByLocation = {};

  recordings.forEach((rec) => {
    const locId = rec.location.id;
    if (!recordingsByLocation[locId]) {
      recordingsByLocation[locId] = [rec];
    } else {
      recordingsByLocation[locId].push(rec);
    }
  });

  const filterPredicate = (query, item) => {
    if (item && item.name) {
      return item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    }
  };

  return (
    <>
      <Wrapper>
        <StyledPanel>
          <StyledPanelHeader>
            <SelectionGroup>
              <Select
                items={locations}
                itemPredicate={filterPredicate}
                itemRenderer={(location, { handleClick }) => (
                  <MenuItem
                    key={location.name}
                    text={location.name}
                    active={
                      selectedLocation
                        ? location.name === selectedLocation.name
                        : false
                    }
                    onClick={handleClick}
                  />
                )}
                onItemSelect={(location) => setSelectedLocation(location)}
              >
                <Button
                  text={
                    selectedLocation
                      ? selectedLocation.name
                      : 'No locations available'
                  }
                  intent="primary"
                  rightIcon="caret-down"
                />
              </Select>
              <DateLabel>{selectedDate}</DateLabel>
            </SelectionGroup>
            {typeof compare !== 'undefined' && setCompare && (
              <StyledSwitch
                checked={compare}
                onChange={() => setCompare(!compare)}
                alignIndicator={Alignment.RIGHT}
                label="Compare"
              />
            )}
          </StyledPanelHeader>
        </StyledPanel>

        {dates.length > 1 && (
          <StyledPanel>
            <PanelHeader>
              <DateSelector
                dates={dates}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            </PanelHeader>
          </StyledPanel>
        )}
        <StyledPanel>
          <PanelBody>
            <RecordingGrid>
              {selectedDate &&
              activeRecordings &&
              activeRecordings.length > 0 &&
              Array.isArray(activeRecordings[0].thumbnails) ? (
                activeRecordings.map((recording, i) => {
                  const thumb = recording.thumbnails.find(
                    (thumb) =>
                      moment(thumb.local_time).format('L') ===
                      selectedDate,
                  );
                  const src = thumb ? thumb.url : '';
                  return (
                    src && (
                      <RecordingPreview key={i}>
                        <p>{recording.name}</p>
                        <Image
                          src={src}
                          loading="lazy"
                          onClick={() => {
                            if (
                              window.outerWidth >
                              parseInt(breakpoints.TABLET_SIZE)
                            ) {
                              setPopupSelectedDate(selectedDate);
                              setSelectedRecording(recording);
                            }
                          }}
                        />
                      </RecordingPreview>
                    )
                  );
                })
              ) : (
                <SpinnerContainer>
                  <Spinner size={25} />
                </SpinnerContainer>
              )}
            </RecordingGrid>
          </PanelBody>
        </StyledPanel>
      </Wrapper>

      <Popup
        dates={dates}
        popupSelectedDate={popupSelectedDate}
        setPopupSelectedDate={setPopupSelectedDate}
        selectedRecording={selectedRecording}
        setSelectedRecording={setSelectedRecording}
      />
    </>
  );
};

export default CameraPreviews;
