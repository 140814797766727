import { submitterSyncTaskFactory } from './submitterSyncTask';
import { SUBMITTERS_PUSH_FULL_QUEUE } from '../../logger/constants';
var DATA_NAME = 'events';
/**
 * Sync task that periodically posts tracked events
 */
export function eventsSyncTaskFactory(log, postEventsBulk, eventsCache, eventsPushRate, eventsFirstPushWindow, latencyTracker) {
    // don't retry events.
    var syncTask = submitterSyncTaskFactory(log, postEventsBulk, eventsCache, eventsPushRate, DATA_NAME, latencyTracker);
    // Set a timer for the first push window of events.
    // Not implemented in the base submitter or sync task, since this feature is only used by the events submitter.
    if (eventsFirstPushWindow > 0) {
        var running_1 = false;
        var stopEventPublisherTimeout_1;
        var originalStart_1 = syncTask.start;
        syncTask.start = function () {
            running_1 = true;
            stopEventPublisherTimeout_1 = setTimeout(originalStart_1, eventsFirstPushWindow);
        };
        var originalStop_1 = syncTask.stop;
        syncTask.stop = function () {
            running_1 = false;
            clearTimeout(stopEventPublisherTimeout_1);
            originalStop_1();
        };
        syncTask.isRunning = function () {
            return running_1;
        };
    }
    // register events submitter to be executed when events cache is full
    eventsCache.setOnFullQueueCb(function () {
        if (syncTask.isRunning()) {
            log.info(SUBMITTERS_PUSH_FULL_QUEUE, [DATA_NAME]);
            syncTask.execute();
        }
        // If submitter is stopped (e.g., user consent declined or unknown, or app state offline), we don't send the data.
        // Data will be sent when submitter is resumed.
    });
    return syncTask;
}
