import styled from 'styled-components';
import { Card, Classes, Spinner } from '@blueprintjs/core';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  top: 1rem;
  left: 0;
  z-index: 10000;
`;
const LoadingHeading = styled(Card)`
  display: flex;
  align-items: center;
  padding: 1.5rem 2.5rem;
`;
const LoadingText = styled.p`
  margin: 0 0.5em 0 0;
`;

const TopBarLoading = () => (
  <Container>
    <LoadingHeading elevation={3}>
      <LoadingText>Loading</LoadingText>
      <Spinner className={Classes.SMALL} />
    </LoadingHeading>
  </Container>
);

export default TopBarLoading;
