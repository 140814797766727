// @flow

import { Component } from 'react';
import styled from 'styled-components';
import { isEqual, flatten, uniq } from 'lodash';
import { Button, ButtonGroup } from '@blueprintjs/core';
import TableBody from './table-body';
import MetricsMenu from '../metrics-menu';
import DownloadOptionsBar from '../download-options/bar';
import DownloadOptionsSummaryBtn from '../download-options/summary-btn';
import MetricsDropdownMenu from '../pickers/metrics-dropdown-menu';
import { breakpoints } from '../../styles/variables';
import {
  getMetricFromGridItem,
  redirectWithStateToExplorerPage,
  validateRedirectToExplorerPage
} from '../../utils/urlHelpers';

import type { breakdownByEnum } from '../../constants/graph-options';
import type { editSummaryPageActiveMetricsArgsT } from '../../actions/page-settings';
import * as RecodingModel from '../../models/recording';
import * as ActiveMetricsModel from '../../models/active-metrics';
import * as QueryResponseListModel from '../../models/query-response-list';
import * as LocationModel from '../../models/location';
import * as AvailableMetricsModel from '../../models/available-metrics';
import * as PageSettingsModel from '../../models/page-settings';
import { errorToast } from '../../utils/toaster';
import { PanelBody, PanelHeader } from '../panel';

export const InverMetricsButton = styled(Button)`
  margin-right: auto;
`;
export const StyledButtonGroup = styled(ButtonGroup)`
  margin: 1em;
  margin-left: auto;
`;
const StyledPanelHeader = styled(PanelHeader)`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media (max-width: ${breakpoints.PHONE_SIZE}) {
    justify-content: center;
  }
`;
const Wrapper = styled.div`
  box-shadow: var(--widget-box-shadow);
`;

type Props = {
  excludeStaff: boolean,
  handleOnCloseMetricsMenu: () => void,
  editActiveMetrics: (config: editSummaryPageActiveMetricsArgsT) => void,
  clearActiveMetrics: () => void,
  activeMetrics: ActiveMetricsModel.t,
  queryResponseList: QueryResponseListModel.t,
  filteredLocations: string[],
  locations: LocationModel.t[],
  breakdownBy: breakdownByEnum,
  availableMetrics: AvailableMetricsModel.t,
  settings: PageSettingsModel.summaryT,
  recordings: RecodingModel.t[],
  fetchingQueryData: boolean,
  isComparisonActive: boolean,
  showAllLocationsTotal: boolean,
  children: any,
};

export type sortDirectionT = 'asc' | 'desc' | '';
export type sortByT = {
  columnDetails:
    | ActiveMetricsModel.activeMetricsHeadingsT
    | ActiveMetricsModel.itemT,
  sortDirection: sortDirectionT,
  isSortByComparison: boolean
};

type State = {
  sortBy: sortByT,
  useInvertedMetrics: boolean,
  locationsSelectedForComparison: Array<string>,
  metricsSelectedForComparison: Array<ActiveMetricsModel.itemT>
};

class MetricsTable extends Component<Props, State> {
  state = {
    useInvertedMetrics: false,
    sortBy: {
      columnDetails: {
        isLocationHeading: true,
        metricGroupKey: 'locations',
        metricKey: 'locations'
      },
      sortDirection: 'asc',
      isSortByComparison: false
    },
    locationsSelectedForComparison: [],
    metricsSelectedForComparison: []
  };

  handleUseInvertedMetrics = (): void => {
    this.setState({
      useInvertedMetrics: !this.state.useInvertedMetrics
    });
  };

  handleSortBy(sortBy: sortByT): void {
    this.setState({
      sortBy
    });
  }

  toggleMetric = (metric: ActiveMetricsModel.itemT): void => {
    const isMetricActive = !!this.state.metricsSelectedForComparison.find(sM =>
      isEqual(sM, metric)
    );
    let newMetrics = [];

    if (isMetricActive) {
      newMetrics = this.state.metricsSelectedForComparison.filter(sM => {
        return !isEqual(sM, metric);
      });
    } else {
      newMetrics = [...this.state.metricsSelectedForComparison, metric];
    }

    this.setState({
      metricsSelectedForComparison: newMetrics
    });
  };

  toggleLocation = (location: string | string[]): void => {
    let newLocations = [];

    if (Array.isArray(location)) {
      const isLocationActive = isEqual(
        this.state.locationsSelectedForComparison,
        location
      );

      if (isLocationActive) {
        newLocations = this.state.locationsSelectedForComparison.filter(sM => {
          return !location.includes(sM);
        });
      } else {
        newLocations = location;
      }
    } else {
      const isLocationActive = !!this.state.locationsSelectedForComparison.find(
        l => isEqual(l, location)
      );

      if (isLocationActive) {
        newLocations = this.state.locationsSelectedForComparison.filter(sM => {
          return !isEqual(sM, location);
        });
      } else {
        newLocations = [...this.state.locationsSelectedForComparison, location];
      }
    }

    this.setState({
      locationsSelectedForComparison: newLocations
    });
  };

  redirectSelectedMetricToExplorerPage = (viewCombined?: boolean = false) => {
    const { excludeStaff, settings } = this.props;
    const {
      locationsSelectedForComparison,
      metricsSelectedForComparison
    } = this.state;
    const { period, compareToPast } = settings;

    const errors = validateRedirectToExplorerPage({
      locationsSelectedForComparison,
      metricsSelectedForComparison
    });

    if (errors.length > 0) {
      errors.forEach(error => {
        errorToast({ message: error });
      });

      return undefined;
    }

    if (!viewCombined) {
      const newMetrics = locationsSelectedForComparison.reduce(
        (finalMetrics, location) => {
          metricsSelectedForComparison.forEach(selectedMetric => {
            const metric = getMetricFromGridItem({
              ...selectedMetric,
              excludeStaff,
              locationId: location
            });
            finalMetrics.push(metric);
          });

          return finalMetrics;
        },
        []
      );

      redirectWithStateToExplorerPage(newMetrics, period, compareToPast);
    } else if (viewCombined) {
      const locations = uniq(flatten(locationsSelectedForComparison));
      const newMetrics = metricsSelectedForComparison.map(selectedMetric => {
        const metric = getMetricFromGridItem({
          ...selectedMetric,
          excludeStaff,
          locationId: locations
        });

        return metric;
      });

      redirectWithStateToExplorerPage(newMetrics, period, compareToPast);
    }
  };

  render() {
    const {
      handleOnCloseMetricsMenu,
      editActiveMetrics,
      activeMetrics,
      queryResponseList,
      filteredLocations,
      locations,
      breakdownBy,
      availableMetrics,
      settings,
      recordings,
      fetchingQueryData,
      excludeStaff,
      isComparisonActive,
      showAllLocationsTotal,
      children,
      clearActiveMetrics,
    } = this.props;
    const { period, compareToPast } = settings;
    const {
      sortBy,
      useInvertedMetrics,
      locationsSelectedForComparison,
      metricsSelectedForComparison
    } = this.state;

    return (
      <Wrapper>
        <StyledPanelHeader>
          <MetricsMenu
            onMenuClose={handleOnCloseMetricsMenu}
            availableMetrics={availableMetrics}
            editActiveMetrics={editActiveMetrics}
            activeMetrics={activeMetrics}
            fetchingQueryData={fetchingQueryData}
            strictModeSelection
          />
          {!useInvertedMetrics && (
            <MetricsDropdownMenu
              disabled={locationsSelectedForComparison.length <= 0}
              availableMetrics={availableMetrics}
              activeMetrics={activeMetrics}
              metricsSelectedForComparison={metricsSelectedForComparison}
              locationsSelectedForComparison={
                locationsSelectedForComparison
              }
              redirectSelectedMetricToExplorerPage={this.redirectSelectedMetricToExplorerPage.bind(
                this
              )}
              toggleMetric={this.toggleMetric.bind(this)}
            />
          )}
          <Button
            onClick={clearActiveMetrics}
            intent="danger"
            icon="trash">
            Clear Metrics
          </Button>
        </StyledPanelHeader>
        <PanelBody>
          <TableBody
            period={period}
            excludeStaff={excludeStaff}
            compareToPeriod={compareToPast}
            useInvertedMetrics={useInvertedMetrics}
            isComparisonActive={isComparisonActive}
            recordings={recordings}
            handleSortBy={this.handleSortBy.bind(this)}
            availableMetrics={availableMetrics}
            activeMetrics={activeMetrics}
            queryResponseList={queryResponseList}
            filteredLocations={filteredLocations}
            locations={locations}
            breakdownBy={breakdownBy}
            sortBy={sortBy}
            toggleLocation={this.toggleLocation.bind(this)}
            locationsSelectedForComparison={locationsSelectedForComparison}
            showAllLocationsTotal={showAllLocationsTotal}
          />
          {children}
        </PanelBody>
        <DownloadOptionsBar>
          <ButtonGroup>
            <InverMetricsButton
              icon="swap-vertical"
              onClick={this.handleUseInvertedMetrics}
            >
              Flip rows/columns
            </InverMetricsButton>
          </ButtonGroup>
          <DownloadOptionsSummaryBtn
            excludeStaff={excludeStaff}
            period={period.selectedDates}
            disabled={fetchingQueryData}
            filename={`${period.selectedDates.start}-${period.selectedDates.end}`}
            availableMetrics={availableMetrics}
            activeMetrics={activeMetrics}
            queryResponseList={queryResponseList}
            filteredLocationIds={filteredLocations}
            locations={locations}
            breakdownBy={breakdownBy}
          />
        </DownloadOptionsBar>
      </Wrapper>
    );
  }
}

export default MetricsTable;
