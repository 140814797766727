import { evaluateFeature, evaluateFeatures } from '../evaluator';
import { thenable } from '../utils/promise/thenable';
import { getMatching, getBucketing } from '../utils/key';
import { validateSplitExistance } from '../utils/inputValidation/splitExistance';
import { validateTrafficTypeExistance } from '../utils/inputValidation/trafficTypeExistance';
import { SDK_NOT_READY } from '../utils/labels';
import { CONTROL } from '../utils/constants';
import { IMPRESSION, IMPRESSION_QUEUEING } from '../logger/constants';
/**
 * Creator of base client with getTreatments and track methods.
 */
// @TODO missing time tracking to collect telemetry
export function clientFactory(params) {
    var readinessManager = params.sdkReadinessManager.readinessManager, storage = params.storage, settings = params.settings, impressionsTracker = params.impressionsTracker, eventTracker = params.eventTracker;
    var log = settings.log, mode = settings.mode;
    function getTreatment(key, splitName, attributes, withConfig) {
        if (withConfig === void 0) { withConfig = false; }
        var wrapUp = function (evaluationResult) {
            var queue = [];
            var treatment = processEvaluation(evaluationResult, splitName, key, attributes, withConfig, "getTreatment" + (withConfig ? 'withConfig' : ''), queue);
            impressionsTracker.track(queue, attributes);
            return treatment;
        };
        var evaluation = evaluateFeature(log, key, splitName, attributes, storage);
        return thenable(evaluation) ? evaluation.then(function (res) { return wrapUp(res); }) : wrapUp(evaluation);
    }
    function getTreatmentWithConfig(key, splitName, attributes) {
        return getTreatment(key, splitName, attributes, true);
    }
    function getTreatments(key, splitNames, attributes, withConfig) {
        if (withConfig === void 0) { withConfig = false; }
        var wrapUp = function (evaluationResults) {
            var queue = [];
            var treatments = {};
            Object.keys(evaluationResults).forEach(function (splitName) {
                treatments[splitName] = processEvaluation(evaluationResults[splitName], splitName, key, attributes, withConfig, "getTreatments" + (withConfig ? 'withConfig' : ''), queue);
            });
            impressionsTracker.track(queue, attributes);
            return treatments;
        };
        var evaluations = evaluateFeatures(log, key, splitNames, attributes, storage);
        return thenable(evaluations) ? evaluations.then(function (res) { return wrapUp(res); }) : wrapUp(evaluations);
    }
    function getTreatmentsWithConfig(key, splitNames, attributes) {
        return getTreatments(key, splitNames, attributes, true);
    }
    // Internal function
    function processEvaluation(evaluation, splitName, key, attributes, withConfig, invokingMethodName, queue) {
        var isSdkReady = readinessManager.isReady() || readinessManager.isReadyFromCache();
        var matchingKey = getMatching(key);
        var bucketingKey = getBucketing(key);
        // If the SDK was not ready, treatment may be incorrect due to having Splits but not segments data.
        if (!isSdkReady) {
            evaluation = { treatment: CONTROL, label: SDK_NOT_READY };
        }
        var treatment = evaluation.treatment, label = evaluation.label, changeNumber = evaluation.changeNumber, _a = evaluation.config, config = _a === void 0 ? null : _a;
        log.info(IMPRESSION, [splitName, matchingKey, treatment, label]);
        if (validateSplitExistance(log, readinessManager, splitName, label, invokingMethodName)) {
            log.info(IMPRESSION_QUEUEING);
            queue.push({
                feature: splitName,
                keyName: matchingKey,
                treatment: treatment,
                time: Date.now(),
                bucketingKey: bucketingKey,
                label: label,
                changeNumber: changeNumber
            });
        }
        if (withConfig) {
            return {
                treatment: treatment,
                config: config
            };
        }
        return treatment;
    }
    function track(key, trafficTypeName, eventTypeId, value, properties, size) {
        if (size === void 0) { size = 1024; }
        var matchingKey = getMatching(key);
        var timestamp = Date.now();
        var eventData = {
            eventTypeId: eventTypeId,
            trafficTypeName: trafficTypeName,
            value: value,
            timestamp: timestamp,
            key: matchingKey,
            properties: properties
        };
        // This may be async but we only warn, we don't actually care if it is valid or not in terms of queueing the event.
        validateTrafficTypeExistance(log, readinessManager, storage.splits, mode, trafficTypeName, 'track');
        return eventTracker.track(eventData, size);
    }
    return {
        getTreatment: getTreatment,
        getTreatmentWithConfig: getTreatmentWithConfig,
        getTreatments: getTreatments,
        getTreatmentsWithConfig: getTreatmentsWithConfig,
        track: track,
        isClientSide: false
    };
}
