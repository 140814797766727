// @flow

import { API_BASE, DISABLE_CACHE, IS_EMAIL_VIEW } from '../config/vars';
import { errorToast } from '../utils/toaster';
import history from '../root/history';
import { LOGOUT } from '../constants/routes';
import { getAuth0Client } from '../services/auth0';
import { db } from '../models/database';
import { neverCache } from '../utils/cacheExclusions';

type callArgs = {
  path: string,
  fetchOptions: Object,
  otherOptions?: Object,
};

type Response = {
  status: number,
  statusText: string,
};

const call = async ({
  path,
  fetchOptions,
  otherOptions = {},
}: callArgs): Promise<any> => {
  let bypassCache = otherOptions.bypassCache;
  let shouldCache = true;
  const fetchOpts = {
    ...fetchOptions,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
    },
  };

  if (fetchOpts.body) {
    fetchOpts.body = JSON.stringify(fetchOpts.body);
  }

  let dbRecord = null;
  if (!IS_EMAIL_VIEW && db && db.isOpen()) {
    try {
      dbRecord = await db.coreAPICache.get({
        path: API_BASE + path,
        method: fetchOpts.method || '',
        params: fetchOpts.body || '',
      });
    } catch (err) {
      console.log(`Failed to get record from local db: ${err}`);
    }
  }

  // if there's no cached record, or the existing cached record is stale, fetch a new version
  if (!dbRecord || (dbRecord && dbRecord.timestamp < new Date() - 10800000)) {
    bypassCache = true;
  }

  if (!bypassCache && dbRecord) {
    return dbRecord.response;
  }

  // do not cache some routes
  const pathWithoutQuery = path.split('?')[0];

  if (
    IS_EMAIL_VIEW ||
    fetchOptions.method === 'DELETE' ||
    neverCache(pathWithoutQuery)
  ) {
    shouldCache = false;
  }

  // do not cache if it is disabled for the environment
  if (DISABLE_CACHE === true) {
    shouldCache = false;
  }

  const auth0Client = getAuth0Client();
  if (auth0Client) {
    const token = await auth0Client.getTokenSilently();

    if (token && !otherOptions.dontAddAuthorization) {
      fetchOpts.headers.Authorization = `Bearer ${token}`;
    }
  }

  const res = await fetch((API_BASE + path: string), (fetchOpts: Object));

  if (!res.ok) {
    // if response is 401 show a toast prompting the user to log back in
    if (res.status === 401 && !otherOptions.disable401Toast) {
      errorToast({
        message: 'Could not authenticate. You probably need to log back in',
        action: {
          text: 'Log in',
          onClick: (e) => history.push(LOGOUT),
        },
        timeout: 0,
      });
      const responseBody = await res.json();
      console.error(responseBody);
    }

    const error: { response?: Response } = new Error(res.statusText);
    error.response = {
      status: res.status,
      statusText: res.statusText,
    };
    throw error;
  }

  if (fetchOptions.method === 'DELETE') return true;
  const jsonRes = await res.json();

  if (shouldCache && db && db.isOpen()) {
    try {
      await db.coreAPICache.put({
        path: API_BASE + path,
        method: fetchOpts.method || '',
        timestamp: new Date() - 1,
        params: fetchOpts.body || '',
        response: jsonRes,
      });
    } catch (err) {
      console.log(`Failed to put record in local db: ${err}`);
    }
  }
  return jsonRes;
};

export const users = {
  profile: () =>
    call({
      path: '/v1/users/profile?include=availableMetrics',
      fetchOptions: { method: 'GET' },
    }),
};

export const locations = {
  fetch: () =>
    call({
      path: '/v1/location/list',
      fetchOptions: { method: 'POST' },
    }),
  fetchOne: (locationId: string) =>
    call({
      path: `/v1/location/${locationId}`,
      fetchOptions: { method: 'GET' },
    }),
  update: (id: string, payload: Object) =>
    call({
      path: `/v1/location/${id}`,
      fetchOptions: { method: 'PATCH', body: payload },
      otherOptions: { bypassCache: true },
    }),
};

export const recordings = {
  fetch: (
    include: string = 'locationDetails,statElems,thumbnail,areaContexts,uploadingSchedule'
  ) =>
    call({
      path: '/v1/recording/list',
      fetchOptions: { method: 'POST', body: { include } },
    }),
  fetchHeatmaps: (id: string) =>
    call({
      path: `/v1/recording/${id}/heatmaps`,
      fetchOptions: { method: 'GET' },
    }),
  uptimes: (recids: Array<string>, start: string, end: string) =>
    call({
      path: '/v1/recording/uptimes',
      fetchOptions: { method: 'POST', body: { recids, start, end } },
    }),
};

export const dashboardReports = {
  fetch: () =>
    call({
      path: '/v1/dashboard-report/list',
      fetchOptions: { method: 'GET' },
    }),
};

export const scheduledReportJobs = {
  list: () =>
    call({
      path: '/v1/scheduled-report-job/list',
      fetchOptions: { method: 'GET' },
      otherOptions: { bypassCache: true },
    }),
  create: (payload: Object) =>
    call({
      path: '/v1/scheduled-report-job',
      fetchOptions: { method: 'POST', body: payload },
      otherOptions: { bypassCache: true },
    }),
  update: (id: string, payload: Object) =>
    call({
      path: `/v1/scheduled-report-job/${id}`,
      fetchOptions: { method: 'PUT', body: payload },
      otherOptions: { bypassCache: true },
    }),
  delete: (id: Object) =>
    call({
      path: `/v1/scheduled-report-job/${id}`,
      fetchOptions: { method: 'DELETE' },
      otherOptions: { bypassCache: true },
    }),
};

export const organisations = {
  userHasMembership: () =>
    call({
      path: '/v1/organisation/userHasMembership',
      fetchOptions: { method: 'GET' },
    }),
};

export const heatmaps = {
  getSourceUrl: (id: string) =>
    call({
      path: `/v1/heatmap/${id}/get-source-url`,
      fetchOptions: { method: 'GET' },
    }),
};

export const taxonomyMetas = {
  list: () =>
    call({
      path: '/v1/taxonomy-meta/list',
      fetchOptions: { method: 'GET' },
    }),
};

export const cameraServer = {
  list: () =>
    call({
      path: '/v1/cameraServer/list',
      fetchOptions: { method: 'POST' },
    }),
};
