import * as ACTIONS from '../constants/actions';
import { heatmaps } from '../services/api';
import { errorToast } from '../utils/toaster';

export const getSourceUrl = (recordingId, heatmapId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: ACTIONS.FETCH_HEATMAP_SOURCE_URL_REQUEST });

    const response = await heatmaps.getSourceUrl(heatmapId);

    const payload = {
      recordingId,
      heatmapId,
      sourceUrl: response.url
    };

    dispatch({ type: ACTIONS.FETCH_HEATMAP_SOURCE_URL_SUCCESS, payload });

    // HACK: this action should update the state with the url, instead it's
    // returning it directly so I can use it in the heatmapViewer component
    // and force a rerender. Fix is to move heatmap page settings to pageSettings
    // reducer so the container controls the rerenders better
    return payload;
  } catch (error) {
    errorToast({ message: 'Error fetching heatmap image.', timeout: 2500 });
    dispatch({ type: ACTIONS.FETCH_HEATMAP_SOURCE_URL_ERROR });
  }
};
