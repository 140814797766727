/**
 * Factory of SplitChanges fetcher.
 * SplitChanges fetcher is a wrapper around `splitChanges` API service that parses the response and handle errors.
 */
export function splitChangesFetcherFactory(fetchSplitChanges) {
    return function splitChangesFetcher(since, noCache, 
    // Optional decorator for `fetchSplitChanges` promise, such as timeout or time tracker
    decorator) {
        var splitsPromise = fetchSplitChanges(since, noCache);
        if (decorator)
            splitsPromise = decorator(splitsPromise);
        return splitsPromise.then(function (resp) { return resp.json(); });
    };
}
