'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = reduce;

var _series = require('./__internal__/series');

var _series2 = _interopRequireDefault(_series);

var _resolveAll = require('./__internal__/resolveAll');

var _resolveAll2 = _interopRequireDefault(_resolveAll);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function reduce(iterable, reducer, initial) {
  return (0, _resolveAll2.default)(iterable).then((0, _series2.default)(reducer, initial));
}