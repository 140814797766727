import { startsWith } from '../utils/lang';
var everythingAtTheEnd = /[^.]+$/;
var DEFAULT_PREFIX = 'SPLITIO';
export function validatePrefix(prefix) {
    return prefix ? prefix + '.SPLITIO' : 'SPLITIO';
}
var KeyBuilder = /** @class */ (function () {
    function KeyBuilder(prefix) {
        if (prefix === void 0) { prefix = DEFAULT_PREFIX; }
        this.prefix = prefix;
    }
    KeyBuilder.prototype.buildTrafficTypeKey = function (trafficType) {
        return this.prefix + ".trafficType." + trafficType;
    };
    KeyBuilder.prototype.buildSplitKey = function (splitName) {
        return this.prefix + ".split." + splitName;
    };
    KeyBuilder.prototype.buildSplitsTillKey = function () {
        return this.prefix + ".splits.till";
    };
    // NOT USED
    // buildSplitsReady() {
    //   return `${this.prefix}.splits.ready`;
    // }
    KeyBuilder.prototype.isSplitKey = function (key) {
        return startsWith(key, this.prefix + ".split.");
    };
    KeyBuilder.prototype.buildSplitKeyPrefix = function () {
        return this.prefix + ".split.";
    };
    // Only used by InLocalStorage.
    KeyBuilder.prototype.buildSplitsWithSegmentCountKey = function () {
        return this.prefix + ".splits.usingSegments";
    };
    KeyBuilder.prototype.buildSegmentNameKey = function (segmentName) {
        return this.prefix + ".segment." + segmentName;
    };
    KeyBuilder.prototype.buildSegmentTillKey = function (segmentName) {
        return this.prefix + ".segment." + segmentName + ".till";
    };
    // NOT USED
    // buildSegmentsReady() {
    //   return `${this.prefix}.segments.ready`;
    // }
    KeyBuilder.prototype.extractKey = function (builtKey) {
        var s = builtKey.match(everythingAtTheEnd);
        if (s && s.length) {
            return s[0];
        }
        else {
            throw new Error('Invalid latency key provided');
        }
    };
    return KeyBuilder;
}());
export { KeyBuilder };
