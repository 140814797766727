import { ENGINE_MATCHER_CONTAINS_ANY } from '../../logger/constants';
import { findIndex } from '../../utils/lang';
export function containsAnySetMatcherContext(log, ruleAttr) {
    return function containsAnyMatcher(runtimeAttr) {
        var containsAny = false;
        var _loop_1 = function (i) {
            if (findIndex(runtimeAttr, function (e) { return e === ruleAttr[i]; }) >= 0)
                containsAny = true;
        };
        for (var i = 0; i < ruleAttr.length && !containsAny; i++) {
            _loop_1(i);
        }
        log.debug(ENGINE_MATCHER_CONTAINS_ANY, [runtimeAttr, ruleAttr, containsAny]);
        return containsAny;
    };
}
