// @flow

import { Component } from 'react';
import styled from 'styled-components';
import { Navbar, NavbarGroup, NavbarHeading } from '@blueprintjs/core';
import HeatmapViewer from '../../heatmap-viewer';
import Layout from '../../layouts/default-logged-in';
import { breakpoints } from '../../../styles/variables';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';

import * as RecordingModel from '../../../models/recording';

const TopBar = styled(Navbar)`
  padding-left: 2em;
  padding-right: 2em;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    padding: 1em;
  }
`;

type Props = {
  recordings: RecordingModel.t[],
  fetchHeatmaps(recordingId: string): any,
  getSourceUrl(recordingId: string, heatmapId: string): any,
  fetchingHeatmaps: boolean,
  fetchingHeatmapSourceUrl: boolean
};

class HeatmapsPage extends Component<Props> {
  componentDidMount() {
    trackEvent(ENTER_PAGE, 'HEATMAPS');
  }

  render() {
    const {
      recordings,
      fetchHeatmaps,
      getSourceUrl,
      fetchingHeatmaps,
      fetchingHeatmapSourceUrl
    } = this.props;

    if (!recordings.length) {
      return (
        <Layout>
          <TopBar>
            <NavbarGroup>
              <NavbarHeading>No camera placements found</NavbarHeading>
            </NavbarGroup>
          </TopBar>
        </Layout>
      );
    }

    return (
      <Layout>
        <HeatmapViewer
          fetchingHeatmaps={fetchingHeatmaps}
          fetchingHeatmapSourceUrl={fetchingHeatmapSourceUrl}
          recordings={recordings}
          fetchHeatmaps={fetchHeatmaps}
          getSourceUrl={getSourceUrl}
        />
      </Layout>
    );
  }
}

export default HeatmapsPage;
