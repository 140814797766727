import { allMatcherContext } from './all';
import { segmentMatcherContext } from './segment';
import { whitelistMatcherContext } from './whitelist';
import { equalToMatcherContext } from './eq';
import { greaterThanEqualMatcherContext } from './gte';
import { lessThanEqualMatcherContext } from './lte';
import { betweenMatcherContext } from './between';
import { equalToSetMatcherContext } from './eq_set';
import { containsAnySetMatcherContext } from './cont_any';
import { containsAllSetMatcherContext } from './cont_all';
import { partOfSetMatcherContext } from './part_of';
import { endsWithMatcherContext } from './ew';
import { startsWithMatcherContext } from './sw';
import { containsStringMatcherContext } from './cont_str';
import { dependencyMatcherContext } from './dependency';
import { booleanMatcherContext } from './boolean';
import { stringMatcherContext } from './string';
var matchers = [
    undefined,
    allMatcherContext,
    segmentMatcherContext,
    whitelistMatcherContext,
    equalToMatcherContext,
    greaterThanEqualMatcherContext,
    lessThanEqualMatcherContext,
    betweenMatcherContext,
    equalToSetMatcherContext,
    containsAnySetMatcherContext,
    containsAllSetMatcherContext,
    partOfSetMatcherContext,
    endsWithMatcherContext,
    startsWithMatcherContext,
    containsStringMatcherContext,
    dependencyMatcherContext,
    booleanMatcherContext,
    stringMatcherContext // MATCHES_STRING: 17
];
/**
 * Matcher factory.
 */
export function matcherFactory(log, matcherDto, storage) {
    var type = matcherDto.type, value = matcherDto.value;
    var matcherFn;
    // @ts-ignore
    if (matchers[type])
        matcherFn = matchers[type](log, value, storage); // There is no index-out-of-bound exception in JavaScript
    return matcherFn;
}
