import { submitterSyncTaskFactory } from './submitterSyncTask';
/**
 * Converts `impressionCounts` data from cache into request payload.
 */
export function fromImpressionCountsCollector(impressionsCount) {
    var pf = [];
    var keys = Object.keys(impressionsCount);
    for (var i = 0; i < keys.length; i++) {
        var splitted = keys[i].split('::');
        if (splitted.length !== 2)
            continue;
        var featureName = splitted[0];
        var timeFrame = splitted[1];
        var impressionsInTimeframe = {
            f: featureName,
            m: Number(timeFrame),
            rc: impressionsCount[keys[i]] // Count
        };
        pf.push(impressionsInTimeframe);
    }
    return { pf: pf };
}
var IMPRESSIONS_COUNT_RATE = 1800000; // 30 minutes
/**
 * Sync task that periodically posts impression counts
 */
export function impressionCountsSyncTaskFactory(log, postTestImpressionsCount, impressionCountsCache, latencyTracker) {
    // retry impressions counts only once.
    return submitterSyncTaskFactory(log, postTestImpressionsCount, impressionCountsCache, IMPRESSIONS_COUNT_RATE, 'impression counts', latencyTracker, fromImpressionCountsCollector, 1);
}
