import { SDK_SPLITS_ARRIVED } from '../../../readiness/constants';
import { Backoff } from '../../../utils/Backoff';
/**
 * SplitsUpdateWorker class
 */
var SplitsUpdateWorker = /** @class */ (function () {
    /**
     * @param {Object} splitsCache splits data cache
     * @param {Object} splitsSyncTask task for syncing splits data
     * @param {Object} splitsEventEmitter emitter for splits data events
     */
    function SplitsUpdateWorker(splitsCache, splitsSyncTask, splitsEventEmitter, segmentsSyncTask) {
        this.splitsCache = splitsCache;
        this.splitsSyncTask = splitsSyncTask;
        this.splitsEventEmitter = splitsEventEmitter;
        this.segmentsSyncTask = segmentsSyncTask;
        this.maxChangeNumber = 0;
        this.handleNewEvent = false;
        this.put = this.put.bind(this);
        this.killSplit = this.killSplit.bind(this);
        this.__handleSplitUpdateCall = this.__handleSplitUpdateCall.bind(this);
        this.backoff = new Backoff(this.__handleSplitUpdateCall);
    }
    // Private method
    // Preconditions: this.splitsSyncTask.isSynchronizingSplits === false
    SplitsUpdateWorker.prototype.__handleSplitUpdateCall = function () {
        var _this = this;
        if (this.maxChangeNumber > this.splitsCache.getChangeNumber()) {
            this.handleNewEvent = false;
            // fetch splits revalidating data if cached
            this.splitsSyncTask.execute(true).then(function () {
                if (_this.handleNewEvent) {
                    _this.__handleSplitUpdateCall();
                }
                else {
                    // fetch new registered segments for server-side API. Not retrying on error
                    if (_this.segmentsSyncTask)
                        _this.segmentsSyncTask.execute(undefined, false, true);
                    _this.backoff.scheduleCall();
                }
            });
        }
    };
    /**
     * Invoked by NotificationProcessor on SPLIT_UPDATE event
     *
     * @param {number} changeNumber change number of the SPLIT_UPDATE notification
     */
    SplitsUpdateWorker.prototype.put = function (_a) {
        var changeNumber = _a.changeNumber;
        var currentChangeNumber = this.splitsCache.getChangeNumber();
        if (changeNumber <= currentChangeNumber || changeNumber <= this.maxChangeNumber)
            return;
        this.maxChangeNumber = changeNumber;
        this.handleNewEvent = true;
        this.backoff.reset();
        if (this.splitsSyncTask.isExecuting())
            return;
        this.__handleSplitUpdateCall();
    };
    /**
     * Invoked by NotificationProcessor on SPLIT_KILL event
     *
     * @param {number} changeNumber change number of the SPLIT_UPDATE notification
     * @param {string} splitName name of split to kill
     * @param {string} defaultTreatment default treatment value
     */
    SplitsUpdateWorker.prototype.killSplit = function (_a) {
        var changeNumber = _a.changeNumber, splitName = _a.splitName, defaultTreatment = _a.defaultTreatment;
        if (this.splitsCache.killLocally(splitName, defaultTreatment, changeNumber)) {
            // trigger an SDK_UPDATE if Split was killed locally
            this.splitsEventEmitter.emit(SDK_SPLITS_ARRIVED, true);
        }
        // queues the SplitChanges fetch (only if changeNumber is newer)
        this.put({ changeNumber: changeNumber });
    };
    return SplitsUpdateWorker;
}());
export { SplitsUpdateWorker };
