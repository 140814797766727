// @flow

import { isEqual, cloneDeep } from 'lodash';
import {
  INSUFFICIENT_SALES_DATA,
  NOT_AVAILABLE,
} from '../constants/error-messages';
import { prettyValueToCompare } from '../utils/summaryPageHelpers';

import type { sortByT } from '../components/metrics-table/index';
import type { filterT } from '../models/active-metrics';
import * as ActiveMetricsModel from '../models/active-metrics';
import * as LocationModel from '../models/location';
import * as QueryResponseListModel from '../models/query-response-list';
import * as AvailableMetricsModel from '../models/available-metrics';
import * as QueryResponseModel from '../models/query-response';
import * as RecordingModel from '../models/recording';
import * as QueryApiRequestModel from '../models/query-api-request';
import type { breakdownByEnum } from '../constants/graph-options';
import { areTwoArraysEquals } from './generalHelpers';
import type { splitsT } from '../models/query-response';

export const ALL_LOCATIONS = 'All locations';
export const LOCATIONS = 'locations';
export const METRICS = 'metrics';
export const NULL_VALUE = '-';
export const STRING_NULL_VALUE = 'n/a';
export const NONE = '';
export const ASCENDANT = 'asc';
export const DESCENDANT = 'desc';

export type labelObjectT = {
  subLabel: string,
  label: string,
};

export type metricsTableCellT = {
  locationId: string | string[],
  metricGroupKey: string,
  metricKey: string,
  taxonomies?: Array<string>,
  displayType?: 'string' | 'splits' | 'number',
  splits?: QueryResponseModel.splitsT,
  unitType?: QueryResponseModel.unitTypeT,
  grid: {
    row: number,
    column: number,
  },
  value?: string | number | labelObjectT,
  isLocationHeading?: boolean,
  isMetricHeading?: boolean,
  isComparison?: boolean,
  valueToCompare?: number | string | splitsT,
  filter?: filterT,
  previews?: string[],
};
export type metricsTableRowT = metricsTableCellT[];
export type metricsTableT = metricsTableRowT[];

export const getColumnLabel = (
  metric: ActiveMetricsModel.itemT,
  availableMetrics: AvailableMetricsModel.t,
  displayTaxonomy?: boolean = false,
  displayFilters?: boolean = false
): string => {
  const metricDetails = AvailableMetricsModel.findMetricDetails(
    availableMetrics,
    metric.metricGroupKey,
    metric.metricKey
  );

  if (!metricDetails) return `${metric.metricGroupKey} > ${metric.metricKey}`;

  // const metricGroupKeyTitle = availableMetrics[metric.metricGroupKey].title;
  const metricLabel = AvailableMetricsModel.getMetricLabel(metricDetails);

  if (displayTaxonomy && metric.taxonomies) {
    return `${metric.taxonomies
      .map((taxonomy) => taxonomy.replace(/:/g, ': '))
      .join(', ')} - ${metricLabel}`;
  }
  if (displayTaxonomy && metric.taxonomy) {
    return `${metric.taxonomy.replace(/:/g, ': ')} - ${metricLabel}`;
  }

  if (displayFilters && metric.filter) {
    return `${metric.filter.key}: ${metric.filter.value} - ${metricLabel}`;
  }

  return metricLabel;
};

export const isAllLocationsCell = (cell: metricsTableCellT): boolean => {
  return !!cell.isLocationHeading && Array.isArray(cell.locationId);
};

export const getColumnLabelAsObject = (
  headingConfig: ActiveMetricsModel.itemT,
  availableMetrics: AvailableMetricsModel.t
): labelObjectT | null => {
  const label = getColumnLabel(headingConfig, availableMetrics);

  let labelObject = null;

  if (headingConfig && headingConfig.taxonomies && label) {
    labelObject = {
      label,
      subLabel: headingConfig.taxonomies.join(', '),
    };
  } else if (headingConfig && headingConfig.filter && label) {
    labelObject = {
      label,
      subLabel: `${headingConfig.filter.key} - ${headingConfig.filter.value}`,
    };
  }

  return labelObject;
};

export const isLocationSelected = (
  cell: metricsTableCellT,
  locations?: Array<string>
): boolean => {
  return !!locations && !!locations.find((l) => isEqual(l, cell.locationId));
};

export const isCellLocationBeingSelected = (
  cell: metricsTableCellT,
  locations?: Array<string>
): boolean => {
  const isLocationCell = cell.isLocationHeading;
  return !!isLocationCell && isLocationSelected(cell, locations);
};

type metricsTableGridArgument = {
  excludeStaff: boolean,
  period: QueryApiRequestModel.periodT,
  compareToPeriod: QueryApiRequestModel.periodT,
  recordings?: RecordingModel.t[],
  useSplits: boolean,
  queryResponseList: QueryResponseListModel.t,
  filteredLocationList: string[],
  locations: LocationModel.t[],
  activeMetricsHeadings: ActiveMetricsModel.activeMetricsListT,
  isTotalsMetrics?: boolean,
  isComparisonActive?: boolean,
  breakdownBy: breakdownByEnum,
};

export const metricsTableGridMaker = ({
  excludeStaff,
  period,
  compareToPeriod,
  recordings,
  useSplits,
  queryResponseList,
  filteredLocationList,
  locations,
  activeMetricsHeadings,
  isTotalsMetrics = false,
  isComparisonActive = false,
  breakdownBy,
}: metricsTableGridArgument): metricsTableT => {
  const filteredLocationListWithTotals = isTotalsMetrics
    ? [filteredLocationList]
    : filteredLocationList;
  const gridBaseWithoutValues = [];

  filteredLocationListWithTotals.forEach((gridRowLocId, gridRowIndex) => {
    const locationName = isTotalsMetrics
      ? ALL_LOCATIONS
      : typeof gridRowLocId === 'string'
      ? LocationModel.getLocationName(gridRowLocId, locations)
      : NULL_VALUE;
    gridBaseWithoutValues.push([
      {
        isLocationHeading: true,
        locationId: gridRowLocId,
        metricGroupKey: LOCATIONS,
        metricKey: LOCATIONS,
        value: locationName,
        grid: {
          row: gridRowIndex,
          column: 0,
        },
        isComparison: false,
      },
    ]);

    activeMetricsHeadings.forEach((gridColumnMetric, gridColumnIndex) => {
      const metricConfigObj = {
        locationId: gridRowLocId,
        metricGroupKey: gridColumnMetric.metricGroupKey,
        metricKey: gridColumnMetric.metricKey,
        taxonomies: gridColumnMetric.taxonomies,
        isComparison: isComparisonActive,
        grid: {
          row: gridRowIndex,
          column: gridColumnIndex + 1,
        },
        filter: undefined,
      };

      if (gridColumnMetric.filter) {
        metricConfigObj.filter = {
          key: gridColumnMetric.filter.key,
          value: gridColumnMetric.filter.value,
        };
      }

      gridBaseWithoutValues[gridRowIndex][gridColumnIndex + 1] =
        metricConfigObj;
    });
  });

  // Add the correct value for each cell
  // TODO: this array reshaping is tech debt from when the API calls were per-cell instead of per-column
  const columns =
    gridBaseWithoutValues && gridBaseWithoutValues.length > 0
      ? gridBaseWithoutValues[0].map((column, colIndex) => {
          if (column.metricGroupKey === LOCATIONS) {
            return gridBaseWithoutValues.map((row, r) => ({
              ...row[colIndex],
              displayType: 'string',
              grid: {
                row: r,
                column: colIndex,
              },
            }));
          }

          const findCriteria = {
            aggregation: 'day',
            period,
            isComparisonActive: false,
            metricGroupKey: column.metricGroupKey,
            locations: filteredLocationList,
            metricKey: column.metricKey,
            taxonomies: column.taxonomies
              ? column.taxonomies
              : column.filter
              ? [`${column.filter.key}:${column.filter.value}`]
              : undefined,
            excludeStaff,
            isAllLocations: false,
            breakdownByDimensions: isTotalsMetrics ? undefined : ['entity'],
          };

          if (breakdownBy !== 'total_only') {
            if (findCriteria.breakdownByDimensions) {
              findCriteria.breakdownByDimensions.push(breakdownBy);
            } else {
              findCriteria.breakdownByDimensions = [breakdownBy];
            }
          }

          const hasSplits =
            breakdownBy === 'gender' ||
            breakdownBy === 'age' ||
            breakdownBy === 'role';

          const queryData = QueryResponseListModel.findOne(
            findCriteria,
            queryResponseList
          );

          let comparisonQueryData;

          if (queryData && column.isComparison) {
            const comparisonFindCriteria = {
              ...findCriteria,
              period: compareToPeriod,
              isComparisonActive: true,
            };

            comparisonQueryData = QueryResponseListModel.findOne(
              comparisonFindCriteria,
              queryResponseList
            );
          }

          let r = 0;
          let values = [];
          let comparisonValues: Array<number | string | splitsT> = [];
          let previews = [];

          if (isTotalsMetrics && queryData && queryData.data) {
            let value = NULL_VALUE;
            if (queryData && queryData.data) {
              if (hasSplits) {
                value = QueryResponseModel.getSummaryByBreakdown({
                  response: queryData.data,
                  metricKey: column.metricKey,
                });
              } else {
                value = QueryResponseModel.getSummaryValue({
                  response: queryData.data,
                  metricKey: column.metricKey,
                });
              }
            }

            values = [value === null ? NULL_VALUE : value];

            if (column.isComparison && comparisonQueryData && !hasSplits) {
              let compValue = NULL_VALUE;
              if (comparisonQueryData && comparisonQueryData.data) {
                if (hasSplits) {
                  compValue = QueryResponseModel.getSummaryByBreakdown({
                    response: queryData.data,
                    metricKey: column.metricKey,
                  });
                } else {
                  compValue = QueryResponseModel.getSummaryValue({
                    response: comparisonQueryData.data,
                    metricKey: column.metricKey,
                  });
                }
              }
              comparisonValues = [compValue === null ? NULL_VALUE : compValue];
            }

            previews =
              queryData && queryData.data
                ? [QueryResponseModel.getPreviews(queryData.data)]
                : [undefined];
          } else if (!isTotalsMetrics && queryData) {
            values = filteredLocationList.map((locationId) => {
              let value = NULL_VALUE;
              if (queryData && queryData.data) {
                if (hasSplits) {
                  value = QueryResponseModel.getSummaryByBreakdown({
                    response: queryData.data,
                    metricKey: column.metricKey,
                    filterBy: {
                      location: locationId,
                    },
                  });
                } else {
                  value = QueryResponseModel.getSummaryValue({
                    response: queryData.data,
                    metricKey: column.metricKey,
                    locationId,
                  });
                }
              }
              return value === null ? NULL_VALUE : value;
            });

            if (column.isComparison && comparisonQueryData && !hasSplits) {
              comparisonValues = filteredLocationList.map((locationId) => {
                let compValue = NULL_VALUE;
                if (comparisonQueryData && comparisonQueryData.data) {
                  if (hasSplits) {
                    compValue = QueryResponseModel.getSummaryByBreakdown({
                      response: comparisonQueryData.data,
                      metricKey: column.metricKey,
                      filterBy: {
                        location: locationId,
                      },
                    });
                  } else {
                    compValue = QueryResponseModel.getSummaryValue({
                      response: comparisonQueryData.data,
                      metricKey: column.metricKey,
                      locationId,
                    });
                  }
                }
                return compValue === null ? NULL_VALUE : compValue;
              });
            }

            if (queryData && queryData.data) {
              previews = filteredLocationList.map((locationId) =>
                queryData.data
                  ? QueryResponseModel.getPreviewsByLocation(
                      queryData.data,
                      locationId
                    )
                  : undefined
              );
            }
          } else {
            values = new Array(filteredLocationList.length).fill(NULL_VALUE);
            if (column.isComparison) {
              comparisonValues = new Array(filteredLocationList.length).fill(
                NULL_VALUE
              );
            }
          }
          const arr = values.map((value, i) => {
            const valueHasSplits =
              hasSplits &&
              breakdownBy !== 'total_only' &&
              typeof value === 'object' &&
              value[breakdownBy];

            return {
              ...column,
              locationId: filteredLocationList[i], // TODO: strongly couple the location ID and values. This relies on the array sorting.
              value:
                value !== null && !valueHasSplits && typeof value !== 'object'
                  ? value
                  : NULL_VALUE,
              valueToCompare: comparisonValues[i] !== null
                ? comparisonValues[i]
                : NULL_VALUE,
              displayType: valueHasSplits ? 'splits' : 'number',
              splits:
                typeof value === 'object' && valueHasSplits ? value : undefined,
              unitType:
                queryData && queryData.data
                  ? QueryResponseModel.getUnitType(
                      queryData.data,
                      queryData.metricKey
                    )
                  : 'number',
              previews: previews[i],
              grid: {
                row: (r += 1),
                column: colIndex,
              },
            };
          });

          return arr;
        })
      : [new Array(filteredLocationList.length)];

  return gridBaseWithoutValues.map((row, i) =>
    columns.map((column) => column[i])
  );
};

export const sortMetricsGridTable = (
  sortBy: sortByT,
  metricsTableGrid: metricsTableT,
  isComparisonActive: boolean
): metricsTableT => {
  const { columnDetails, sortDirection, isSortByComparison } = sortBy;
  if (!columnDetails && !sortDirection) return metricsTableGrid;
  const compareColumn = (column, columnDetails) => {
    if (columnDetails.isLocationHeading) {
      return typeof columnDetails.locationId === 'string'
        ? column.locationId === columnDetails.locationId
        : isEqual(column.locationId, columnDetails.locationId);
    }

    return (
      column.metricGroupKey === columnDetails.metricGroupKey &&
      column.metricKey === columnDetails.metricKey &&
      column.isComparison === isComparisonActive
    );
  };

  let index =
    sortDirection !== NONE &&
    metricsTableGrid.length > 0 &&
    metricsTableGrid[0].find((column) => compareColumn(column, columnDetails));

  if (
    !index &&
    (ActiveMetricsModel.isGeneralLocationHeading(columnDetails) ||
      ActiveMetricsModel.isGeneralMetricHeading(columnDetails))
  ) {
    index = {
      grid: {
        row: 0,
        column: 0,
      },
    };
  } else if (!index) {
    return metricsTableGrid;
  }

  const sortNumberValues = (valueA: number, valueB: number): number => {
    if (sortDirection === DESCENDANT) {
      return valueA > valueB ? -1 : 1;
    } else if (sortDirection === ASCENDANT) {
      return valueA < valueB ? -1 : 1;
    }
    return 0;
  };

  const sortStringValues = (valueA: string, valueB: string): number => {
    if (sortDirection === DESCENDANT) {
      return valueA > valueB ? -1 : 1;
    } else if (sortDirection === ASCENDANT) {
      return valueA < valueB ? -1 : 1;
    }
    return 0;
  };

  const sortedMetricsGrid = metricsTableGrid.sort((rowA, rowB) => {
    if (index && index.grid) {
      let valueA = rowA[index.grid.column].value;
      let valueB = rowB[index.grid.column].value;

      if (isSortByComparison) {
        const comparisonValueA =
          typeof rowA[index.grid.column].value === 'number'
            ? rowA[index.grid.column].value
            : 0;
        const comparisonValueB =
          typeof rowB[index.grid.column].value === 'number'
            ? rowB[index.grid.column].value
            : 0;

        const valueToCompareA =
          typeof rowA[index.grid.column].valueToCompare === 'number'
            ? rowA[index.grid.column].valueToCompare
            : 0;
        const valueToCompareB =
          typeof rowB[index.grid.column].valueToCompare === 'number'
            ? rowB[index.grid.column].valueToCompare
            : 0;

        const { result: resultA } = prettyValueToCompare(
          valueToCompareA,
          comparisonValueA,
        );
        const { result: resultB } = prettyValueToCompare(
          valueToCompareB,
          comparisonValueB,
        );

        valueA = resultA;
        valueB = resultB;
      }

      if (
        valueA === NULL_VALUE ||
        valueA === INSUFFICIENT_SALES_DATA ||
        valueA === NOT_AVAILABLE
      ) {
        if (sortDirection === DESCENDANT) {
          return 1;
        } else {
          return -1;
        }
      } else if (
        valueB === NULL_VALUE ||
        valueB === INSUFFICIENT_SALES_DATA ||
        valueB === NOT_AVAILABLE
      ) {
        if (sortDirection === DESCENDANT) {
          return -1;
        } else {
          return 1;
        }
      }
      if (valueA && valueB) {
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          return sortNumberValues(valueA, valueB);
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return sortStringValues(valueA, valueB);
        } else if (typeof valueA === 'object' && typeof valueB === 'object') {
          if (valueA.label === valueB.label) {
            return sortStringValues(valueA.subLabel, valueB.subLabel);
          }

          return sortStringValues(valueA.label, valueB.label);
        }
      } else if (valueA && !valueB) {
        return sortDirection === DESCENDANT ? -1 : 1;
      } else if (!valueA && valueB) {
        return sortDirection === DESCENDANT ? 1 : -1;
      } else if (
        (typeof valueA === 'number' && typeof valueB === 'string') ||
        (typeof valueB === 'number' && typeof valueA === 'string')
      ) {
        // This case occurs when the displayValue !== displayType, when data is undefined and given a displayValue
        return sortDirection === DESCENDANT && isNaN(parseFloat(valueA))
          ? 1
          : -1;
      }
      return 0;
    }
    return 0;
  });

  return index ? sortedMetricsGrid : metricsTableGrid;
};

export const invertMetricsGridTable = (
  metricsGridTable: metricsTableT,
  activeMetricsHeadings: ActiveMetricsModel.activeMetricsHeadingsT[],
  availableMetrics: AvailableMetricsModel.t,
  tableMetricsTotal?: metricsTableT
): {
  invertedMetricsTable: metricsTableT,
  invertedActiveMetricsHeadings: ActiveMetricsModel.activeMetricsHeadingsT[],
} => {
  let baseInvertedMetricsTable = [];

  // Inverts the table
  metricsGridTable.forEach((row, rowIndex) => {
    if (row && row.length) {
      row.forEach((column, columnIndex) => {
        const newColumn = cloneDeep(column);
        newColumn.grid.column = rowIndex;
        newColumn.grid.row = columnIndex;
        if (rowIndex === 0) {
          baseInvertedMetricsTable.push([newColumn]);
        } else {
          baseInvertedMetricsTable[columnIndex].push(newColumn);
        }
      });
    }
  });

  if (tableMetricsTotal && tableMetricsTotal.length > 0) {
    const invertedBaseInvertedMetricsTable = [];

    // Inver the All Locations grid
    tableMetricsTotal.forEach((row, rowIndex) => {
      if (row && row.length) {
        row.forEach((column, columnIndex) => {
          const newColumn = cloneDeep(column);
          newColumn.grid.column = rowIndex;
          newColumn.grid.row = columnIndex;
          if (rowIndex === 0) {
            invertedBaseInvertedMetricsTable.push([newColumn]);
          } else {
            invertedBaseInvertedMetricsTable[columnIndex].push(newColumn);
          }
        });
      }
    });

    // Merge All Locations into baseInvertedMetricsTable at the end of each row
    baseInvertedMetricsTable = baseInvertedMetricsTable.map((row, rowIndex) => {
      return [...row, invertedBaseInvertedMetricsTable[rowIndex][0]];
    });
  }

  // Gets all the headers locations from the first row in the invertedTable
  const invertedActiveMetricsHeadings =
    baseInvertedMetricsTable &&
    baseInvertedMetricsTable[0] &&
    baseInvertedMetricsTable[0].map((firstRowColumn) => {
      return {
        isLocationHeading: true,
        locationId: firstRowColumn.locationId,
        taxonomies: firstRowColumn.taxonomies,
        filter: firstRowColumn.filter,
        metricGroupKey: firstRowColumn.metricGroupKey,
        metricKey: firstRowColumn.metricKey,
        label: firstRowColumn.value,
      };
    });

  // Removes the first row with all the locations
  const invertedMetricsWithoutLocationRow = baseInvertedMetricsTable.filter(
    (row, rowIndex) => {
      return rowIndex !== 0;
    }
  );

  // Adds in each row the first column which is the metric name
  const invertedMetricsWithFirstColumnMetric =
    invertedMetricsWithoutLocationRow.map((row, rowIndex) => {
      const metric = activeMetricsHeadings[rowIndex];
      const label =
        getColumnLabelAsObject(metric, availableMetrics) ||
        getColumnLabel(metric, availableMetrics, true, true);

      const metricColumn = {
        isMetricHeading: true,
        locationId: metric.locationId,
        metricGroupKey: metric.metricGroupKey,
        metricKey: metric.metricKey,
        taxonomies: metric.taxonomies,
        filter: metric.filter,
        value: label,
        isComparison: false,
        displayType: 'string',
      };

      return [metricColumn, ...row];
    });

  // This sets the correct grid.column and grid.row
  const invertedMetricsTable = invertedMetricsWithFirstColumnMetric.reduce(
    (totalInvertedMetrics, row, rowIndex) => {
      const newRow = row.map((column, columnIndex) => ({
        ...column,
        grid: {
          column: columnIndex,
          row: rowIndex,
        },
      }));

      totalInvertedMetrics.push(newRow);

      return totalInvertedMetrics;
    },
    []
  );

  return {
    // $FlowIgnore - no time to fix right now
    invertedMetricsTable,
    invertedActiveMetricsHeadings,
  };
};

export const getDescriptionFromAvailableMetrics = (
  metric: ActiveMetricsModel.itemT,
  availableMetrics: AvailableMetricsModel.t
): ?string => {
  const availableMetricFound = AvailableMetricsModel.findMetricDetails(
    availableMetrics,
    metric.metricGroupKey,
    metric.metricKey
  );

  if (availableMetricFound) {
    return AvailableMetricsModel.getMetricDescription(availableMetricFound);
  }

  return null;
};
