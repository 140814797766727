import { ERROR_INVALID_CONFIG_PARAM } from '../../logger/constants';
import { CONSENT_DECLINED, CONSENT_GRANTED, CONSENT_UNKNOWN } from '../constants';
import { stringToUpperCase } from '../lang';
var userConsentValues = [CONSENT_DECLINED, CONSENT_GRANTED, CONSENT_UNKNOWN];
export function validateConsent(_a) {
    var userConsent = _a.userConsent, log = _a.log;
    userConsent = stringToUpperCase(userConsent);
    if (userConsentValues.indexOf(userConsent) > -1)
        return userConsent;
    log.error(ERROR_INVALID_CONFIG_PARAM, ['userConsent', userConsentValues, CONSENT_GRANTED]);
    return CONSENT_GRANTED;
}
