import { objectAssign } from '../utils/lang/objectAssign';
import { validateAttributes, validateEvent, validateEventValue, validateEventProperties, validateKey, validateSplit, validateSplits, validateTrafficType, validateIfNotDestroyed, validateIfOperational } from '../utils/inputValidation';
import { startsWith } from '../utils/lang';
import { CONTROL, CONTROL_WITH_CONFIG } from '../utils/constants';
import { isStorageSync } from '../trackers/impressionObserver/utils';
/**
 * Decorator that validates the input before actually executing the client methods.
 * We should "guard" the client here, while not polluting the "real" implementation of those methods.
 */
export function clientInputValidationDecorator(settings, client, readinessManager) {
    var log = settings.log;
    var isSync = isStorageSync(settings);
    /**
     * Avoid repeating this validations code
     */
    function validateEvaluationParams(maybeKey, maybeSplitOrSplits, maybeAttributes, methodName) {
        var multi = startsWith(methodName, 'getTreatments');
        var key = validateKey(log, maybeKey, methodName);
        var splitOrSplits = multi ? validateSplits(log, maybeSplitOrSplits, methodName) : validateSplit(log, maybeSplitOrSplits, methodName);
        var attributes = validateAttributes(log, maybeAttributes, methodName);
        var isOperational = validateIfNotDestroyed(log, readinessManager, methodName);
        validateIfOperational(log, readinessManager, methodName);
        var valid = isOperational && key && splitOrSplits && attributes !== false;
        return {
            valid: valid,
            key: key,
            splitOrSplits: splitOrSplits,
            attributes: attributes
        };
    }
    function wrapResult(value) {
        return isSync ? value : Promise.resolve(value);
    }
    function getTreatment(maybeKey, maybeSplit, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeSplit, maybeAttributes, 'getTreatment');
        if (params.valid) {
            return client.getTreatment(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            return wrapResult(CONTROL);
        }
    }
    function getTreatmentWithConfig(maybeKey, maybeSplit, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeSplit, maybeAttributes, 'getTreatmentWithConfig');
        if (params.valid) {
            return client.getTreatmentWithConfig(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            return wrapResult(objectAssign({}, CONTROL_WITH_CONFIG));
        }
    }
    function getTreatments(maybeKey, maybeSplits, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeSplits, maybeAttributes, 'getTreatments');
        if (params.valid) {
            return client.getTreatments(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            var res_1 = {};
            if (params.splitOrSplits)
                params.splitOrSplits.forEach(function (split) { return res_1[split] = CONTROL; });
            return wrapResult(res_1);
        }
    }
    function getTreatmentsWithConfig(maybeKey, maybeSplits, maybeAttributes) {
        var params = validateEvaluationParams(maybeKey, maybeSplits, maybeAttributes, 'getTreatmentsWithConfig');
        if (params.valid) {
            return client.getTreatmentsWithConfig(params.key, params.splitOrSplits, params.attributes);
        }
        else {
            var res_2 = {};
            if (params.splitOrSplits)
                params.splitOrSplits.forEach(function (split) { return res_2[split] = objectAssign({}, CONTROL_WITH_CONFIG); });
            return wrapResult(res_2);
        }
    }
    function track(maybeKey, maybeTT, maybeEvent, maybeEventValue, maybeProperties) {
        var key = validateKey(log, maybeKey, 'track');
        var tt = validateTrafficType(log, maybeTT, 'track');
        var event = validateEvent(log, maybeEvent, 'track');
        var eventValue = validateEventValue(log, maybeEventValue, 'track');
        var _a = validateEventProperties(log, maybeProperties, 'track'), properties = _a.properties, size = _a.size;
        var isOperational = validateIfNotDestroyed(log, readinessManager, 'track');
        if (isOperational && key && tt && event && eventValue !== false && properties !== false) { // @ts-expect-error
            return client.track(key, tt, event, eventValue, properties, size);
        }
        else {
            return isSync ? false : Promise.resolve(false);
        }
    }
    return {
        getTreatment: getTreatment,
        getTreatmentWithConfig: getTreatmentWithConfig,
        getTreatments: getTreatments,
        getTreatmentsWithConfig: getTreatmentsWithConfig,
        track: track
    };
}
