import { thenable } from '../promise/thenable';
import { LOCALHOST_MODE } from '../constants';
import { WARN_NOT_EXISTENT_TT } from '../../logger/constants';
function logTTExistanceWarning(log, maybeTT, method) {
    log.warn(WARN_NOT_EXISTENT_TT, [method, maybeTT]);
}
/**
 * Separated from the previous method since on some cases it'll be async.
 */
export function validateTrafficTypeExistance(log, readinessManager, splitsCache, mode, maybeTT, method) {
    // If not ready or in localhost mode, we won't run the validation
    if (!readinessManager.isReady() || mode === LOCALHOST_MODE)
        return true;
    var res = splitsCache.trafficTypeExists(maybeTT); // never rejects or throws error
    if (thenable(res)) {
        return res.then(function (isValid) {
            if (!isValid)
                logTTExistanceWarning(log, maybeTT, method);
            return isValid; // propagate result
        });
    }
    else {
        if (!res)
            logTTExistanceWarning(log, maybeTT, method);
        return res;
    }
}
