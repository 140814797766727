import { ENGINE_MATCHER_STRING_INVALID, ENGINE_MATCHER_STRING } from '../../logger/constants';
export function stringMatcherContext(log, ruleAttr) {
    return function stringMatcher(runtimeAttr) {
        var re;
        try {
            re = new RegExp(ruleAttr);
        }
        catch (e) {
            log.debug(ENGINE_MATCHER_STRING_INVALID, [ruleAttr]);
            return false;
        }
        var regexMatches = re.test(runtimeAttr);
        log.debug(ENGINE_MATCHER_STRING, [runtimeAttr, ruleAttr, regexMatches ? 'yes' : 'no']);
        return regexMatches;
    };
}
