'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = mapLimit;

var _concurrent = require('./__internal__/concurrent');

var _concurrent2 = _interopRequireDefault(_concurrent);

var _resolveAll = require('./__internal__/resolveAll');

var _resolveAll2 = _interopRequireDefault(_resolveAll);

var _utils = require('./__internal__/utils');

var _map = require('./map');

var _map2 = _interopRequireDefault(_map);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function mapLimit(iterable) {
  var mapper = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _utils.identity;
  var limit = arguments[2];

  var resolved = {};

  if (!limit || iterable.length <= limit) {
    return (0, _map2.default)(iterable, mapper);
  }

  return (0, _resolveAll2.default)(iterable).then((0, _concurrent2.default)({
    limit: limit,
    breakOnError: true,
    transform: mapper,
    onItemResolved: function onItemResolved(value, key) {
      resolved[key] = value;
    },

    onItemCompleted: function onItemCompleted(done) {
      return function (count, values) {
        if (count === values.length) {
          done((0, _utils.toArray)(resolved));
        }
      };
    }
  }));
}