/**
 * Factory of MySegments fetcher.
 * MySegments fetcher is a wrapper around `mySegments` API service that parses the response and handle errors.
 */
export function mySegmentsFetcherFactory(fetchMySegments) {
    return function mySegmentsFetcher(userMatchingKey, noCache, 
    // Optional decorator for `fetchMySegments` promise, such as timeout or time tracker
    decorator) {
        var mySegmentsPromise = fetchMySegments(userMatchingKey, noCache);
        if (decorator)
            mySegmentsPromise = decorator(mySegmentsPromise);
        // Extract segment names
        return mySegmentsPromise
            .then(function (resp) { return resp.json(); })
            .then(function (json) { return json.mySegments.map(function (segment) { return segment.name; }); });
    };
}
