import { objectAssign } from '../utils/lang/objectAssign';
import { ERROR_HTTP, ERROR_CLIENT_CANNOT_GET_READY } from '../logger/constants';
var messageNoFetch = 'Global fetch API is not available.';
/**
 * Factory of Split HTTP clients, which are HTTP clients with predefined headers for Split endpoints.
 *
 * @param settings SDK settings, used to access authorizationKey, logger instance and metadata (SDK version, ip and hostname) to set additional headers
 * @param options global request options
 * @param fetch optional http client to use instead of the global Fetch (for environments where Fetch API is not available such as Node)
 */
export function splitHttpClientFactory(settings, getFetch, getOptions) {
    var log = settings.log, authorizationKey = settings.core.authorizationKey, version = settings.version, _a = settings.runtime, ip = _a.ip, hostname = _a.hostname;
    var options = getOptions && getOptions();
    var fetch = getFetch && getFetch();
    // if fetch is not available, log Error
    if (!fetch)
        log.error(ERROR_CLIENT_CANNOT_GET_READY, [messageNoFetch]);
    var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authorizationKey,
        'SplitSDKVersion': version
    };
    if (ip)
        headers['SplitSDKMachineIP'] = ip;
    if (hostname)
        headers['SplitSDKMachineName'] = hostname;
    return function httpClient(url, reqOpts, logErrorsAsInfo) {
        if (reqOpts === void 0) { reqOpts = {}; }
        if (logErrorsAsInfo === void 0) { logErrorsAsInfo = false; }
        var request = objectAssign({
            headers: reqOpts.headers ? objectAssign({}, headers, reqOpts.headers) : headers,
            method: reqOpts.method || 'GET',
            body: reqOpts.body
        }, options);
        // using `fetch(url, options)` signature to work with unfetch, a lightweight ponyfill of fetch API.
        return fetch ? fetch(url, request)
            // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Checking_that_the_fetch_was_successful
            .then(function (response) {
            if (!response.ok) {
                return response.text().then(function (message) { return Promise.reject({ response: response, message: message }); });
            }
            return response;
        })
            .catch(function (error) {
            var resp = error && error.response;
            var msg = '';
            if (resp) { // An HTTP error
                switch (resp.status) {
                    case 404:
                        msg = 'Invalid API key or resource not found.';
                        break;
                    // Don't use resp.statusText since reason phrase is removed in HTTP/2
                    default:
                        msg = error.message;
                        break;
                }
            }
            else { // Something else, either an error making the request or a Network error.
                msg = error.message || 'Network Error';
            }
            if (!resp || resp.status !== 403) { // 403's log we'll be handled somewhere else.
                log[logErrorsAsInfo ? 'info' : 'error'](ERROR_HTTP, [resp ? resp.status : 'NO_STATUS', url, msg]);
            }
            var networkError = new Error(msg);
            // passes `undefined` as statusCode if not an HTTP error (resp === undefined)
            networkError.statusCode = resp && resp.status;
            throw networkError;
        }) : Promise.reject(new Error(messageNoFetch));
    };
}
