import { fromImpressionsCollector } from '../sync/submitters/impressionsSyncTask';
import { fromImpressionCountsCollector } from '../sync/submitters/impressionCountsSyncTask';
import { OPTIMIZED, DEBUG } from '../utils/constants';
import { objectAssign } from '../utils/lang/objectAssign';
import { CLEANUP_REGISTERING, CLEANUP_DEREGISTERING } from '../logger/constants';
import { isConsentGranted } from '../consent';
// 'unload' event is used instead of 'beforeunload', since 'unload' is not a cancelable event, so no other listeners can stop the event from occurring.
var UNLOAD_DOM_EVENT = 'unload';
var EVENT_NAME = 'for unload page event.';
/**
 * We'll listen for 'unload' event over the window object, since it's the standard way to listen page reload and close.
 */
var BrowserSignalListener = /** @class */ (function () {
    function BrowserSignalListener(syncManager, settings, storage, serviceApi) {
        this.syncManager = syncManager;
        this.settings = settings;
        this.storage = storage;
        this.serviceApi = serviceApi;
        this.flushData = this.flushData.bind(this);
        this.fromImpressionsCollector = fromImpressionsCollector.bind(undefined, settings.core.labelsEnabled);
    }
    /**
     * start method.
     * Called when SplitFactory is initialized.
     * We add a handler on unload events. The handler flushes remaining impressions and events to the backend.
     */
    BrowserSignalListener.prototype.start = function () {
        if (typeof window !== 'undefined' && window.addEventListener) {
            this.settings.log.debug(CLEANUP_REGISTERING, [EVENT_NAME]);
            window.addEventListener(UNLOAD_DOM_EVENT, this.flushData);
        }
    };
    /**
     * stop method.
     * Called when client is destroyed.
     * We need to remove the handler for unload events, since it can break if called when Split context was destroyed.
     */
    BrowserSignalListener.prototype.stop = function () {
        if (typeof window !== 'undefined' && window.removeEventListener) {
            this.settings.log.debug(CLEANUP_DEREGISTERING, [EVENT_NAME]);
            window.removeEventListener(UNLOAD_DOM_EVENT, this.flushData);
        }
    };
    /**
     * flushData method.
     * Called when unload event is triggered. It flushed remaining impressions and events to the backend,
     * using beacon API if possible, or falling back to regular post transport.
     */
    BrowserSignalListener.prototype.flushData = function () {
        if (!this.syncManager)
            return; // In consumer mode there is not sync manager and data to flush
        // Flush data if there is user consent
        if (isConsentGranted(this.settings)) {
            var eventsUrl = this.settings.urls.events;
            var extraMetadata = {
                // sim stands for Sync/Split Impressions Mode
                sim: this.settings.sync.impressionsMode === OPTIMIZED ? OPTIMIZED : DEBUG
            };
            this._flushData(eventsUrl + '/testImpressions/beacon', this.storage.impressions, this.serviceApi.postTestImpressionsBulk, this.fromImpressionsCollector, extraMetadata);
            this._flushData(eventsUrl + '/events/beacon', this.storage.events, this.serviceApi.postEventsBulk);
            if (this.storage.impressionCounts)
                this._flushData(eventsUrl + '/testImpressions/count/beacon', this.storage.impressionCounts, this.serviceApi.postTestImpressionsCount, fromImpressionCountsCollector);
        }
        // Close streaming connection
        if (this.syncManager.pushManager)
            this.syncManager.pushManager.stop();
    };
    BrowserSignalListener.prototype._flushData = function (url, cache, postService, fromCacheToPayload, extraMetadata) {
        // if there is data in cache, send it to backend
        if (!cache.isEmpty()) {
            var dataPayload = fromCacheToPayload ? fromCacheToPayload(cache.state()) : cache.state();
            if (!this._sendBeacon(url, dataPayload, extraMetadata)) {
                postService(JSON.stringify(dataPayload)).catch(function () { }); // no-op just to catch a possible exception
            }
            cache.clear();
        }
    };
    /**
     * _sendBeacon method.
     * Util method that check if beacon API is available, build the payload and send it.
     */
    BrowserSignalListener.prototype._sendBeacon = function (url, data, extraMetadata) {
        // eslint-disable-next-line compat/compat
        if (typeof navigator !== 'undefined' && navigator.sendBeacon) {
            var json = {
                entries: data,
                token: this.settings.core.authorizationKey,
                sdk: this.settings.version
            };
            // Extend with endpoint specific metadata where needed
            if (extraMetadata)
                objectAssign(json, extraMetadata);
            // Stringify the payload
            var payload = JSON.stringify(json);
            // eslint-disable-next-line compat/compat
            return navigator.sendBeacon(url, payload);
        }
        return false;
    };
    return BrowserSignalListener;
}());
export { BrowserSignalListener };
