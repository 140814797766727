import { objectAssign } from '../utils/lang/objectAssign';
import { SDK_SPLITS_ARRIVED, SDK_SPLITS_CACHE_LOADED, SDK_SEGMENTS_ARRIVED, SDK_READY_TIMED_OUT, SDK_READY_FROM_CACHE, SDK_UPDATE, SDK_READY } from './constants';
function splitsEventEmitterFactory(EventEmitter) {
    var splitsEventEmitter = objectAssign(new EventEmitter(), {
        splitsArrived: false,
        splitsCacheLoaded: false,
    });
    // `isSplitKill` condition avoids an edge-case of wrongly emitting SDK_READY if:
    // - `/mySegments` fetch and SPLIT_KILL occurs before `/splitChanges` fetch, and
    // - storage has cached splits (for which case `splitsStorage.killLocally` can return true)
    splitsEventEmitter.on(SDK_SPLITS_ARRIVED, function (isSplitKill) { if (!isSplitKill)
        splitsEventEmitter.splitsArrived = true; });
    splitsEventEmitter.once(SDK_SPLITS_CACHE_LOADED, function () { splitsEventEmitter.splitsCacheLoaded = true; });
    return splitsEventEmitter;
}
function segmentsEventEmitterFactory(EventEmitter) {
    var segmentsEventEmitter = objectAssign(new EventEmitter(), {
        segmentsArrived: false
    });
    segmentsEventEmitter.once(SDK_SEGMENTS_ARRIVED, function () { segmentsEventEmitter.segmentsArrived = true; });
    return segmentsEventEmitter;
}
/**
 * Factory of readiness manager, which handles the ready / update event propagation.
 */
export function readinessManagerFactory(EventEmitter, readyTimeout, splits) {
    if (readyTimeout === void 0) { readyTimeout = 0; }
    if (splits === void 0) { splits = splitsEventEmitterFactory(EventEmitter); }
    var segments = segmentsEventEmitterFactory(EventEmitter);
    var gate = new EventEmitter();
    // emit SDK_READY_FROM_CACHE
    var isReadyFromCache = false;
    if (splits.splitsCacheLoaded)
        isReadyFromCache = true; // ready from cache, but doesn't emit SDK_READY_FROM_CACHE
    else
        splits.once(SDK_SPLITS_CACHE_LOADED, checkIsReadyFromCache);
    // emit SDK_READY_TIMED_OUT
    var hasTimedout = false;
    var readyTimeoutId;
    if (readyTimeout > 0) {
        readyTimeoutId = setTimeout(function () {
            hasTimedout = true;
            gate.emit(SDK_READY_TIMED_OUT, 'Split SDK emitted SDK_READY_TIMED_OUT event.');
        }, readyTimeout);
    }
    // emit SDK_READY and SDK_UPDATE
    var isReady = false;
    splits.on(SDK_SPLITS_ARRIVED, checkIsReadyOrUpdate);
    segments.on(SDK_SEGMENTS_ARRIVED, checkIsReadyOrUpdate);
    var isDestroyed = false;
    function checkIsReadyFromCache() {
        isReadyFromCache = true;
        // Don't emit SDK_READY_FROM_CACHE if SDK_READY has been emitted
        if (!isReady) {
            try {
                gate.emit(SDK_READY_FROM_CACHE);
            }
            catch (e) {
                // throws user callback exceptions in next tick
                setTimeout(function () { throw e; }, 0);
            }
        }
    }
    function checkIsReadyOrUpdate(diff) {
        if (isReady) {
            try {
                gate.emit(SDK_UPDATE, diff);
            }
            catch (e) {
                // throws user callback exceptions in next tick
                setTimeout(function () { throw e; }, 0);
            }
        }
        else {
            if (splits.splitsArrived && segments.segmentsArrived) {
                clearTimeout(readyTimeoutId);
                isReady = true;
                try {
                    gate.emit(SDK_READY);
                }
                catch (e) {
                    // throws user callback exceptions in next tick
                    setTimeout(function () { throw e; }, 0);
                }
            }
        }
    }
    var refCount = 1;
    return {
        splits: splits,
        segments: segments,
        gate: gate,
        shared: function (readyTimeout) {
            if (readyTimeout === void 0) { readyTimeout = 0; }
            refCount++;
            return readinessManagerFactory(EventEmitter, readyTimeout, splits);
        },
        destroy: function () {
            isDestroyed = true;
            segments.removeAllListeners();
            gate.removeAllListeners();
            clearTimeout(readyTimeoutId);
            if (refCount > 0)
                refCount--;
            if (refCount === 0)
                splits.removeAllListeners();
        },
        isReady: function () { return isReady; },
        hasTimedout: function () { return hasTimedout; },
        isReadyFromCache: function () { return isReadyFromCache; },
        isDestroyed: function () { return isDestroyed; },
        isOperational: function () { return (isReady || isReadyFromCache) && !isDestroyed; }
    };
}
