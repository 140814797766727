// @flow

import { Component, Fragment } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Redirect } from 'react-router-dom';
import { Navbar, NavbarGroup } from '@blueprintjs/core';

import { getAuth0Client } from '../../../services/auth0';
import * as ROUTES from '../../../constants/routes';
import Layout from '../../layouts/default-logged-in';
import TopBarLoading from '../../top-bar-loading';
import NewUserSplashPage from '../new-user-splash';
import ContractExpiryWarning from '../../contract-expiry-warning';
import * as LocationModel from '../../../models/location';
import * as UserModel from '../../../models/user';
import { getTheme } from '../../../styles/theme';
import PreInitLoading from '../pre-init-loading';

const TopBar = styled(Navbar)`
  padding-left: 2em;
  padding-right: 2em;
`;

// override any blueprint styles here with theme variables
const BlueprintOverrides = styled.div`
  font-family: var(--fonts-default);

  .bp3-card {
    border-radius: var(--widget-border-radius);
  }

  .bp3-button:not([class*=bp3-intent-]) .bp3-icon {
    color: var(--button-icon-color);
  }

  .bp3-tab {
    outline: none;
  }

  .bp3-tab[aria-selected="true"] {
    color: var(--selected-text);
  }

  .bp3-tab-indicator-wrapper .bp3-tab-indicator {
    background-color: var(--selected-text);
  }

  .bp3-tag.bp3-intent-success {
    background-color: var(--ok);
  }

  .bp3-tag.bp3-intent-danger {
    background-color: var(--error);
  }
`;

const Wrapper = styled.div`
  background: var(--main-background);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

type Props = {
  refreshUser: () => void,
  userRefreshCompleted: boolean,
  userRefreshAuthFailure: boolean,
  isUserNewlyCreated: boolean,
  children: any,
  user: UserModel.t,
  location: any, // BrowserHistory location
  locations: LocationModel.t[],
};

type State = {
  auth0CheckCompleted: boolean,
  isAuthenticated: boolean,
  theme: Object,
};

class Authenticate extends Component<Props, State> {
  state = {
    auth0CheckCompleted: false,
    isAuthenticated: false,
    theme: {},
  };

  async componentDidMount() {
    const auth0Client = getAuth0Client();
    if (auth0Client) {
      const isAuthenticated = await auth0Client.isAuthenticated();
      this.setState({ auth0CheckCompleted: true, isAuthenticated });

      if (isAuthenticated) {
        await this.props.refreshUser();
      }

      this.setState({ theme: await getTheme() });
    }
  }

  render() {
    const {
      userRefreshCompleted,
      userRefreshAuthFailure,
      isUserNewlyCreated,
      children,
      location,
      locations,
      refreshUser,
      user,
    } = this.props;

    if (this.state.theme !== null && this.state.theme.properties) {
      const body = document.querySelector('body');
      Object.entries(this.state.theme.properties).forEach(
        ([property, value]: [string, mixed]) => {
          if (body && body.style && typeof value === 'string') {
            body.style.setProperty(property, value);
          }
        }
      );
    }

    const { auth0CheckCompleted, isAuthenticated } = this.state;

    if (auth0CheckCompleted && !isAuthenticated)
      return (
        <Redirect to={{ pathname: ROUTES.LOGIN, state: { from: location } }} />
      );

    if (userRefreshAuthFailure)
      return (
        <Layout>
          <TopBar>
            <NavbarGroup>Authentication failure</NavbarGroup>
          </TopBar>
        </Layout>
      );

    if (auth0CheckCompleted && isAuthenticated && userRefreshCompleted) {
      if (isUserNewlyCreated)
        return <NewUserSplashPage user={user} refreshUser={refreshUser} />;
      return (
        <>
          {this.state.theme && Object.keys(this.state.theme).length ? (
            <ThemeProvider theme={this.state.theme}>
              <BlueprintOverrides>
                <Wrapper>
                  <Fragment>
                    <ContractExpiryWarning locations={locations} />
                    {children}
                  </Fragment>
                </Wrapper>
              </BlueprintOverrides>
            </ThemeProvider>
          ) : (
            <PreInitLoading />
          )}
        </>
      );
    }

    // user refresh is still in progress....
    return (
      <>
        {this.state.theme && Object.keys(this.state.theme).length ? (
          <ThemeProvider theme={this.state.theme}>
            <BlueprintOverrides>
              <Layout>
                <TopBar>
                  <NavbarGroup>
                    <TopBarLoading />
                  </NavbarGroup>
                </TopBar>
              </Layout>
            </BlueprintOverrides>
          </ThemeProvider>
        ) : (
          <PreInitLoading />
        )}
      </>
    );
  }
}

export default Authenticate;
