import { _Map } from '../lang/maps';
import { LinkedList, Node } from './LinkedList';
var LRUCache = /** @class */ (function () {
    function LRUCache(maxSize) {
        this.maxLen = maxSize || 1;
        this.items = new _Map();
        this.lru = new LinkedList();
    }
    LRUCache.prototype.get = function (key) {
        var node = this.items.get(key);
        if (!node || !(node instanceof Node))
            return;
        this.lru.unshiftNode(node); // Move to front
        return node.value.value;
    };
    LRUCache.prototype.set = function (key, value) {
        var node = this.items.get(key);
        if (node) {
            if (!(node instanceof Node))
                return false;
            this.lru.unshiftNode(node); // Move to front
            this.lru.head.value.value = value; // Update value
        }
        else {
            if (this.lru.length === this.maxLen) { // Remove last
                var last = this.lru.tail;
                if (!last)
                    return false;
                this.items.delete(last.value.key);
                this.lru.removeNode(this.lru.tail); // Remove node
            }
            // @ts-ignore
            this.lru.unshift({ key: key, value: value }); // Push front
            this.items.set(key, this.lru.head);
        }
        return true;
    };
    return LRUCache;
}());
export { LRUCache };
