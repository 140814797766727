'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = flow;

var _resolveAll = require('./__internal__/resolveAll');

var _resolveAll2 = _interopRequireDefault(_resolveAll);

var _series = require('./__internal__/series');

var _series2 = _interopRequireDefault(_series);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function flow(value, fns) {
  return (0, _resolveAll2.default)(value, false).then(function (initial) {
    return (0, _series2.default)(function (val, fn) {
      return fn(val);
    }, initial)(fns);
  });
}