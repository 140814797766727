'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = map;

var _resolveAll = require('./__internal__/resolveAll');

var _resolveAll2 = _interopRequireDefault(_resolveAll);

var _utils = require('./__internal__/utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function map(iterable) {
  var mapper = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _utils.identity;

  return (0, _resolveAll2.default)(iterable).then(function (values) {
    return Promise.all(values.map(mapper));
  });
}