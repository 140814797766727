// @flow

import { useState, useEffect } from 'react';
import { isEqual } from 'lodash';
import styled from 'styled-components';
import { Dialog, Tabs, Tab, Button, Classes, Spinner } from '@blueprintjs/core';
import EditOpeningTimes from './edit-opening-times';
import EditDetails from './edit-details';
import { breakpoints } from '../../styles/variables';
import { fetchOne } from '../../actions/locations';

const StyledDialog = styled(Dialog)`
  min-height: 540px;
  width: 800px;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    width: 100%;
  }

  .bp3-dialog-header {
    border-radius: var(--blueprint-dialog-border-radius)
      var(--blueprint-dialog-border-radius) 0 0;
  }

  .bp3-button {
    border-radius: var(--button-border-radius);
  }
`;
const DialogBody = styled.div`
  flex: 1 1 auto;
  margin: 30px 30px 10px 30px;
  line-height: 18px;

  @media (max-width: ${breakpoints.TABLET_SIZE}) {
    margin: 20px 20px 0 20px;
  }
`;
const StyledTabs = styled(Tabs)`
  .tab-remove-margin {
    margin-top: 0;
  }
`;
const FooterText = styled.p`
  & {
    margin: auto;
    opacity: 0.7;
  }
`;

type Props = {
  locationId: string,
  isOpen: boolean,
  updateLocation(id: string, edits: Object): Promise<any>,
  onClose(): void,
  canManageLocations: boolean,
};

const EditLocationModal = ({
  locationId,
  isOpen,
  canManageLocations,
  updateLocation,
  onClose,
}: Props) => {
  const [hasBeenEdited: boolean, setHasBeenEdited] = useState<boolean>(false);
  const [newLocation: LocationModel.t, setNewLocation] =
    useState<LocationModel.t>({});
  const [edits: Object, setEdits] = useState<Object>({});
  const [currentTab: stringify, setCurrentTab] = useState('info');
  const [isSaving: boolean, setIsSaving] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);
  const [location, setLocation] = useState();

  const updateEdits = async (newEdits) => setEdits({ ...edits, ...newEdits });

  const fetchLocationData = async (locationId) => {
    const loc = await fetchOne(locationId);
    setLocation(loc);
    setIsLoading(false);
  };
  const generateTitle = (location, canManageLocations) => {
    if (location && location.name) {
      if (canManageLocations) {
        return `Edit location details: ${location.name}`;
      } else {
        return `Location details: ${location.name}`;
      }
    } else {
      return 'Edit Location Details';
    }
  };

  // TODO: prompt before close
  const closeDialog = () => {
    setCurrentTab('info');
    setEdits({});
    onClose();
  };

  const saveChanges = async () => {
    setIsSaving(true);
    const result = await updateLocation(newLocation.id, edits);

    if (result.response && result.response.status !== 200) {
      setIsSaving(false);
      return;
    }

    setIsSaving(false);
    closeDialog();
  };

  const updateField = ({ target }) => {
    updateEdits({ [target.name]: target.value });
  };

  const updateSelect = (field: string, value: string) => {
    updateEdits({ [field]: value });
  };

  const handleTabChange = (tabId) => {
    setCurrentTab(tabId);
  };

  useEffect(() => {
    setHasBeenEdited(!isEqual(location, newLocation));
  }, [location, newLocation]);

  useEffect(() => setNewLocation({ ...location, ...edits }), [edits, location]);

  useEffect(() => {
    if (locationId && typeof locationId === 'string' && !location) {
      fetchLocationData(locationId);
    }
  }, [locationId]);

  return (
    <>
      <StyledDialog
        isOpen={isOpen}
        onClose={closeDialog}
        canOutsideClickClose={false}
        title={
          isLoading
            ? 'Loading ...'
            : generateTitle(location, canManageLocations)
        }
      >
        {!isLoading && typeof location === 'undefined' && <DialogBody className={Classes.DIALOG_BODY}>Location not found</DialogBody>}
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <DialogBody className={Classes.DIALOG_BODY}>
              <StyledTabs
                animate={false}
                onChange={handleTabChange}
                selectedTabId={currentTab}
              >
                <Tab
                  id="info"
                  title="Details"
                  panel={
                    <EditDetails
                      location={newLocation}
                      updateField={updateField}
                      updateSelect={updateSelect}
                      canManageLocations={canManageLocations}
                    />
                  }
                />
                {newLocation.openingTimes &&
                  newLocation.openingTimes.length > 0 && (
                    <Tab
                      id="openingTimes"
                      title="Opening Times"
                      panel={
                        <EditOpeningTimes
                          openingTimes={newLocation.openingTimes.sort(
                            (a, b) => {
                              if (a.day < b.day) return -1;
                              if (a.day > b.day) return 1;
                              return 0;
                            },
                          )}
                          updateEdits={updateEdits}
                          disabled={!canManageLocations}
                        />
                      }
                    />
                  )}
              </StyledTabs>
            </DialogBody>
            <footer className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                {canManageLocations ? (
                  <Button
                    disabled={!hasBeenEdited || isSaving}
                    text="Save"
                    intent="success"
                    onClick={saveChanges}
                  />
                ) : (
                  <FooterText>
                    You do not have permission to edit details in this section.
                  </FooterText>
                )}
              </div>
            </footer>
          </>
        )}
      </StyledDialog>
    </>
  );
};

export default EditLocationModal;
