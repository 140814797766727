import { isLocalStorageAvailable } from '@splitsoftware/splitio-commons/esm/utils/env/isLocalStorageAvailable';
import { LOCALHOST_MODE, STORAGE_MEMORY } from '@splitsoftware/splitio-commons/esm/utils/constants';
var STORAGE_LOCALSTORAGE = 'LOCALSTORAGE';
export function validateStorage(settings) {
    var log = settings.log, mode = settings.mode, _a = settings.storage, _b = _a === void 0 ? { type: STORAGE_MEMORY } : _a, type = _b.type, _c = _b.options, options = _c === void 0 ? {} : _c, prefix = _b.prefix;
    var __originalType;
    var fallbackToMemory = function () {
        __originalType = type;
        type = STORAGE_MEMORY;
    };
    // In localhost mode, fallback to Memory storage and track original type to emit SDK_READY_FROM_CACHE if corresponds.
    // ATM, other mode settings (e.g., 'consumer') are ignored in client-side API, and so treated as standalone.
    if (mode === LOCALHOST_MODE && type === STORAGE_LOCALSTORAGE) {
        fallbackToMemory();
    }
    // If an invalid storage type is provided OR we want to use LOCALSTORAGE and
    // it's not available, fallback into MEMORY
    if (type !== STORAGE_MEMORY && type !== STORAGE_LOCALSTORAGE ||
        type === STORAGE_LOCALSTORAGE && !isLocalStorageAvailable()) {
        fallbackToMemory();
        log.error('Invalid or unavailable storage. Fallbacking into MEMORY storage');
    }
    return {
        type: type,
        options: options,
        prefix: prefix,
        __originalType: __originalType
    };
}
