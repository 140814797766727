import { useState, useEffect } from 'react';
import { trackEvent, ENTER_PAGE } from '../../../services/mixpanel';
import CameraPreviews from '../../camera-previews';
import Layout from '../../layouts/default-logged-in';

const ThumbnailsPage = ({ recordings, locations }) => {
  const [compare, setCompare] = useState(false);

  // run only of first load, not re-render
  useEffect(() => {
    trackEvent(ENTER_PAGE, 'CAMERA_PREVIEW');
  }, []);

  return (
    <Layout>
      <CameraPreviews
        recordings={recordings}
        locations={locations}
        compare={compare}
        setCompare={setCompare}
      ></CameraPreviews>
      {compare && (
        <CameraPreviews
          recordings={recordings}
          locations={locations}
        ></CameraPreviews>
      )}
    </Layout>
  );
};

export default ThumbnailsPage;
