'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function customError(fn, BaseError) {
  BaseError = BaseError || Error;

  function CustomError() {
    // eslint-disable-next-line no-proto
    var _this = CustomError.__proto__.apply(this, arguments);
    Object.assign(_this, fn.apply(null, arguments));
    return _this;
  }

  Object.setPrototypeOf(CustomError, BaseError);

  return CustomError;
}

var SubError = exports.SubError = customError(function (errors) {
  var multiStack = errors.reduce(function (str, error) {
    str += '\n\n' + error.stack;
    return str;
  }, '');

  return {
    name: 'SubError',
    message: 'SubError ' + multiStack,
    errors: errors
  };
}, Error);

var IterableError = exports.IterableError = customError(function (value) {
  var type = value === null ? 'null' : typeof value === 'undefined' ? 'undefined' : _typeof(value);

  return {
    name: 'IterableError',
    message: 'expecting an array or an iterable object but got ' + type
  };
}, TypeError);