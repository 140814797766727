import { syncTaskFactory } from '../syncTask';
import { SUBMITTERS_PUSH, SUBMITTERS_PUSH_FAILS, SUBMITTERS_PUSH_RETRY } from '../../logger/constants';
/**
 * Base function to create submitter sync tasks, such as ImpressionsSyncTask and EventsSyncTask
 */
export function submitterSyncTaskFactory(log, postClient, sourceCache, postRate, dataName, latencyTracker, fromCacheToPayload, maxRetries, debugLogs) {
    if (maxRetries === void 0) { maxRetries = 0; }
    var retries = 0;
    function postData() {
        if (sourceCache.isEmpty())
            return Promise.resolve();
        var data = sourceCache.state();
        var dataCount = typeof data.length === 'number' ? data.length : '';
        log[debugLogs ? 'debug' : 'info'](SUBMITTERS_PUSH, [dataCount, dataName]);
        var latencyTrackerStop = latencyTracker && latencyTracker.start();
        var jsonPayload = JSON.stringify(fromCacheToPayload ? fromCacheToPayload(data) : data);
        if (!maxRetries)
            sourceCache.clear();
        var postPromise = postClient(jsonPayload).then(function () {
            retries = 0;
            sourceCache.clear(); // we clear the queue if request successes.
        }).catch(function (err) {
            if (!maxRetries) {
                log.warn(SUBMITTERS_PUSH_FAILS, [dataCount, dataName, err]);
            }
            else if (retries === maxRetries) {
                retries = 0;
                sourceCache.clear(); // we clear the queue if request fails after retries.
                log.warn(SUBMITTERS_PUSH_FAILS, [dataCount, dataName, err]);
            }
            else {
                retries++;
                log.warn(SUBMITTERS_PUSH_RETRY, [dataCount, dataName, err]);
            }
        });
        // if latencyTracker provided, attach stop callback to postEventsPromise
        return latencyTrackerStop ? postPromise.then(latencyTrackerStop).catch(latencyTrackerStop) : postPromise;
    }
    return syncTaskFactory(log, postData, postRate, dataName + ' submitter');
}
