'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = concurrent;

var _utils = require('./utils');

function run() {
  for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  var iterator = args[0],
      isFulfilled = args[1],
      transform = args[2],
      onItemResolved = args[3],
      onItemRejected = args[4],
      onItemCompleted = args[5];


  var iteration = iterator.next();

  if (iteration.done || isFulfilled()) return;

  var key = iteration.value[0];
  var value = iteration.value[1];

  transform(value, key).then(function (resolved) {
    return onItemResolved(resolved, key);
  }).catch(function (error) {
    return onItemRejected(error, key);
  }).then(function () {
    onItemCompleted();
    run.apply(undefined, args);
  });
}

function concurrent(_options) {
  return function (values) {
    return new Promise(function (resolve, reject) {
      var options = (0, _utils.defaults)(_options, {
        limit: values.length,
        breakOnError: true,
        transform: _utils.identity,
        onItemCompleted: function onItemCompleted() {
          return _utils.noop;
        },
        onItemResolved: _utils.noop,
        onItemRejected: _utils.noop
      });

      var count = 0;
      var fulfilled = false;
      var errors = [];

      var iterator = values.entries();

      var isFulfilled = function isFulfilled() {
        return fulfilled;
      };

      function done(value) {
        resolve(value);
        fulfilled = true;
      }

      function throws(error) {
        reject(error);
        fulfilled = true;
      }

      function onItemCompleted() {
        count += 1;

        if (options.breakOnError && errors.length) {
          throws(errors[0]);
          return;
        }

        options.onItemCompleted(done, throws)(count, values, errors);
      }

      function onItemRejected(error) {
        if (fulfilled) {
          return;
        }
        errors = errors.concat(error);
        return options.onItemRejected(error);
      }

      function onItemResolved(value, key) {
        if (fulfilled) {
          return;
        }
        return options.onItemResolved(value, key, values);
      }

      var transform = function transform(value, key) {
        return Promise.resolve(options.transform(value, key, values));
      };

      (0, _utils.repeat)(options.limit, function () {
        return run(iterator, isFulfilled, transform, onItemResolved, onItemRejected, onItemCompleted);
      });
    });
  };
}