// Special treatments
export var CONTROL = 'control';
export var CONTROL_WITH_CONFIG = {
    treatment: CONTROL,
    config: null
};
// Constants for unknown and not-applicable values
export var UNKNOWN = 'unknown';
export var NA = 'NA';
// Integration data types
export var SPLIT_IMPRESSION = 'IMPRESSION';
export var SPLIT_EVENT = 'EVENT';
// Impression collection modes
export var DEBUG = 'DEBUG';
export var OPTIMIZED = 'OPTIMIZED';
// SDK Modes
export var LOCALHOST_MODE = 'localhost';
export var STANDALONE_MODE = 'standalone';
export var PRODUCER_MODE = 'producer';
export var CONSUMER_MODE = 'consumer';
export var CONSUMER_PARTIAL_MODE = 'consumer_partial';
// Storage types
export var STORAGE_MEMORY = 'MEMORY';
export var STORAGE_LOCALSTORAGE = 'LOCALSTORAGE';
export var STORAGE_REDIS = 'REDIS';
export var STORAGE_PLUGGABLE = 'PLUGGABLE';
// User consent
export var CONSENT_GRANTED = 'GRANTED'; // The user has granted consent for tracking events and impressions
export var CONSENT_DECLINED = 'DECLINED'; // The user has declined consent for tracking events and impressions
export var CONSENT_UNKNOWN = 'UNKNOWN'; // The user has neither granted nor declined consent for tracking events and impressions
