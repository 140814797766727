import styled from 'styled-components';

const NotFoundText = styled.p`
  text-align: center;
  padding: 4em 0;
  color: var(--mid-grey);
  width: 100%;
`;

export default NotFoundText;
