import { findIndex } from '../../utils/lang';
import { matcherTypes, matcherTypesMapper, matcherDataTypes } from '../matchers/matcherTypes';
import { segmentTransform } from './segment';
import { whitelistTransform } from './whitelist';
import { setTransform } from './set';
import { numericTransform } from './unaryNumeric';
import { zeroSinceHH, zeroSinceSS } from '../convertions';
/**
 * Flat the complex matcherGroup structure into something handy.
 */
export function matchersTransform(matchers) {
    var parsedMatchers = matchers.map(function (matcher) {
        var matcherType = matcher.matcherType /* string */, negate = matcher.negate /* boolean */, keySelector = matcher.keySelector /* keySelectorObject */, segmentObject = matcher.userDefinedSegmentMatcherData /* segmentObject */, whitelistObject = matcher.whitelistMatcherData /* whiteListObject, provided by 'WHITELIST', set and string matchers */, unaryNumericObject = matcher.unaryNumericMatcherData /* unaryNumericObject */, betweenObject = matcher.betweenMatcherData /* betweenObject */, dependencyObject = matcher.dependencyMatcherData /* dependencyObject */, booleanMatcherData = matcher.booleanMatcherData, stringMatcherData = matcher.stringMatcherData;
        var attribute = keySelector && keySelector.attribute;
        var type = matcherTypesMapper(matcherType);
        // As default input data type we use string (even for ALL_KEYS)
        var dataType = matcherDataTypes.STRING;
        var value = undefined;
        if (type === matcherTypes.IN_SEGMENT) {
            value = segmentTransform(segmentObject);
        }
        else if (type === matcherTypes.WHITELIST) {
            value = whitelistTransform(whitelistObject);
        }
        else if (type === matcherTypes.EQUAL_TO) {
            value = numericTransform(unaryNumericObject);
            dataType = matcherDataTypes.NUMBER;
            if (unaryNumericObject.dataType === 'DATETIME') {
                value = zeroSinceHH(value);
                dataType = matcherDataTypes.DATETIME;
            }
        }
        else if (type === matcherTypes.GREATER_THAN_OR_EQUAL_TO ||
            type === matcherTypes.LESS_THAN_OR_EQUAL_TO) {
            value = numericTransform(unaryNumericObject);
            dataType = matcherDataTypes.NUMBER;
            if (unaryNumericObject.dataType === 'DATETIME') {
                value = zeroSinceSS(value);
                dataType = matcherDataTypes.DATETIME;
            }
        }
        else if (type === matcherTypes.BETWEEN) {
            value = betweenObject;
            dataType = matcherDataTypes.NUMBER;
            if (value.dataType === 'DATETIME') {
                value.start = zeroSinceSS(value.start);
                value.end = zeroSinceSS(value.end);
                dataType = matcherDataTypes.DATETIME;
            }
        }
        else if (type === matcherTypes.EQUAL_TO_SET ||
            type === matcherTypes.CONTAINS_ANY_OF_SET ||
            type === matcherTypes.CONTAINS_ALL_OF_SET ||
            type === matcherTypes.PART_OF_SET) {
            value = setTransform(whitelistObject);
            dataType = matcherDataTypes.SET;
        }
        else if (type === matcherTypes.STARTS_WITH ||
            type === matcherTypes.ENDS_WITH ||
            type === matcherTypes.CONTAINS_STRING) {
            value = setTransform(whitelistObject);
        }
        else if (type === matcherTypes.IN_SPLIT_TREATMENT) {
            value = dependencyObject;
            dataType = matcherDataTypes.NOT_SPECIFIED;
        }
        else if (type === matcherTypes.EQUAL_TO_BOOLEAN) {
            dataType = matcherDataTypes.BOOLEAN;
            value = booleanMatcherData;
        }
        else if (type === matcherTypes.MATCHES_STRING) {
            value = stringMatcherData;
        }
        return {
            attribute: attribute,
            negate: negate,
            type: type,
            value: value,
            dataType: dataType // runtime input data type
        };
    });
    if (findIndex(parsedMatchers, function (m) { return m.type === matcherTypes.UNDEFINED; }) === -1) {
        return parsedMatchers;
    }
    else {
        return [];
    }
}
