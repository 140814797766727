import { ENGINE_MATCHER_EQUAL_TO_SET } from '../../logger/constants';
import { findIndex } from '../../utils/lang';
export function equalToSetMatcherContext(log, ruleAttr) {
    return function equalToSetMatcher(runtimeAttr) {
        // Length being the same is the first condition.
        var isEqual = runtimeAttr.length === ruleAttr.length;
        var _loop_1 = function (i) {
            // if length is the same we check that all elements are present in the other collection.
            if (findIndex(ruleAttr, function (e) { return e === runtimeAttr[i]; }) < 0)
                isEqual = false;
        };
        for (var i = 0; i < runtimeAttr.length && isEqual; i++) {
            _loop_1(i);
        }
        log.debug(ENGINE_MATCHER_EQUAL_TO_SET, [runtimeAttr, ruleAttr, isEqual]);
        return isEqual;
    };
}
