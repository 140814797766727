import { isObject } from '../lang';
// function isSplitKeyObject(key: any): key is SplitIO.SplitKeyObject {
//   return key !== undefined && key !== null && typeof key.matchingKey === 'string';
// }
// returns the matchingKey if the Key is defined as an object or the key itself if it is a string
export function getMatching(key) {
    return isObject(key) ? key.matchingKey : key;
}
// if the key is a string, there's no bucketingKey (undefined)
export function getBucketing(key) {
    return isObject(key) ? key.bucketingKey : undefined;
}
/**
 * Verify type of key and return a valid object key used for get treatment for a
 * specific split.
 */
export function keyParser(key) {
    if (isObject(key)) {
        return {
            matchingKey: key.matchingKey,
            bucketingKey: key.bucketingKey
        };
    }
    else {
        return {
            matchingKey: key,
            bucketingKey: key
        };
    }
}
