export var Compression;
(function (Compression) {
    Compression[Compression["None"] = 0] = "None";
    Compression[Compression["Gzip"] = 1] = "Gzip";
    Compression[Compression["Zlib"] = 2] = "Zlib";
})(Compression || (Compression = {}));
export var UpdateStrategy;
(function (UpdateStrategy) {
    UpdateStrategy[UpdateStrategy["UnboundedFetchRequest"] = 0] = "UnboundedFetchRequest";
    UpdateStrategy[UpdateStrategy["BoundedFetchRequest"] = 1] = "BoundedFetchRequest";
    UpdateStrategy[UpdateStrategy["KeyList"] = 2] = "KeyList";
    UpdateStrategy[UpdateStrategy["SegmentRemoval"] = 3] = "SegmentRemoval";
})(UpdateStrategy || (UpdateStrategy = {}));
