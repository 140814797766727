import { Toaster, Intent } from '@blueprintjs/core';

const errorsToastMessagesList = [];

const toaster = Toaster.create();

const errorToast = args => {
  const messageHasBeenUsed = errorsToastMessagesList.includes(args.message);

  if (!messageHasBeenUsed) {
    errorsToastMessagesList.push(args.message);

    setTimeout(() => {
      const index = errorsToastMessagesList.indexOf(args.message);
      errorsToastMessagesList.splice(index, 1);
    }, 1000);

    toaster.show({
      intent: Intent.WARNING,
      ...args
    });
  }
};

export { errorToast };
export default toaster;
